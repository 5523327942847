import {
    Grid,
    IconButton,
    Hidden,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import React from 'react'
import ValidationIcon from './ValidationIcon'

export const ArrayLayoutToolbar = React.memo(
    ({ label, errors, addItem, path, enabled, createDefault }) => {
        return (
            <Toolbar disableGutters={false} sx={{display: 'flex'}}>
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid item>
                        <Typography variant={'h6'}>{label}</Typography>
                    </Grid>
                    <Hidden smUp={!enabled}>
                        <Hidden smUp={errors.length === 0}>
                            <Grid item>
                                <ValidationIcon
                                    id="tooltip-validation"
                                    errorMessages={errors}
                                />
                            </Grid>
                        </Hidden>
                        <Grid item>
                            <Grid container>
                                <Grid item>
                                    <Tooltip
                                        id="tooltip-add"
                                        title={`Add to ${label}`}
                                        placement="bottom"
                                    >
                                        <IconButton
                                            aria-label={`Add to ${label}`}
                                            onClick={addItem(path, createDefault())}
                                            size="large"
                                        >
                                            <AddIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Hidden>
                </Grid>
            </Toolbar>
        )
    }
)
