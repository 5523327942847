import { apiUrls } from '../../../utils/constants/urls'
import { removeEmptyParameters } from '../../../utils/queryParameters'
import toast from '../../elem/Toast'

const getBidResponse = (authFetch, API_URL, bidResponseId, after) => {
    authFetch(
        `${API_URL}/${apiUrls.bidResponse}/${bidResponseId}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Bid Response: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getBidResponseSchema = (authFetch, API_URL, after) => {
    authFetch(
        `${API_URL}/${apiUrls.schema}/bidResponse`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Schema: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getBidResponseAutoCalc = (authFetch, API_URL, bidResponse, abortController, after) => {
    const queryParams = removeEmptyParameters(bidResponse)
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(queryParams),
        signal: abortController.signal
    }  
    authFetch(`${API_URL}/${apiUrls.autoCalc}/bidResponse`, POST)
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                toast({
                    level: 'error',
                    message:
                        'Schema: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            }
        })
}

const createBidResponse = (authFetch, API_URL, bidResponse, after) => {
    const url = `${API_URL}/${apiUrls.bidResponse}/create`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(bidResponse)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response.createdId)
            
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Create Bid Response: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const updateBidResponse = (authFetch, API_URL, bidResponse, after) => {
    const url = `${API_URL}/${apiUrls.bidResponse}` 
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(bidResponse)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Update Bid Response: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export {
    getBidResponse,
    getBidResponseSchema,
    createBidResponse,
    updateBidResponse,
    getBidResponseAutoCalc,
}
