import React from 'react'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Badge, Tooltip, styled } from '@mui/material'

const StyledBadge = styled(Badge)(({ theme }) => ({
    color: theme.palette.error.main,
}))

const invalidMessages = [
    'must match "then" schema'
]
const filterMessages = (messages) => {
    return messages.filter(x => !invalidMessages.includes(x))
}
const ValidationIcon = ({ errorMessages, id }) => {
    return (
        <Tooltip id={id} title={errorMessages}>
            <StyledBadge badgeContent={filterMessages(errorMessages.split('\n')).length}>
                <ErrorOutlineIcon color="inherit" />
            </StyledBadge>
        </Tooltip>
    )
}

export default ValidationIcon
