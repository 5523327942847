import { DefaultColumnFilter } from './search'
import Cell from '../../../components/elem/grid/display/Cell'
import get from 'lodash/get'

const getDefaultInputWidth = (dataType) => {
    if (dataType === 'Date') {
        return 200
    } else {
        return 150
    }
}

//set default sortType if not declared in config
const getDefaultInputSort = (dataType) => {
    if (dataType === 'String') {
        return 'caseInsensitive'
    } else {
        return 'alphanumeric'
    }    
}

const getColumns = (staticConfig, attributeConfig) => {
    const staticColumns = Object.keys(staticConfig).map(c => {
        return {
            // ...columnFilter,
            Header: get(staticConfig, c).Label,
            accessor: c,
            type: get(staticConfig, c).DataType,
            width: get(staticConfig, c).Width ? get(staticConfig, c).Width : getDefaultInputWidth(get(staticConfig, c).DataType),
            seq: get(staticConfig, c).Seq,
            sortType: get(staticConfig, c).SortType ? get(staticConfig, c).SortType : getDefaultInputSort(get(staticConfig, c).DataType),
            Cell: ({ cell, column, row }) => (
                <Cell
                    value={cell.value}
                    column={column.id}
                    row={row}
                    {...staticConfig[c]}
                />
            ),
        }
    })

    const attributeColumns = Object.keys(attributeConfig).map(c => {
        return {
            // ...columnFilter,
            Header: get(attributeConfig, c).Label,
            accessor: c,
            type: get(attributeConfig, c).DataType,
            seq: get(attributeConfig, c).Seq,
            sortType: get(attributeConfig, c).SortType ? get(attributeConfig, c).SortType : getDefaultInputSort(get(attributeConfig, c).DataType),
            width: get(attributeConfig, c).Width ? get(attributeConfig, c).Width : getDefaultInputWidth(get(attributeConfig, c).DataType),
            Cell: ({ cell, column, row }) => (
                <Cell
                    value={cell.value}
                    column={column.id}
                    row={row}
                    {...attributeConfig[c]}
                />
            ),
        }
    })

    const allColumns = [...staticColumns, ...attributeColumns]
    allColumns.sort((a, b) => a.seq - b.seq) // sort columns by seq
    return allColumns
}

const defaultColumn = () => ({
    Filter: DefaultColumnFilter,
    disableResizing: true,
    // minWidth: 100,
    width: 200,
    // maxWidth: 400,
})

export { getColumns, defaultColumn } 