import React, { useState, useEffect, useCallback } from 'react'
import merge from 'lodash/merge'
import PhoneInput, {
    isPossiblePhoneNumber,
} from 'react-phone-number-input/input'
import { isDescriptionHidden } from '@jsonforms/core'
import {
    createOnChangeHandler,
    getData,
    useFocus,
} from '@jsonforms/material-renderers'
import { FormControl, FormHelperText, TextField } from '@mui/material'
import { withJsonFormsControlProps } from '@jsonforms/react'

export const phoneControlTester = (uiSchema) => {
    return uiSchema.specializedType && uiSchema.specializedType === 'Phone' ? 7 : 0
  }

const PhoneFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props
    return (
        <PhoneInput
            {...other}
            getInputRef={ref}
            country="US"
            onChange={(value) => {
                onChange({
                    target: {
                        name: props.name,
                        value: value,
                    },
                })
            }}
        />
    )
})

const PhoneControlRenderer = (props) => {
    const [focused, onFocus, onBlur] = useFocus()
    const {
        description,
        id,
        errors,
        label,
        uischema,
        visible,
        enabled,
        required,
        path,
        handleChange,
        data,
        config,
    } = props
    const isValid = errors.length === 0
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    )

    const [value, setValue] = useState(data)

    const onChange = useCallback(
        (evt) => {
            const newVal = evt.target.value
            setValue(newVal)
        },
        [path, handleChange]
    )

    useEffect(() => {
        setValue(data)
    }, [data])

    const handleBlur = useCallback(() => {
        handleChange(path, value ? String(value) : undefined)
    }, [value])

    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
    const secondFormHelperText = showDescription && !isValid ? errors : null

    return (
        <FormControl fullWidth>
            <TextField
                label={label}
                value={value ? value.toString() : ""}
                onChange={onChange}
                onBlur={handleBlur}
                required={required}
                disabled={!enabled}
                InputProps={{
                    inputComponent: PhoneFormatCustom,
                }}
                InputLabelProps={data ? { shrink: true } : ""}
                error={!isValid}
                variant="standard"
            />
            <FormHelperText error={!isValid && !showDescription}>
                {firstFormHelperText}
            </FormHelperText>
            <FormHelperText error={!isValid}>
                {secondFormHelperText}
            </FormHelperText>
        </FormControl>
    )
}

export default withJsonFormsControlProps(PhoneControlRenderer)