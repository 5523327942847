import { _ } from "ajv"
import { isPossiblePhoneNumber } from "react-phone-number-input"

const isValidPhoneNumber = {
    keyword: "isValidPhoneNumber",
    validate: (schema, data) => {
        return isPossiblePhoneNumber(data, 'US')
    },
    error: {
        message: 'must be valid US phone number',
    },
}

export default [isValidPhoneNumber]