import { apiUrls } from '../../../utils/constants/urls'
import { toast } from 'react-toastify'

let dataAbortController = new AbortController()
const getBidResponses = (authFetch, API_URL, queryParameters, after) => {
    const queryString = new URLSearchParams(queryParameters).toString()
    dataAbortController.abort()
    const abortController = new AbortController()
    dataAbortController = abortController
    authFetch(`${API_URL}/${apiUrls.bidResponse}?${queryString}`, {
        signal: abortController.signal,
    })
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                toast({
                    level: 'error',
                    message:
                        'Bid Responses: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            }
        })
}

let metaAbortController = new AbortController()
const getBidResponseListMetadata = (
    authFetch,
    API_URL,
    queryParameters,
    after
) => {
    metaAbortController.abort()
    const abortController = new AbortController()
    metaAbortController = abortController
    const queryString = new URLSearchParams(queryParameters).toString()
    authFetch(`${API_URL}/${apiUrls.bidResponse}/listMeta/?${queryString}`, {
        signal: abortController.signal,
    })
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                toast({
                    level: 'error',
                    message:
                        'Bid Responses: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            }
        })
}

export { getBidResponseListMetadata, getBidResponses }
