import React from 'react'
import CellWrapper from './CellWrapper'

const NumberCell = ({value}) => {
    return (
        <CellWrapper>
            {value}
        </CellWrapper>
    )
}
export default NumberCell