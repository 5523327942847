import Toast from "../../components/elem/Toast"

export const checkEditingChild = editingChild => {
    return new Promise((resolve, reject) => {
        if (editingChild) {
            Toast({
                level: 'error',
                message: 'You must complete changes to all related forms before saving',
                alert: true
            })
            reject()
        } else {
            resolve()
        }
    })
}
export const checkFormErrors = (errors, additionalErrors) => {
    return new Promise((resolve, reject) => {
        if ((errors && errors.length) || (additionalErrors && additionalErrors.length)) {
            Toast({
                level: 'error',
                message: 'Please fix all form errors before saving',
                alert: true
            })
            reject()
        } else {
            resolve()
        }
    })
}