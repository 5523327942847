import React, { useState } from 'react'
import merge from 'lodash/merge'
import NumberFormat from 'react-number-format'
import { isDescriptionHidden } from '@jsonforms/core'
import ClearIcon from '@mui/icons-material/Clear'
import { useDebouncedChange } from '@jsonforms/material-renderers'
import { useFocus } from '@jsonforms/material-renderers'
import {
    FormControl,
    FormHelperText,
    TextField,
    IconButton,
    useTheme,
    InputAdornment,
} from '@mui/material'
import { withJsonFormsControlProps } from '@jsonforms/react'

export const currencyControlTester = (uiSchema) => {
    return uiSchema.specializedType && uiSchema.specializedType === 'Currency'
        ? 7
        : 0
}

const NumberFormatCustom = React.forwardRef((props, ref) => {
    const { onChange, ...other } = props
    const value = other.value === null || other.value === undefined ? '' : other.value //if it is 0 other.value returns false
    return (
        <NumberFormat
            {...other}
            value={value}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                })
            }}
            thousandSeparator
            decimalScale={2}
            fixedDecimalScale={true}
            prefix="$"
            // To be defined
            // allowNegative={false}
        />
    )
})

const CurrencyControlRenderer = (props) => {
    const [focused] = useFocus()
    const [showAdornment, setShowAdornment] = useState(false)
    const theme = useTheme()
    const closeStyle = {
        background: theme.jsonforms?.input?.delete?.background || theme.palette.background.default,
        borderRadius: '50%'
    };
    const {
        description,
        errors,
        label,
        uischema,
        visible,
        enabled,
        required,
        path,
        handleChange,
        data,
        config,
    } = props
    const [input, onChange, onClear] = useDebouncedChange(
        handleChange,
        undefined,
        data,
        path,
        (evt) => {
            const newVal = evt.target.value
            return newVal ? Number(newVal) : undefined
        }, 2000
    )
    const isValid = errors.length === 0
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    )

    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
    const secondFormHelperText = showDescription && !isValid ? errors : null

    const onPointerEnter = () => setShowAdornment(true)
    const onPointerLeave = () => setShowAdornment(false)
    return (
        <FormControl fullWidth>
            <TextField
                label={label}
                value={input}
                onChange={onChange}
                required={required}
                disabled={!enabled}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: (
                        <InputAdornment
                            position="end"
                            style={{
                                display:
                                    !showAdornment ||
                                    !enabled ||
                                    data === undefined
                                        ? 'none'
                                        : 'flex',
                                position: 'absolute',
                                right: 0,
                            }}
                        >
                            <IconButton size="large" onClick={onClear}>
                                <ClearIcon style={closeStyle} />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                InputLabelProps={data ? { shrink: true, required: false } : { required: false }}
                error={!isValid}
                variant="standard"
            />
            <FormHelperText error={!isValid && !showDescription}>
                {firstFormHelperText}
            </FormHelperText>
            <FormHelperText error={!isValid}>
                {secondFormHelperText}
            </FormHelperText>
        </FormControl>
    )
}

export default withJsonFormsControlProps(CurrencyControlRenderer)
