import React, {
    useContext,
    useMemo,
    useRef,
    useCallback,
    useState,
    useEffect,
} from 'react'

import MaterialTable from 'material-table'
import { DataContext } from '../LinkDataContext'
import { MuiThemeProvider } from '@material-ui/core'
import { createTheme, Grid, Typography } from '@mui/material'
import { theme, defaultOptions } from '../muiGridConfig'
import Toolbar from '../Toolbar'
import ErrorOutline from '@mui/icons-material/ErrorOutline'

import { FilterAltOff } from '@mui/icons-material'
import { getExportFileName } from '../../../../../utils/grids/exports'
const defaultMaterialTheme = createTheme(theme)

const getErrorMessage = (props) => {
    if (props.errors) {
        if (props.errors === 'is a required property') {
            return 'requires at least one item'
        }
        if (props.errors.startsWith('must NOT')) {
            return 'requires at least one item'
        }
        return props.errors
    }
    return undefined
}
const ExistingGrid = (props) => {
    const {
        enabled,
        uischema
    } = props
    const { gridColumns, existingTableData, removeItem, existingLoading, exportId } =
        useContext(DataContext)
    const columns = useMemo(() => gridColumns, [gridColumns])
    const [resetKey, setResetKey] = useState(1)

    const options = useMemo(
        () => ({
            ...defaultOptions,
            selection: enabled,
            exportButton: true,
            exportAllData: true,
            exportFileName: getExportFileName(exportId, uischema.exportFileName)
        }),
        [enabled]
    )

    const tableRef = useRef(null)

    const deleteItem = useCallback(
        (data) => {
            if (tableRef && tableRef.current) {
                tableRef.current.onAllSelected(false)
                removeItem(data)
            }
        },
        [removeItem, tableRef]
    )


    const error = getErrorMessage(props)
    const onClearFilter = useCallback((col) => {
        col.forEach((c, idx) => {
            tableRef.current && tableRef.current.dataManager.changeFilterValue(idx, undefined)
        })
        setResetKey((prevKey) => prevKey + 1)
    }, [columns, tableRef])

    let actions = [
        {
            icon: () => <FilterAltOff />,
            tooltip: "Clear all filters",
            isFreeAction: true,
            onClick: (event) => {
                onClearFilter(columns)
            }
        }
    ]

    if (enabled) {
        actions = [
            ...actions, 
            {
            tooltip: 'Remove Selected',
            icon: 'delete',
            onClick: (evt, data) => deleteItem(data),
        }]
    }

    return (
        <div className="" style={{ maxWidth: '100%' }}>
            <MuiThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                    key={resetKey}
                    tableRef={tableRef}
                    columns={columns}
                    data={existingTableData}
                    isLoading={existingLoading}
                    padding="dense"
                    options={options}
                    actions={actions}
                    components={{
                        Toolbar: props => <Toolbar {...props} />
                    }}
                    title={
                        <React.Fragment>
                            <Grid justifyContent="space-around" display="flex">
                                <Grid item>
                                    <Typography variant="h6">Assigned</Typography>
                                </Grid>
                                <Grid item display="flex" alignItems="center" paddingLeft="10px">
                                    {error ? (
                                        <React.Fragment>
                                        <ErrorOutline color="error" />
                                        <Typography color="error" variant="caption">{error}</Typography>
                                        </React.Fragment>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </React.Fragment>}
                />
            </MuiThemeProvider>
        </div>
    )
}

export default ExistingGrid
