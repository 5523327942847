import React from 'react'
import { Link } from 'react-router-dom'
import CellWrapper from './CellWrapper'

const LinkCell = ({value, BaseUrl}) => {
    return (
        <CellWrapper>
            <Link to={`${BaseUrl}/${value}`}>
                {value}
            </Link>
        </CellWrapper>
    )
}

export default LinkCell