import React, { useContext } from 'react'
import urls from '../../../utils/constants/urls'

import Form from '../../elem/form/Form'
import FormWrapper from '../../elem/form/FormWrapper'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const Invoice = () => {
    const { invoice, setInvoice, submitting, schemas, editing, setEditing, errors, setErrors, additionalErrors, setAdditionalErrors, saveForm, resetForm, invoiceTitle } = useContext(DataContext)

    return (
        <PageLayout returnUrl={urls.invoices}>
            <FormWrapper title="Invoice" subTitle={invoiceTitle} editing={editing} setEditing={setEditing} schemas={schemas} data={invoice} errors={errors} saveForm={saveForm} resetForm={resetForm} loading={submitting} parentContext={DataContext}>
                <Form name='invoice' data={invoice} schemas={schemas} setData={setInvoice} errors={errors} setErrors={setErrors} additionalErrors={additionalErrors} setAdditionalErrors={setAdditionalErrors} editing={editing} />
            </FormWrapper>
        </PageLayout>
    )
}

export default Invoice
