import { composePaths, getSubErrorsAt, resolveData, toDataPath, toDataPathSegments } from "@jsonforms/core"
import { constructErrorMessage, findAllControlsInSchema, findAllDetailControlsInSchema, findControlElements, isListWithDetail, lookForFieldsWithOptions } from "../../../../utils/form/helpers"

const constructMustBeLabelString = (mustBeLabels) => {
    if (mustBeLabels && mustBeLabels.length) {
        if (mustBeLabels.length < 3) {
            return mustBeLabels.join(' or ')
        } else {
            return `${mustBeLabels.slice(0, mustBeLabels.length - 1).join(', ')}, or ${mustBeLabels[mustBeLabels.length -1]}`
        }
    } else {
        return ''
    }
} 

// get all errors for ui schema values that have options.limitDecimals
const getLimitDecimalErrors = (formState, elements, path=null) => {
    const fieldsWithLimitDecimals = lookForFieldsWithOptions(elements, 'limitDecimals')
    let errors = []
    if (formState.data) {
        const data = formState.data
        fieldsWithLimitDecimals.forEach(f => {
            const numDecimals = f.options.limitDecimals
            const controlConfig = f
            const controlPath = toDataPath(controlConfig.scope)
            const dataPath = path ? path : controlPath
            const controlData = resolveData(data, dataPath)
            if (!isNaN(controlData)) {
                const regexString = `^-?\\d+(\\.\\d{0,${numDecimals}})?$`;
                const regex = new RegExp(regexString, 'g')
                const passes = regex.test(controlData)
                if (!passes) {
                    errors.push(constructErrorMessage(dataPath, `cannot have more than ${numDecimals} decimals`, controlConfig.scope))
                }
            }
        })
    }
    return errors
}

const pushError = (errors, error) => {
    if (error && error.message && //makes it so error messages do not duplicate
        errors && !errors.find(x => x.message === error.message && x.instancePath === error.instancePath)
    ) {
        errors.push(error)
    }
}


const resolveDependentScope = (scope, f, path, data) => {

    let dependentPath = toDataPath(scope)
    let dependentData = resolveData(data, dependentPath)
    const controlConfig = f
    const controlPath = path ? composePaths(path, toDataPath(controlConfig.scope)) : toDataPath(controlConfig.scope)
    let controlData = resolveData(data, controlPath)
    if (path) {
            const propertyPath = composePaths(path, dependentPath)
            dependentData = resolveData(data, propertyPath)
    }
    
    return { dependentPath, dependentData, controlData, controlPath, controlConfig }
}

// get all errors for ui schema values that have options.dependentOn
const getDependsOnErrors = (formState, elements, path=null) => {
    const fieldsWithDependsOn = lookForFieldsWithOptions(elements, 'dependentOn')
    let errors = []
    if (formState.data) {
        const data = formState.data
        fieldsWithDependsOn.forEach(f => {
            // support array declarations for dependentOn.
            // if dependentOn is an object, just make it into a single value array
            // before looping through
            let dependentConfigs = f.options.dependentOn

            if (!Array.isArray(dependentConfigs)) { 
                dependentConfigs = [f.options.dependentOn] 
            }
            // loop through all dependentConfigs and accumulate errors
            dependentConfigs.forEach(dependentConfig => {
                //By passing an array to the dependentConfig.scope in the uiSchema you can specify a rule like "if field1 has value OR field[2,3...n] has value then targetField is required"
                let scopeProps = []
                if (Array.isArray(dependentConfig.scope)) {
                    dependentConfig.scope.forEach(scope => {
                        scopeProps.push(resolveDependentScope(scope, f, path, data))
                    })
                }
                else {
                    scopeProps = [resolveDependentScope(dependentConfig.scope, f, path, data)]
                }

                let error = null

                scopeProps.forEach(props => {
                    let { dependentPath, dependentData, controlData, controlPath, controlConfig } = props

                    // this handles those which require a specific value to be present for dependent on requirement
                    if (dependentConfig && dependentConfig.values) {
                        if (dependentConfig.values.includes(dependentData) && !controlData) {
                            error = constructErrorMessage(controlPath, `is a required property`, controlConfig.scope)
                        }
                    } else {
                        // and this handles those which do not
                        if (dependentData !== null && dependentData !== undefined && !controlData) {
                            error = constructErrorMessage(controlPath, `is a required property`, controlConfig.scope)
                        }
                    }
    
                    pushError(errors, error)
    
                    if (dependentConfig && dependentConfig.mustBe) {
                        const mustBeValues = dependentConfig.mustBe.map(x => x.value)
                        const mustBeLabels = dependentConfig.mustBe.map(x => x.label)
                        if (!mustBeValues.includes(dependentData) && controlData) {
                            error = constructErrorMessage(dependentPath, `must be ${constructMustBeLabelString(mustBeLabels)}`, dependentConfig.scope)
                            pushError(errors, error)
                        }
                    }    
                })
            })
        })
    }
    return errors
} 

const getAdditionalErrors = (formState, uischema) => {
    let additionalErrors = []
    if (formState) {
        const controlElements = []
        findAllControlsInSchema(uischema, controlElements)
        let dependsOnErrors = getDependsOnErrors(formState, controlElements)
        let limitDecimalErrors = getLimitDecimalErrors(formState, controlElements)

        // loop through array elements and find any associated errors
        const detailControlElements = []
        findAllDetailControlsInSchema(uischema, detailControlElements)
        detailControlElements.forEach(element => {
            // get the data path of the detail element
            const instancePath = toDataPath(element.scope)

            // get all child controls of the detail element (in the options field)
            const childElements = []
            findAllControlsInSchema(element, childElements)
            // separate out data elements vs array elements (will be tables)
            const arrayElements = childElements.filter(x => toDataPath(x.scope).split('.').length !== 1 && !isListWithDetail(x))
            const dataElements = childElements.filter(x => toDataPath(x.scope).split('.').length === 1)
            const detailData = resolveData(formState.data, instancePath)
            
            // handle the properties directly on the array element
            detailData?.forEach((x, idx) => {
                const rowPath = composePaths(instancePath, `${idx}`)
                dataElements.forEach(e => {
                    const propertyPath = composePaths(rowPath, toDataPath(e.scope))
                    const subDecimalErrors = getLimitDecimalErrors(formState, [e], propertyPath)
                    limitDecimalErrors = limitDecimalErrors.concat(...subDecimalErrors)
                })
                const subDependsOnErrors = getDependsOnErrors(formState, dataElements, rowPath)
                dependsOnErrors = dependsOnErrors.concat(...subDependsOnErrors)
            })

            // handle the array properties on the array element (will give table errors)
            detailData?.forEach((x, idx) => {
                const rowPath = composePaths(instancePath, `${idx}`)
                arrayElements.forEach((e, subIdx) => {
                    const childPath = toDataPath(e.scope)
                    const arrayPath = childPath.split('.')[0]
                    const propertyPath = childPath.split('.')[1]
                    const p = composePaths(composePaths(composePaths(rowPath, arrayPath), `${subIdx}`), `${propertyPath}`)
                    const subDecimalErrors = getLimitDecimalErrors(formState, [e], p)
                    limitDecimalErrors = limitDecimalErrors.concat(...subDecimalErrors)
                    const subDependsOnErrors = getDependsOnErrors(formState, arrayElements, rowPath)
                    dependsOnErrors = dependsOnErrors.concat(...subDependsOnErrors)
                })
                
            })
        })
        additionalErrors = [...additionalErrors, ...dependsOnErrors, ...limitDecimalErrors]
    }
    return additionalErrors
}

export default getAdditionalErrors