import Ajv, { str, _ } from 'ajv'
import addFormats from 'ajv-formats'
import addErrors from 'ajv-errors'
import dateKeywords from './date'
import numberKeywords from './number'
import numberZipCodeKeywords from './zipCode'
import addDependencyKeyword from './dependencies'

// customize the validator to add keywords that we need
// extends the json forms default ajv instance https://jsonforms.io/docs/validation
const ajv = new Ajv({
    allErrors: true,
    verbose: true,
    strict: false,
    $data: true,
})

addFormats(ajv)
addErrors(ajv)

addDependencyKeyword(ajv)
dateKeywords.map((keyWord) => ajv.addKeyword(keyWord))
numberKeywords.map((keyWord) => ajv.addKeyword(keyWord))

numberZipCodeKeywords.map((keyWord) => ajv.addKeyword(keyWord))

export default ajv
