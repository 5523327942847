import { materialRenderers } from '@jsonforms/material-renderers'

import LinkControlRenderer from './link/LinkControlRenderer'
import linkControlTester from './link/linkControlTester'


import DisplayLinkControlRenderer from './display-links/DisplayLinkControlRenderer'
import displayLinkControlTester from './display-links/displayLinkControlTester'


import selectOrCreateControlTester from './selectOrCreate/selectOrCreateControlTester'
import SelectOrCreateControlRenderer from './selectOrCreate/SelectOrCreateRenderer'

import lookupControlRenderer from './lookup/lookupControlRenderer'
import lookupControlTester from './lookup/lookupControlTester'

import TightRenderer from './tight/TightRenderer'
import { tightTester } from './tight/TightUtils'

import DateControlRenderer, { dateControlTester } from './date/DateControlRenderer'
import NumericControlRenderer, { numericControlTester } from './number/NumericControlRenderer'
import DateTimeControlRenderer, { dateTimeControlTester } from './date-time/DateTimeControlRenderer'
import CurrencyControlRenderer, { currencyControlTester } from './currency/CurrencyControlRenderer'
import LabelControlRenderer, { labelControlTester } from './label/LabelControlRenderer'
import PhoneControlRenderer, { phoneControlTester } from './phone/PhoneControlRenderer'
import MaterialArrayLayoutRenderer, { materialArrayLayoutTester } from './array-layout/MaterialArrayLayoutRenderer'
import MaterialArrayControlRenderer, { materialArrayControlTester } from './array/ArrayControlRenderer'
import MaterialCategorizationLayoutRenderer, { materialCategorizationTester} from './categorization/CategorizationLayoutRenderer'

const renderers = [
    ...materialRenderers,
    {
        tester: linkControlTester,
        renderer: LinkControlRenderer,
    },
    {
        tester: displayLinkControlTester,
        renderer: DisplayLinkControlRenderer,
    },
    {
        tester: numericControlTester,
        renderer: NumericControlRenderer,
    },
    {
        tester: selectOrCreateControlTester,
        renderer: SelectOrCreateControlRenderer,
    },
    {
        tester: lookupControlTester,
        renderer: lookupControlRenderer,
    },
    {
        tester: tightTester,
        renderer: TightRenderer,
    },
    {
        tester: dateControlTester,
        renderer: DateControlRenderer,
    },
    {
        tester: dateTimeControlTester,
        renderer: DateTimeControlRenderer,
    },
    {
        tester: currencyControlTester,
        renderer: CurrencyControlRenderer,
    },
    {
        tester: labelControlTester,
        renderer: LabelControlRenderer,
    },
    {
        tester: phoneControlTester,
        renderer: PhoneControlRenderer,
    },
    {
        tester: materialArrayLayoutTester,
        renderer: MaterialArrayLayoutRenderer
    },
    {
        tester: materialCategorizationTester,
        renderer: MaterialCategorizationLayoutRenderer
    },
    {
        tester: materialArrayControlTester,
        renderer: MaterialArrayControlRenderer
    }
]

export default renderers
