import React, { useCallback, useEffect } from 'react'
import { Hidden } from '@mui/material'

import LinkDataContextProvider from './LinkDataContext.js'
import ExistingGrid from './existing/ExistingGrid.js'
import AvailableGrid from './available/AvailableGrid.js'
import { update } from '@jsonforms/core'
import { useJsonForms } from '@jsonforms/react'

const LinkControl = (props) => {
    const { data, path, uischema, visible } = props
    const {
        dispatch,
        core: { data: parentData },
    } = useJsonForms()
    
    const setData = useCallback(
        (updatedData) => {
            dispatch(update(path, () => updatedData))
        },
        [data, path, dispatch]
    )

    return (
        <Hidden xsUp={!visible}>
            <LinkDataContextProvider
                existingLinkData={data}
                parentData={parentData}
                setData={setData}
                path={path}
                {...uischema}
            >
                <ExistingGrid {...props} />
                <AvailableGrid enabled={props.enabled} />
            </LinkDataContextProvider>
        </Hidden>
    )
}

export default LinkControl
