import React, { useState } from 'react'
import { AppBar, Hidden, Tab, Tabs } from '@mui/material'
import { and, getControlPath, getSubErrorsAt, isControl, isVisible, iterateSchema, rankWith, toDataPath, uiTypeIs } from '@jsonforms/core'
import { useJsonForms, withJsonFormsLayoutProps } from '@jsonforms/react'
import {
    MaterialLayoutRenderer,
    withAjvProps,
} from '@jsonforms/material-renderers'
import ErrorOutline from '@mui/icons-material/ErrorOutline'
import { findAllControlsInSchema, findAllDetailControlsInSchema } from '../../../../../utils/form/helpers'

const getCategoryErrors = (ctx, categories) => {
    // get the paths of all errors in the form
    const errorControlPaths = ctx.core.errors.map(x => getControlPath(x))
    const additionalErrorPaths = ctx.core.additionalErrors.map(x => getControlPath(x))

    // loop through the categories and find associated errors on their pages
    let errorData = []
    categories.forEach(category => {
        let categoryErrors = []
        // get the control elements for each category
        let controlElements = []
        findAllControlsInSchema(category, controlElements)

        // if any control elements have an error associated
        // with them, add them to an array
        controlElements.forEach(element => {
            const instancePath = toDataPath(element.scope)
            if (errorControlPaths.includes(instancePath)) {
                categoryErrors.push(instancePath)
            }
            if (additionalErrorPaths.includes(instancePath)) {
                categoryErrors.push(instancePath)
            }
        })

        // if any of the error paths are because of an array, find the appropriate controls and
        // also add them to the array
        const detailControlElements = []
        findAllDetailControlsInSchema(category, detailControlElements)
        detailControlElements.forEach(element => {
            const instancePath = toDataPath(element.scope)
            const childErrors = getSubErrorsAt(instancePath, ctx.schema)({...ctx.core, jsonforms: ctx})
            if (childErrors && childErrors.length) {
                categoryErrors.push(instancePath)
            }
        })
        

        errorData.push({
            category: category.label,
            errors: categoryErrors
        })
    })
    return errorData
}

export const isSingleLevelCategorization = and(
    uiTypeIs('Categorization'),
    (uischema) => {
        const categorization = uischema

        return (
            categorization.elements &&
            categorization.elements.reduce(
                (acc, e) => acc && e.type === 'Category',
                true
            )
        )
    }
)

export const materialCategorizationTester = rankWith(
    2,
    isSingleLevelCategorization
)

export const MaterialCategorizationLayoutRenderer = (props) => {
    const {
        data,
        path,
        renderers,
        cells,
        schema,
        uischema,
        visible,
        enabled,
        selected,
        onChange,
        ajv,
    } = props
    const categorization = uischema
    const ctx = useJsonForms()

    const [activeCategory, setActiveCategory] = useState(selected ?? 0)
    const categories = categorization.elements.filter((category) =>
        isVisible(category, data, undefined, ajv)
    )
    const activeElements = categories[activeCategory] ? categories[activeCategory].elements : categories.length > 0 ? categories[0].elements : []
    const childProps = {
        elements: activeElements,
        schema,
        path,
        direction: 'column',
        enabled,
        visible,
        renderers,
        cells,
    }
    const onTabChange = (_event, value) => {
        if (onChange) {
            onChange(value, activeCategory)
        }
        setActiveCategory(value)
    }

    const categoryErrors = getCategoryErrors(ctx, categories)

    return (
        <Hidden xsUp={!visible}>
            <AppBar position="static">
                <Tabs
                    value={activeCategory}
                    onChange={onTabChange}
                    textColor="inherit"
                    indicatorColor="secondary"
                    variant="scrollable"
                >
                    {categories.map((e, idx) => {
                            const tabErrors = categoryErrors.find(x => x.category === e.label)
                            if (tabErrors && tabErrors.errors && tabErrors.errors.length && enabled) {
                                return <Tab key={idx} label={e.label} iconPosition="end" icon={<ErrorOutline fontSize="small" color="inherit" />} />
                            }
                            return <Tab key={idx} label={e.label} />
                        }
                    )}
                </Tabs>
            </AppBar>
            <div style={{ marginTop: '0.5em' }}>
                <MaterialLayoutRenderer {...childProps} />
            </div>
        </Hidden>
    )
}

export default withJsonFormsLayoutProps(
    withAjvProps(MaterialCategorizationLayoutRenderer)
)
