import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Loader = ({size="3rem"}) => (
    <Wrapper>
        <CircularProgress size={size} sx={{ color: '#1976d2' }} />
    </Wrapper>
);

export default Loader;
