import get from 'lodash/get'

const titles = {
    facility: ['permitNumber', 'apiNumber'],
    bidPackage: ['bidPackageName'],
    bidResponse: ['contractId', 'bidPackage.bidPackageName'],
    invoice: ['bidResponse.contractId', 'bidResponse.bidPackage.bidPackageName', 'invoiceIdAt']
}

const constructFormTitle = (data, title) => {
    if (title && data && Object.keys(data).length) {
        if (Array.isArray(title)) {
            const subTitle = title.reduce((acc, curr, idx) => {
                const value = get(data, curr)
                if (value) {
                    if (acc === '') {
                        return value
                    } else {
                        return acc + ' | ' + value
                    }
                } else {
                    return acc
                }
                }, '')
            return subTitle
        } else {
            return title
        }
    } else {
        return null
    }
}
export { constructFormTitle }
export default titles
