import React, { createContext, useContext } from 'react'


const ParentFormContext = createContext(null)

const ParentFormContextProvider = ({children, parentContext}) => {
    const ctx = useContext(parentContext)

    return (
        <ParentFormContext.Provider value={ctx}>
            {children}
        </ParentFormContext.Provider>
    )
}

export { ParentFormContext }
export default ParentFormContextProvider