import React, { useContext, useMemo, useState } from 'react'
import {
    FormControl,
    TextField
} from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import { DataContext } from './SelectOrCreateDataContext'
import { createFilterOptions } from '@mui/material/Autocomplete'

const filterOptions = createFilterOptions({
    limit: 100
})

const SelectControl = (props) => {
    const { isCreating, availableData, existingData, linkIdColumn, displayColumn, handleSelectChange, error } = useContext(DataContext)
    const initialValue = useMemo(() => (existingData ? { label: existingData[displayColumn], id: existingData[linkIdColumn] } : ''), [existingData])

    const { required } = props;

    if (isCreating) {
        return null
    }
    const isValid = existingData === null

    return (
        <FormControl fullWidth>
            <Autocomplete
                value={initialValue}
                onChange={handleSelectChange}
                filterOptions={filterOptions}
                includeInputInList
                autoComplete
                disableClearable
                options={availableData}
                renderInput={(params) => <TextField {...params} label="Select Existing..." required={true} error={isValid} InputLabelProps={{ required: false }}
                    variant="standard" />}
            />
        </FormControl>
    )
}

export default SelectControl