import React, { useEffect } from 'react'
import { matchSorter } from 'match-sorter'
const filterTypes = () => ({
    fuzzyText: fuzzyTextFilterFn,

    text: (rows, id, filterValue) => {
        return rows.filter((row) => {
            const rowValue = row.values[id]
            return rowValue !== undefined
                ? String(rowValue)
                      .toLowerCase()
                      .startsWith(String(filterValue).toLowerCase())
                : true
        })
    },
})

const DefaultColumnFilter = ({
    column: { id, filterValue, preFilteredRows, setFilter },
    setQuery, clear
}) => {
    const count = preFilteredRows.length
    useEffect(() => {
        if (clear && filterValue) {
            setFilter(undefined)
        }
    }, [clear])
    return (
        <input
            style={{
                maxWidth: '143px',
                width: 'inherit',
                textOverflow: 'ellipsis',
            }}
            value={filterValue || ''}
            onChange={(e) => {
                setFilter(e.target.value || undefined)
                setQuery({ [id]: e.target.value })
                e.target.value
                    ? setQuery({ [id]: e.target.value })
                    : setQuery({ [id]: undefined })
            }}
            placeholder={`Search ${count} records...`}
        />
    )
}

const SelectColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
}) => {
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach((row) => {
            options.add(row.values[id])
        })
        return [...options.values()]
    }, [id, preFilteredRows])

    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined)
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    )
}

const NumberRangeColumnFilter = ({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) => {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <input
                value={filterValue[0] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ])
                }}
                placeholder={`Min(${min})`}
                style={{
                    width: '75px',
                    marginRight: '0.1rem',
                }}
            />

            <input
                value={filterValue[1] || ''}
                type="number"
                onChange={(e) => {
                    const val = e.target.value
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ])
                }}
                placeholder={`Max(${max})`}
                style={{
                    width: '75px',
                    marginLeft: '0.1rem',
                }}
            />
        </div>
    )
}

const fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

export {
    DefaultColumnFilter,
    SelectColumnFilter,
    NumberRangeColumnFilter,
    fuzzyTextFilterFn,
    filterTypes,
}
