import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { Autorenew, Save } from '@material-ui/icons'
import { Button, Card, CardActions } from '@mui/material'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import Spinner from '../../Spinner'
import { DataContext } from './SelectOrCreateDataContext'
import renderers from '../renderers/renderers'
import ajv from '../ajv/ajv'

const Create = () => {
    const { uischema, schema, name, saveFormData } = useContext(DataContext)
    const initialData = { [name]: {} }
    const [formData, setFormData] = useState(initialData)
    const [formErrors, setFormErrors] = useState()

    const handleFormChange = useCallback(
        ({ data, errors }) => {
            setFormData(data)
            setFormErrors(errors)
        },
        [setFormData, setFormErrors]
    )
    
    const canSave = useMemo(() => {
        return (
            typeof formErrors === 'undefined' ||
            !(formErrors && formErrors.length)
        )
    }, [formErrors])

    const saveRecord = useCallback(() => {
        saveFormData(formData)
    }, [saveFormData, formData])

    const resetRecord = useCallback(() => {
        setFormData(initialData)
    }, [initialData])

    if (!schema) {
        return <Spinner />
    }

    return (
        <div className="createRecordForm">
            <JsonForms
                schema={schema}
                uischema={uischema}
                data={formData}
                ajv={ajv}
                renderers={renderers}
                cells={materialCells}
                onChange={handleFormChange}
            />
            <Card>
                <CardActions>
                    <Button
                        startIcon={<Save />}
                        onClick={saveRecord}
                        fullWidth
                        disabled={!canSave}
                    >
                        Create
                    </Button>
                    <Button
                        startIcon={<Autorenew />}
                        onClick={resetRecord}
                        fullWidth
                    >
                        Clear
                    </Button>
                </CardActions>
            </Card>
        </div>
    )
}
export default Create
