import { FormControl, IconButton, Icon, InputAdornment, OutlinedInput, TableCell } from '@mui/material'
import { Clear, Search } from '@mui/icons-material'
import React, { useCallback, useEffect, useRef, useState } from 'react'

const getInputType = (type) => {
    switch (type) {
        case 'String':
            return 'text'
        case 'Link':
            return 'number'
        case 'Currency':
        case 'Number':
            return 'number'
        case 'Date':
        case 'DateTime':
            return 'date'
        default:
            return 'text'
    }
}

const SearchField = ({ accessor, onSearch, column, queryParameters }) => {
    const [value, setValue] = useState()
    const inputRef = useRef(null)
    const search = useCallback(() => {
        onSearch(accessor, value)
    }, [value, onSearch])
    
    const searchType = getInputType(column.type)

    const clearForm = useCallback(() => {
        const newValue = null
        inputRef.current.value = newValue
        setValue(newValue)
        onSearch(accessor, newValue)
    }
    , [onSearch, setValue, inputRef])

    useEffect(() => {
        if (queryParameters && !Object.keys(queryParameters).includes(accessor)) {
            inputRef.current.value = null //cleans out the text from the searchfield when they are no longer being used to filter the grid 
        }    
    }, [queryParameters])
    
    return (
        <TableCell 
        sx={{px: 1, py: 0, width: column.width, display: 'flex'}}
        >
            <FormControl variant="outlined" size="small" margin="dense" fullWidth>
                <OutlinedInput
                sx={{ fontSize: '0.9rem'}}
                inputProps={{
                    style: {height: '1.1375em'},
                    max: "9999-12-31"
                }}
                inputRef={inputRef}
                type={searchType}
                onChange={(evt) => {
                    const value = evt.target.value
                    setValue(value)
                }}
                onBlur={() => {
                    search()
                }}
                endAdornment={
                    <InputAdornment position='end' >
                        <IconButton edge="end" size="small" onClick={clearForm}>
                            <Clear />
                        </IconButton>
                    </InputAdornment>
                }
                />
            </FormControl>
        </TableCell>
    )
}

export default SearchField