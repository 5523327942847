import React, { useCallback, useEffect, useMemo } from 'react'
import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'

import { getMergedSchema } from '../../../utils/json/schemas'

import renderers from './renderers/renderers'
import ajv from './ajv/ajv'
import getAdditionalErrors from './validation/getAdditionalErrors'
import Spinner from '../Spinner'

const Form = ({ name, data, schemas, setData, editing, setErrors, setAdditionalErrors, additionalErrors }) => {
    const formData = useMemo(() => data, [data])
    const schema = useMemo(
        () => (schemas ? getMergedSchema(name, schemas) : null),
        [schemas]
    )
    const uischema = schemas ? schemas.uiSchema : {}
    const onChange = useCallback(
        (state) => {
            const { data, errors } = state
            setData(data)
            setErrors(errors)
            setAdditionalErrors(getAdditionalErrors(state, uischema))
        }, [editing, setData, setErrors, setAdditionalErrors, uischema])

    if (!schema || !data) {
        return (
            <div className="hero is-large">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="title">
                        <div className="callbackSpinnerWrapper">
                        <Spinner size={'3rem'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }

    return (
        <div className="columns is-centered formWrapper">
            <div className="column is-12">
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={formData}
                    ajv={ajv}
                    renderers={renderers}
                    additionalErrors={editing ? additionalErrors : []}
                    cells={materialCells}
                    onChange={onChange}
                    readonly={!editing}
                    validationMode={
                        !editing ? 'NoValidation' : 'ValidateAndShow'
                    }
                />
            </div>
        </div>
    )
}

export default Form
