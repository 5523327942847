import React, { useContext, useMemo } from 'react'
import urls from '../../../utils/constants/urls'
import Grid from '../../elem/grid/display/Grid'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'
import Loader from '../Loader'

const Facilities = () => {
    const { facilities, listMetadata, updateQueryParameters, queryParameters, loading, gridConfig, gridConfigExpanded } = useContext(DataContext)

    return (
        <PageLayout returnUrl={urls.home}>
                <Grid
                    metaData={listMetadata}
                    data={facilities}
                    updateQueryParameters={updateQueryParameters}
                    queryParameters={queryParameters}
                    gridConfig={gridConfig}
                    name={'facilities'}
                    loading={loading}
                    gridConfigExpanded={gridConfigExpanded}
                />
        </PageLayout>
    )
}

export default Facilities
