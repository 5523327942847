import React, { useContext, useEffect, useMemo } from 'react'
import urls from '../../../utils/constants/urls'
import Grid from '../../elem/grid/display/Grid'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const FacilityGroups = () => {
    const {  listMetadata, updateQueryParameters, facilityGroups, loading, gridConfig, gridConfigExpanded, createLink } = useContext(DataContext)

    return (
        <PageLayout returnUrl={urls.home}>
            <div className="facilityGroupWrapper">
                <Grid
                    metaData={listMetadata}
                    updateQueryParameters={updateQueryParameters}
                    data={facilityGroups}
                    gridConfig={gridConfig}
                    name={'facilityGroups'}
                    loading={loading}
                    gridConfigExpanded={gridConfigExpanded}
                    createLink={createLink}
                />
            </div>
        </PageLayout>
    )
}

export default FacilityGroups