import React from 'react'
import { LoadingButton } from '@mui/lab'

const ButtonWrapper = ({
    text,
    iconComponent,
    display = true,
    action,
    extraClass,
    size = 'normal',
    loading=false
}) => {
    if (!display) {
        return null
    }
    return (
        <LoadingButton 
            loading={loading}
            className={`${extraClass}`} 
            size={size} 
            variant='outlined'
            loadingPosition='start'
            onClick={action}
            startIcon={iconComponent ? React.createElement(iconComponent) : null}
            >
            {text}
        </LoadingButton>
    )
}

export default ButtonWrapper
