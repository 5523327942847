import React from 'react'
import { rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsLayoutProps } from '@jsonforms/react'
import { Hidden, Typography } from '@material-ui/core'
import { Box } from '@mui/material'

export const labelControlTester = rankWith(2, uiTypeIs('Label'))

export const LabelControlRenderer = (props) => {
    const { uischema, visible, enabled } = props
    const labelElement = uischema
    const { variant, text, color, align, editingOnly } = labelElement
    const displayLabel = editingOnly ? !enabled : !visible
    return (
        <Hidden xsUp={displayLabel}>
            <Box
                fullWidth
                justifyContent={align}
                display={align ? 'flex' : 'inherit'}
                sx={{
                    mb: variant && variant === 'caption' ? 2 : 0,
                    color: color ? color : 'inherit',
                }}
            >
                <Typography
                    variant={variant ? variant : 'h6'}
                    align={align ? align : 'inherit'}
                >
                    {text !== undefined && text !== null && text}
                </Typography>
            </Box>
        </Hidden>
    )
}

export default withJsonFormsLayoutProps(LabelControlRenderer)
