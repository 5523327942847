import React from 'react'
import { withJsonFormsArrayControlProps } from '@jsonforms/react'
import LinkControl from '../../../grid/link/LinkControl'
import { Hidden } from '@mui/material'

export const LinkControlRenderer = (props) => {
    const { visible } = props
    return (
        <Hidden xsUp={!visible}>
            <LinkControl {...props} />
        </Hidden>
    )
}

export default withJsonFormsArrayControlProps(LinkControlRenderer)
