import { str, _ } from 'ajv'

const dependencies = (ajv) => {
    const depKw = ajv.getKeyword('dependencies')
    depKw.error = {
        message: ({ params, parentSchema }) => {
            const { property, depsCount, deps } = params
            const { properties } = parentSchema
            const requiredPropertySchema = properties[deps]
            const requiredPropertyString = requiredPropertySchema && requiredPropertySchema.title ? requiredPropertySchema.title : deps
            const propertySchema = properties[property]
            const propertyString = propertySchema && propertySchema.title ? propertySchema.title : property
            return str`${requiredPropertyString} is required when a value is entered for ${propertyString}`
        },
        params: ({ params: { property, depsCount, deps, missingProperty } }) =>
            _`{property: ${property},
          missingProperty: ${missingProperty},
          depsCount: ${depsCount},
          deps: ${deps}}`, // TODO change to reference
    }
    
    ajv.removeKeyword('dependencies')
    ajv.addKeyword(depKw)
}

export default dependencies