import React from 'react'
import NavBar from '../features/navigation/Navbar'
import withConfig from '../wrappers/withConfig'

const PageLayout = withConfig(({ config, returnUrl, children }) => {
    const { APP_TITLE } = config
    return (
        <div className="pageWrapper">
            <NavBar title={APP_TITLE} returnUrl={returnUrl} />
            <div>
                {children}
            </div>
        </div>
    )
})

export default PageLayout