const theme = {
    palette: {
        secondary: {
            main: '#c9ccf6',
          },
    },
}

const defaultOptions = {
    filtering: true,
    selection: true,
    search: true,
    headerStyle: {
        position: 'sticky',
        top: 0,
        height: 50,
    },
    maxBodyHeight: '60vh',
    filterRowStyle: {
        position: 'sticky',
        top: 50,
        background: 'white',
        zIndex: 5 /* optionally */,
    },
    pageSize: 25,
    pageSizeOptions: [25, 50, 100]
}

export { theme, defaultOptions }
