import React, { useContext } from 'react'
import urls from '../../../utils/constants/urls'

import Form from '../../elem/form/Form'
import FormWrapper from '../../elem/form/FormWrapper'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const BidPackage = () => {
    const { bidPackage, setBidPackage, submitting, schemas, editing, setEditing, errors, setErrors, additionalErrors, setAdditionalErrors, saveForm, resetForm, bidPackageTitle } = useContext(DataContext)
    
    return (
        <PageLayout returnUrl={urls.bidPackages}>
            <FormWrapper title="Bid Package" subTitle={bidPackageTitle} editing={editing} setEditing={setEditing} schemas={schemas} data={bidPackage} errors={errors} saveForm={saveForm} resetForm={resetForm} loading={submitting} parentContext={DataContext}>
                <Form name='bidPackage' data={bidPackage} schemas={schemas} setData={setBidPackage} errors={errors} setErrors={setErrors} additionalErrors={additionalErrors} setAdditionalErrors={setAdditionalErrors} editing={editing} />
            </FormWrapper>
        </PageLayout>
    )

}

export default BidPackage