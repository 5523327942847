import { Cancel, Clear, Edit, Refresh, Save } from '@mui/icons-material'
import React, { useState, useContext, useEffect } from 'react'
import Button from '../Button'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getLookupOptions } from './lookup/actions'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useParams } from 'react-router-dom'
import { saveAs } from 'file-saver'
import toast from '../../elem/Toast'
import WithConfig from '../../wrappers/withConfig'
import { UserContext } from '../../wrappers/UserContext'
import { checkRole, hasAccessToAdmin } from '../../../utils/user/permissions'
import { Hidden } from '@mui/material'

const FormControls = WithConfig(
    ({ editing, data, schemas, loading, setEditing, saveForm, resetForm, config }) => {
        const { roles } = useContext(UserContext)
        const [reportValue, setReportValue] = useState('')
        const [lookupData, setLookupData] = useState([])
        const { authenticatedFetch } = useContext(APIRequestContext)
        const { bidPackageId } = useParams()
        const { API_URL } = config
        const hasEditorRole = checkRole('Editor', roles)
        const hasAdminRole = hasAccessToAdmin(roles)

        useEffect(() => {
            const options = {
                tableName: 'RefCodes',
                codeColumn: 'Code',
                descriptionColumn: 'Description',
                keyColumn: 'Fld',
                keyValue: 'ReportTypeBidPackage',
            }
            getLookupOptions(authenticatedFetch, API_URL, options, (d) => {
                setLookupData(d)
            })
        }, [authenticatedFetch])

        const handleChange = (event) => {
            setReportValue(event.target.value)
        }

        const handlePress = () => {
            let fileName = reportValue;
            authenticatedFetch(
                `${API_URL}/StateDocument/GenerateDocX/${bidPackageId}/${reportValue}`
            )
                .then(async (response) => {
                    if (response.ok) {
                        try {
                            fileName = response.headers
                                .get('content-disposition')
                                .match(/filename=(.+);/)[1]
                                .replace(/(^")|("$)/g, '');
                        } catch (error) {
                            fileName = reportValue;
                        }
                        return response.blob()
                    } else {
                        const error = await response.text()
                        throw new Error(error)
                    }
                })
                .catch((e) => {
                    toast({
                        level: 'error',
                        message:
                            'Workflow Landing Page:' +
                            (e.message
                                ? e.message
                                : 'Unable to connect to the server'),
                    })
                })
                .then((d) => {
                    saveAs(d, fileName)
                })
                .finally(() => {})
        }

        return (
            <Hidden xsUp={!schemas || !data }>
                {!editing && (
                    <div className="level-item">
                        {bidPackageId && (
                            <div style={{ marginRight: 50 }}>
                                <Select
                                    sx={{
                                        minWidth: 300,
                                        marginLeft: 2,
                                        marginRight: 2,
                                    }}
                                    size="small"
                                    value={reportValue}
                                    onChange={handleChange}
                                    label="Report"
                                    variant="standard"
                                    color="primary"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {lookupData.map((d, index) => (
                                        <MenuItem
                                            key={index.toString()}
                                            value={d.id}
                                        >
                                            {d.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <Button
                                    text="Go"
                                    size="small"
                                    action={handlePress}
                                />
                            </div>
                        )}
                        {(hasEditorRole || hasAdminRole) && (
                            <Button
                                text="Edit"
                                iconComponent={Edit}
                                size="small"
                                action={() => setEditing(true)}
                            />
                        )}
                    </div>
                )}
                {editing && (
                    <div className="level-item">
                        <Button
                            text="Save"
                            iconComponent={Save}
                            loading={loading}
                            size="small"
                            action={() => saveForm()}
                        />
                    </div>
                )}
                {editing && (
                    <div className="level-item">
                        <Button
                            text="Cancel"
                            iconComponent={Clear}
                            size="small"
                            loading={loading}
                            action={() => {
                                setEditing(false)
                                resetForm(true)
                            }}
                        />
                    </div>
                )}
                {editing && (
                    <div className="level-item">
                        <Button
                            text="Reset"
                            iconComponent={Refresh}
                            loading={loading}
                            size="small"
                            action={() => {
                                resetForm()
                            }}
                        />
                    </div>
                )}
                {/* Hide Export - GPA-102
            !editing && (
                <div className="level-item">
                    <Button
                        text="Export"
                        size="small"
                        iconClass="fas fa-download"
                        action={() => {}}
                    />
                </div>
            )*/}
            </Hidden>
        )
    }
)

export default FormControls
