import React, { useContext, useMemo, useState } from 'react'
import { Autocomplete, InputLabel, FormControl, FormHelperText, TextField } from '@mui/material'
import { DataContext } from './LookupDataContext'
import { createFilterOptions } from '@mui/material/Autocomplete'
import { Link } from 'react-router-dom'

const filterOptions = createFilterOptions({
    limit: 100,
})

const SelectControl = (props) => {
    const { enabled, uischema, errors, description, showDescription, data } = props
    const { lookupOptions, selectedOption, handleSelectChange } =
        useContext(DataContext)

    const initialValue = useMemo(
        () => (selectedOption ? selectedOption : ''),
        [selectedOption]
    )
    const [inputValue, setInputValue] = useState(initialValue ?? undefined)

    const { label, options: { linkTarget } } = uischema

    const isValid = errors.length === 0
    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
        
    return (
        <FormControl fullWidth error={!isValid}>
            <Autocomplete
                value={initialValue}
                onChange={handleSelectChange}
                onInputChange={(_event, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                sx={{paddingBottom: 'none'}}
                inputValue={inputValue}
                filterOptions={filterOptions}
                includeInputInList
                autoComplete
                getOptionDisabled={(option) =>
                    option?.active === false || option?.active === true ? option?.active === false : false                
                  }
                options={lookupOptions}
                disabled={!enabled}
                renderInput={(params) =>
                    linkTarget && isValid && !enabled ? (
                        <TextField
                            {...params}
                            error={!isValid}
                            label={label}
                            variant="standard"
                            InputProps={{
                                ...params.InputProps,
                                inputComponent: () => (
                                    <Link to={`${linkTarget}/${data}`} style={{padding: "4px 0 5px"}}> {inputValue} </Link>
                                )
                            }}
                        />
                    ) : (
                        <TextField
                            {...params}
                            error={!isValid}
                            label={label}
                            variant="standard"
                        />
                    )
                }
            />
            <FormHelperText error={!isValid && !showDescription}>
                {firstFormHelperText}
            </FormHelperText>
        </FormControl>
    )
}

export default SelectControl
