import React, { useCallback } from 'react'
import { Button, IconButton, TablePagination } from '@mui/material'
import GridControls from './display/GridControls'
import Pagination from './Pagination'
import Loader from '../../features/Loader'
import styled from 'styled-components'
import { FaCog } from 'react-icons/fa'

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1rem;
`

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-basis: 25%;
`

const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-basis: auto;
`

const WrapperLeft = styled.div`
    display: flex;
    justify-content: start;
    flex-basis: 25%;
`

const ToggleGridConfigButton = ({ gridConfigExpanded, onClick }) => {
    return (
        <IconButton
            size={'small'}
            onClick={() => onClick()}
            color={gridConfigExpanded ? 'primary' : undefined}
        >
            <FaCog />
        </IconButton>
    )
}

const Header = (props) => {
    const tablePaginationProps = {
        count: props.count,
        onPageChange: (evt, page) => {
            page === props.pages
                ? props.gotoPage(page - 1)
                : props.gotoPage(page)
        },
        page: props.pageIndex,
        rowsPerPage: props.pageSize,
        onRowsPerPageChange: (evt) => props.setPageSize(evt.target.value),
        showFirstButton: true,
        showLastButton: true,
    }
    const loaderSize = '1rem'
    const cellStyle = { borderBottom: 'none' }

    const { setGridConfigExpanded, gridConfigExpanded } = props
    const toggleGridConfigExpanded = useCallback(() => {
        setGridConfigExpanded(!gridConfigExpanded)
    }, [setGridConfigExpanded, gridConfigExpanded])
    return (
        <Container>
            <WrapperLeft>
                <ToggleGridConfigButton
                    gridConfigExpanded={gridConfigExpanded}
                    onClick={toggleGridConfigExpanded}
                />
                {props.loading ? <Loader size={loaderSize} /> : null}
            </WrapperLeft>
            <Wrapper>
                <GridControls
                    clearFunction={props.clearFunction}
                    createLink={props.createLink}
                />
            </Wrapper>
            <PaginationWrapper>
                <TablePagination style={cellStyle} {...tablePaginationProps} />
                <Pagination {...props} style={cellStyle} />
            </PaginationWrapper>
        </Container>
    )
}

export default Header
