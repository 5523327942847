import React, { useCallback, useEffect, useState } from 'react'
import merge from 'lodash/merge'
import NumberFormat from 'react-number-format'
import { isDescriptionHidden } from '@jsonforms/core'
import { useFocus } from '@jsonforms/material-renderers'
import {
    FormControl,
    FormHelperText,
    TextField,
} from '@mui/material'
import { withJsonFormsControlProps } from '@jsonforms/react'

export const numericControlTester = (uiSchema) => {
    return uiSchema.specializedType && uiSchema.specializedType === 'Numeric' ? 7 : 0
}

// const IntegerFormatCustom = React.forwardRef((props, ref) => {
//     const { onChange, ...other } = props
//     const value = other.value ? other.value : ''
//     return (
//         <NumberFormat
//             {...other}
//             value={value}
//             getInputRef={ref}
//             onValueChange={(values) => {
//                 onChange({
//                     target: {
//                         name: props.name,
//                         value: values.value,
//                     },
//                 })
//             }}
//             decimalPrecision={0}
//             decimalSeparator={false}
//         />
//     )
// })

const NumericControlRenderer = (props) => {
    const [focused] = useFocus()
    const {
        description,
        errors,
        label,
        uischema,
        visible,
        enabled,
        required,
        schema,
        path,
        handleChange,
        data,
        config,
    } = props

    const [value, setValue] = useState(data)    
    const isValid = errors.length === 0
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    )

    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
    const secondFormHelperText = showDescription && !isValid ? errors : null
    
    useEffect(() => {
        setValue(data)
    }, [data])

    const onChange = useCallback(
        (evt) => {
            const newVal = evt.target.value
            if (!newVal) {
                setValue(null)                
                return
            }

            setValue(Math.abs(schema.type = 'integer' ? parseInt(newVal) : newVal))
        },
        [path, handleChange]
    )
    
    return (
        <FormControl fullWidth>
            <TextField
                label={label}
                required={required}
                disabled={!enabled}
                type="number"
                variant="standard"
                InputLabelProps={data ? { shrink: true, required: false } : { required: false }}
                error={!isValid}
                // inputProps={{
                    // inputComponent: IntegerFormatCustom ,
                    // inputComponent: schema.type = 'integer' ? IntegerFormatCustom : NumberFormatCustom,
                // }}
                onChange={onChange}
                value={value}
            />
            <FormHelperText error={!isValid && !showDescription}>
                {firstFormHelperText}
            </FormHelperText>
            <FormHelperText error={!isValid}>
                {secondFormHelperText}
            </FormHelperText>
        </FormControl>
    );
}

export default withJsonFormsControlProps(NumericControlRenderer)
