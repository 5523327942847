import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getBidPackages, getBidPackageListMetadata } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { gridConfig as testConfig } from '../../../utils/testing/config' // TODO: replace with api requested data
import urls, { getCreateLink } from '../../../utils/constants/urls'
import { updateParameters } from '../../../utils/queryParameters'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [bidPackages, setBidPackages] = useState([])
    const [loading, setLoading] = useState(true)
    const [gridConfigExpanded, setGridConfigExpanded] = useState(false)
    const [gridConfig, setGridConfig] = useState(testConfig)
    const createLink = useMemo(() => getCreateLink(urls.bidPackage), [])
    const [queryParameters, setQueryParameters] = useState({
        page: 0,
        pageSize: 25,
    })
    const [listMetadata, setListMetadata] = useState({ count: 0, pages: 0 })

    const updateQueryParameters = useCallback(
        (newParameters) => {
            setQueryParameters(prevParameters => updateParameters(newParameters, prevParameters))
        }, [setQueryParameters]
    )

    const fetchBidPackageListMetadata = useCallback((queryParameters) => new Promise((resolve, reject) => {
        try {
            getBidPackageListMetadata(
                authenticatedFetch,
                API_URL,
                queryParameters,
                (res) => {
                    setListMetadata(res)
                    return resolve()
                }
            )
        } catch (e) {
            return reject()
        }
    }), [])

    const fetchBidPackages = useCallback(
        (queryParameters) => new Promise((resolve, reject) => {
            try {
                getBidPackages(
                    authenticatedFetch,
                    API_URL,
                    queryParameters,
                    (res) => {
                        const parsed = res.map((p) => parseJsonAttributes(p))
                        setBidPackages(parsed)
                        return resolve()
                    }
                )
            } catch (e) {
                return reject()
            }
        }),
        [authenticatedFetch, API_URL]
    )

    // when query parameters change, update table data
    useEffect(() => {
        const updateTableData = async () => {
            setLoading(true) 
            try {
                await fetchBidPackages(queryParameters)
                await fetchBidPackageListMetadata(queryParameters)
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }
        updateTableData()
    }, [queryParameters])

    return (
        <DataContext.Provider
            value={{
                bidPackages,
                updateQueryParameters,
                listMetadata,
                loading,
                gridConfig,
                gridConfigExpanded,
                setGridConfigExpanded,
                createLink,
                queryParameters
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
