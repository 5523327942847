import React, {
    useContext,
    useMemo,
    createRef,
    useCallback,
    useEffect,
    useState,
} from 'react'
import MaterialTable from 'material-table'
import { DataContext } from '../LinkDataContext'
import { createTheme, Hidden } from '@mui/material'
import { MuiThemeProvider } from '@material-ui/core'
import { theme, defaultOptions } from '../muiGridConfig'
import Toolbar from '../Toolbar'
import { FilterAltOff } from '@mui/icons-material'

const COLUMNS_DEFAULT_SORT_KEY = 'defaultSort'

const defaultMaterialTheme = createTheme(theme)

const AvailableGrid = ({ enabled }) => {
    const {
        availableLoading,
        availableTableData,
        gridColumns,
        addItem,
        setAvailableQuery,
        availableQuery,
        availableCount
    } = useContext(DataContext)
    const tableRef = createRef(null)
    const [columns, setColumns] = useState(gridColumns)

    const addRow = useCallback(
        (data) => {
            if (tableRef && tableRef.current) {
                tableRef.current.onAllSelected(false)
                addItem(data)
            }
        },
        [addItem, tableRef]
    )

    const onChangePage = (page) =>
        setAvailableQuery((prevQuery) => ({ ...prevQuery, page }))
    const onChangePageSize = (pageSize) =>
        setAvailableQuery((prevQuery) => ({ ...prevQuery, pageSize, page: 0 }))
    const onChangeFilter = (filters) =>
        setAvailableQuery((prevQuery) => ({ ...prevQuery, filters, page: 0 }))
    
    const onClearFilter = useCallback((columns) => {
        columns.forEach(e => {
            e.tableData.filterValue = undefined
        })
        setAvailableQuery({
            filters: [],
            page: 0,
            pageSize: 25,
        })
    }, [setAvailableQuery, columns])
       
    const onChangeOrder = useCallback(
        (orderBy, orderDirection) => {
            // HACK: https://github.com/mbrn/material-table/issues/2322#issuecomment-699708067
            const key = orderBy < 1 ? 0 : orderBy
            setColumns(
                columns.map((c, i) => {
                    if (i !== key) {
                        delete c[COLUMNS_DEFAULT_SORT_KEY]
                    } else {
                        c[COLUMNS_DEFAULT_SORT_KEY] = orderDirection
                    }
                    return c
                })
            )
            const newOrderParams = {
                orderBy: columns[orderBy] ? columns[orderBy] : null,
                orderDirection,
            }
            setAvailableQuery((prevQuery) => ({
                ...prevQuery,
                ...newOrderParams,
            }))
        },
        [columns]
    )

    return (
        <Hidden xsUp={!enabled}>
            <div className="" style={{ maxWidth: '100%' }}>
                <MuiThemeProvider theme={defaultMaterialTheme}>
                    <MaterialTable
                        tableRef={tableRef}
                        columns={columns}
                        isLoading={availableLoading}
                        onChangePage={onChangePage}
                        onFilterChange={onChangeFilter}
                        onOrderChange={onChangeOrder}
                        onChangeRowsPerPage={onChangePageSize}
                        data={availableTableData}
                        totalCount={availableCount}
                        page={availableQuery.page}
                        padding="dense"
                        options={{
                            ...defaultOptions,
                            search: false
                        }}
                        actions={[
                            {
                                tooltip: 'Add Selected',
                                icon: 'add',
                                onClick: (evt, data) => addRow(data),
                            },
                            {
                                icon: () => <FilterAltOff />,
                                tooltip: "Clear all filters",
                                isFreeAction: true,
                                onClick: (e) => {
                                    onClearFilter(columns)
                                }
                            },
                        ]}
                        title={'Available'}
                        components={{
                            Toolbar: props => <Toolbar {...props} />
                        }}
                    />
                </MuiThemeProvider>
            </div>
        </Hidden>
    )
}

export default AvailableGrid
