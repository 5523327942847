import React, { useCallback } from 'react'
import { Hidden } from '@mui/material'

import DisplayLinkDataContextProvider from './DisplayLinkDataContext.js'
import ExistingGrid from './Grid.js'
import { useJsonForms } from '@jsonforms/react'

const DisplayLinkControl = (props) => {
    const { data, path, uischema, visible } = props
    const {
        core,
    } = useJsonForms()
    
    const { data: parentData } = core
    return (
        <Hidden xsUp={!visible}>
            <DisplayLinkDataContextProvider
                existingLinkData={data}
                parentData={parentData}
                path={path}
                {...uischema}
            >
                <ExistingGrid {...props} />
            </DisplayLinkDataContextProvider>
        </Hidden>
    )
}

export default DisplayLinkControl
