import styled from 'styled-components';
import { fonts, colors } from '../../../theme/base.js'

export const Title = styled.h2`
    font-family: ${fonts.normal};
    color: ${colors.white};
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ContainerLogin = styled.div`
    background-color: ${colors.loginBody};
    height: 100vh;
`;
export const Container = styled.div`
    background-color: transparent;
    height: 100vh;
`;

export const Footer = styled.footer`
    font-family: ${fonts.normal};
    color: ${colors.white};
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100vw;
    padding: 20px;
`;
