import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Hidden } from '@mui/material'
import SelectOrCreate from '../../selectOrCreate/SelectOrCreate'

export const SelectOrCreateControlRenderer = (props) => {

    const { visible } = props
    return (
        <Hidden xsUp={!visible}>
            <SelectOrCreate {...props} />
        </Hidden>
    )
}

export default withJsonFormsControlProps(SelectOrCreateControlRenderer)
