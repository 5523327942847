import dayjs from "dayjs"
import { _ } from "ajv"

const disablePast = {
  keyword: "disablePast",
  validate: (schema, data) => {
      const isPast = dayjs().isAfter(dayjs(data))
      return !isPast
  },
  error: {
      message: "must not be in the past"
  }
}

const disableFuture = {
    keyword: "disableFuture",
    validate: (schema, data) => {
        const isPast = dayjs().isAfter(dayjs(data))
        return isPast
    },
    error: {
        message: "must not be in the future"
    }
  }

export default [disablePast, disableFuture]