import React, { useEffect, useMemo, useState } from 'react'
import merge from 'lodash/merge'
import { isDescriptionHidden } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FormControl, FormHelperText, Hidden, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import {
    createOnChangeHandler,
    getData,
    useFocus,
} from '@jsonforms/material-renderers'
import dayjs from 'dayjs'

import { isDateTimeControl, rankWith } from "@jsonforms/core"

export const dateTimeControlTester = rankWith(
    6,
    isDateTimeControl
  )
  

export const MaterialDateTimeControl = (props) => {
    const [focused ] = useFocus()
    const {
        description,
        errors,
        label,
        uischema,
        visible,
        enabled,
        required,
        path,
        handleChange,
        data,
        config,
    } = props
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const [value, setValue] = useState(null)
    const isValid = errors.length === 0

    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    )

    const format = appliedUiSchemaOptions.dateTimeFormat ?? 'MM/DD/YYYY hh:mm A'
    const saveFormat = appliedUiSchemaOptions.dateTimeSaveFormat ?? 'YYYY-MM-DDTHH:mm:ss'

    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
    const secondFormHelperText = showDescription && !isValid ? errors : null

    const onChange = useMemo(
        () => createOnChangeHandler(path, handleChange, saveFormat),
        [path, handleChange, saveFormat]
    )

    useEffect(() => {
        if (data) {
            const dataVal = getData(data, saveFormat)
            const displayVal = dataVal ? dataVal.format(saveFormat).toString() : null
            setValue(displayVal)
        }
    }, [data, saveFormat])
    return (
        <Hidden xsUp={!visible}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                    {enabled ? 
                        <TextField
                            label={label}
                            type="datetime-local"
                            value={value}
                            required={required}
                            disabled={!enabled}
                            onChange={(evt) => setValue(evt.target.value)}
                            onFocus={(evt) => onChange(evt.target.value)}
                            onBlur={(evt) => onChange(evt.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="standard"
                            fillWidth={true}
                            error={!isValid}
                        /> : 
                        <TextField
                            label={label}
                            type="text"
                            value={value ? dayjs(value).format(format) : ''}
                            disabled={!enabled}
                            InputLabelProps={data ? { shrink: true } : null}                            
                            variant="standard"
                            fillWidth={true}
                        />
                    }
                </FormControl>
                <FormHelperText error={!isValid && !showDescription}>
                    {firstFormHelperText}
                </FormHelperText>
                <FormHelperText error={!isValid}>
                    {secondFormHelperText}
                </FormHelperText>
            </LocalizationProvider>
        </Hidden>
    )
}

export default withJsonFormsControlProps(MaterialDateTimeControl)
