import { apiUrls } from '../../../utils/constants/urls'
import toast from '../../elem/Toast'

const getFacilityGroup = (authFetch, API_URL, facilityGroupId, after) => {
    authFetch(
        `${API_URL}/${apiUrls.facilityGroup}/${facilityGroupId}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Facility Group: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getFacilityGroupSchema = (authFetch, API_URL, after) => {
    authFetch(
        `${API_URL}/${apiUrls.schema}/facilityGroup`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Schema: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const createFacilityGroup = (authFetch, API_URL, facilityGroup, after) => {
    const url = `${API_URL}/${apiUrls.facilityGroup}/create`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(facilityGroup)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response.createdId)
            
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Create Project: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const updateFacilityGroup = (authFetch, API_URL, facilityGroup, after) => {
    const url = `${API_URL}/${apiUrls.facilityGroup}` 
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(facilityGroup)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response.data)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Create Project: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export { getFacilityGroup, getFacilityGroupSchema, createFacilityGroup, updateFacilityGroup }
