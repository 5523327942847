import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef
} from 'react'
import isEqual from 'lodash/isEqual'

import { useParams, useNavigate } from 'react-router'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import {
    getBidResponse,
    getBidResponseSchema,
    createBidResponse,
    updateBidResponse,
    getBidResponseAutoCalc,
} from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { getPropertiesFromSchema, parseSchemaObjects, separatePropertiesBasedOnSchemaType } from '../../../utils/json/schemas'

// TODO: Testing, replace with api call later
import { bidResponseSchema } from '../../../utils/testing/schemas'
import urls from '../../../utils/constants/urls'
import { debounce } from '@material-ui/core'
import { checkEditingChild, checkFormErrors } from '../../../utils/form/errors'
import titles, { constructFormTitle } from '../../../utils/constants/titles'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [bidResponse, setBidResponse] = useState(null)
    const [initialState, setInitialState] = useState(null)
    const [schemas, setSchemas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [editing, setEditing] = useState(false)
    const [errors, setErrors] = useState([])
    const [additionalErrors, setAdditionalErrors] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const bidResponseId = useMemo(() => params.bidResponseId, [params])
    const [editingChild, setEditingChild] = useState(false)
    const beforeAutoCalc = useRef({})
    const [bidResponseTitle, setBidResponseTitle] = useState(null)
    const [autoCalcAbortController, setAutoCalcAbortController] = useState(new AbortController)

    // when the bid package changes, submit the data to the auto calc endpoint
    // and set the response if we're editing
    const autoCalculateInputs = useCallback(() => {
        autoCalcAbortController.abort()
        if (!isEqual(beforeAutoCalc.current, bidResponse) && editing) {
            if (bidResponse && bidResponse.bidResponse) {
                const abortController = new AbortController()
                setAutoCalcAbortController(abortController)
                getBidResponseAutoCalc(
                    authenticatedFetch,
                    API_URL,
                    bidResponse.bidResponse,
                    abortController,
                    (d) => {
                        if (d && Object.keys(d).length > 0) {
                            const newBidResponse = {
                                ...bidResponse.bidResponse,
                                ...d,
                            }
                            beforeAutoCalc.current = {
                                bidResponse: newBidResponse,
                            }
                            setBidResponse({ bidResponse: newBidResponse })
                        }
                    }
                )
            }
        }
    }, [bidResponse, setBidResponse, beforeAutoCalc, editing, autoCalcAbortController])

    useEffect(() => {
        debounce(autoCalculateInputs(), 1500)
    }, [bidResponse, editing])

    const setBidResponseData = useCallback((d) => {
        const data = parseJsonAttributes(d)
        setBidResponse({ bidResponse: data })
        setBidResponseTitle(constructFormTitle(data, titles.bidResponse))
        setInitialState(data)
    }, [setInitialState, setBidResponse])

    useEffect(() => {
        if (bidResponseId) {
            getBidResponse(authenticatedFetch, API_URL, bidResponseId, (d) => {
                setBidResponseData(d)
                setEditing(false)
            })
        } else {
            setBidResponseData({})
            setEditing(true)
        }
    }, [bidResponseId, setBidResponseData])

    useEffect(() => {
        // getBidResponseSchema(authenticatedFetch, API_URL, (s) => {
        //     const parsedSchemas = parseSchemaObjects(s)
        //     setSchemas(parsedSchemas)
        // })

        // TODO: Update after testing
        setSchemas(bidResponseSchema)
    }, [])

    useEffect(() => {
        if (schemas && bidResponse) {
            setLoading(false)
        }
    }, [schemas, bidResponse])

    const saveForm = useCallback(() => {
        setSubmitting(true)
        checkEditingChild(editingChild)
            .then(() => checkFormErrors(errors, additionalErrors))
            .then(() => {
                if (bidResponse.bidResponse.bidResponseFacilityWork) {
                    const customProperties = getPropertiesFromSchema(
                        schemas.baseSchema.$defs.bidResponseFacilityWork
                            .additionalProperties.properties.bidResponseFacilityWorkAttributesCustom
                    )
                    bidResponse.bidResponse.bidResponseFacilityWork.forEach((fw) => {
                        const dataKeys = Object.keys(fw)
                        const attributes = dataKeys
                            .filter((x) => customProperties.includes(x))
                            .reduce(
                                (acc, curr) => ({ ...acc, [curr]: fw[curr] }),
                                {}
                            )
                        fw.attributes = JSON.stringify(attributes)
                    })
                }

                const data = separatePropertiesBasedOnSchemaType(
                    bidResponse.bidResponse,
                    schemas
                )
                // if id already exists, it is an update
                if (bidResponse.bidResponse.bidResponseId) {
                    updateBidResponse(authenticatedFetch, API_URL, data, (res) => {
                        setBidResponseData(res.data)
                        setEditing(false)
                        setSubmitting(false)
                    })
                } else {
                    createBidResponse(authenticatedFetch, API_URL, data, (res) => {
                        navigate(`${urls.bidResponse}/${res}`)
                        setEditing(false)
                        setSubmitting(false)
                    })
                }
        }).catch(() => setSubmitting(false))
    }, [bidResponse, schemas, errors, additionalErrors, editingChild, setBidResponseData])

    const resetForm = useCallback((cancel) => {
        if (cancel && !(initialState && initialState.bidResponseId)) {
            navigate(urls.bidResponses)
        } else {
            autoCalcAbortController.abort()
            setBidResponse({ bidResponse: initialState })
        }
    }, [initialState, autoCalcAbortController])

    return (
        <DataContext.Provider
            value={{
                bidResponse,
                setBidResponse,
                schemas,
                loading,
                errors,
                setErrors,
                additionalErrors, 
                setAdditionalErrors,
                setEditingChild,
                saveForm,
                resetForm,
                editing,
                setEditing,
                submitting,
                bidResponseTitle
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
