export const colors = {
  loginBody: '#3b4465',
  primary: '#1976d2',
  secondary: 'white',
  white:'rgba(255, 255, 255, 0.707)',
};

export const fonts = {
  normal: '"Roboto","Helvetica","Arial",sans-serif',
};
