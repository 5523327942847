import React, { useCallback, useContext, useState, useMemo, useRef } from 'react'

import MaterialTable from 'material-table'
import { DataContext } from './DisplayLinkDataContext'
import { MuiThemeProvider } from '@material-ui/core'
import { createTheme, Grid } from '@mui/material'
import { theme, defaultOptions } from './muiGridConfig'
import { FilterAltOff } from '@mui/icons-material'
import { getExportFileName } from '../../../../utils/grids/exports'

const defaultMaterialTheme = createTheme(theme)

const DisplayLinkGrid = (props) => {
    const { enabled, uischema } = props

    const { gridColumns, linkData, exportId } = useContext(DataContext)
    const [resetKey, setResetKey] = useState(1)
    const columns = useMemo(() => gridColumns, [gridColumns])

    const tableRef = useRef(null)

    const onClearFilter = useCallback((col) => {
        col.forEach((c, idx) => {
            tableRef.current && tableRef.current.dataManager.changeFilterValue(idx, undefined)
        })
        setResetKey((prevKey) => prevKey + 1)
    }, [columns, tableRef])

    const options = {
        ...defaultOptions,
        exportButton: true,
        exportAllData: true,
        exportFileName: getExportFileName(exportId, uischema.exportFileName)
    }

    let actions = [
        {
            icon: () => <FilterAltOff />,
            tooltip: "Clear all filters",
            isFreeAction: true,
            onClick: (event) => {
                onClearFilter(columns)
            }
        }
    ]

    return (
        <div
            className="materialTableWrapper"
            style={{ maxWidth: '100%', width: '100%' }}
        >
            <MuiThemeProvider theme={defaultMaterialTheme}>
                <MaterialTable
                    key={resetKey}
                    style={{ maxWidth: 'inherit' }}
                    tableRef={tableRef}
                    columns={columns}
                    data={linkData}
                    options={options}
                    padding="dense"
                    title={'Assigned'}
                    actions={actions}
                />
            </MuiThemeProvider>
        </div>
    )
}

export default DisplayLinkGrid
