import React, { useMemo, useEffect, useCallback, useState } from 'react'
import {
    useTable,
    usePagination,
    useSortBy,
    useFilters,
    useExpanded,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder,
} from 'react-table'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    TableContainer,
    TableSortLabel,
} from '@mui/material'
import {
    getAttributeColumnsFromGridConfig,
    getBaseColumnsFromGridConfig,
} from '../../../../utils/grids/display/config'
import {
    getColumns,
    defaultColumn,
} from '../../../../utils/grids/display/columns'
// import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions'
import { filterTypes } from '../../../../utils/grids/display/search'
import ColumnSelectionModal from './ColumnSelectionModal'
import Header from '../Header'
import SearchField from './SearchField'
import styled from 'styled-components'

const StyledTHeader = styled(TableHead)`
    position: sticky;
    top: 0;
`;

const StyledTableContainer = styled(TableContainer)`
    background-color: transparent;
    height: 80vh;
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    overflow-x: auto;
`;


const Grid = ({
    name,
    data,
    updateQueryParameters,
    queryParameters,
    metaData,
    gridConfig,
    loading,
    createLink,
}) => {
    const baseColumns = getBaseColumnsFromGridConfig(name, gridConfig)
    const attributeColumns = getAttributeColumnsFromGridConfig(name, gridConfig)
    const columns = useMemo(
        () => getColumns(baseColumns, attributeColumns),
        [baseColumns, attributeColumns]
    )
    const width = useMemo(
        () => ({
            columnWidths: columns.map((x) =>
                x.width ? `${x.width}px` : '60px'
            ),
        }),
        []
    )

    const tableData = useMemo(() => data, [data])

    const {
        getTableProps,
        // toggleAllRowsSelected,
        headerGroups,
        prepareRow,
        visibleColumns,
        page,
        // rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        allColumns,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        // selectedFlatRows,
        setColumnOrder,
        setPageSize,
        state: { pageIndex, pageSize, sortBy },
    } = useTable(
        {
            columns,
            autoResetPage: false,
            data: tableData,
            defaultColumn,
            filterTypes,
            autoResetSelectedRows: false,
            manualPagination: true,
            pageCount: metaData.pages,
            manualSortBy: true,
            autoResetSortBy: false,
            disableSortRemove: true,
            initialState: {
                pageIndex: 0,
                pageSize: 25,
                // filters: gridQuery,
                gridLayout: width,
            },
            disableMultiSort: true,
            autoResetFilters: false,
            // updateRow,
            //20220616 - LR - react-table will sort after server-side sorting, base on sortType property. This is an issue for case-insensitive sorting.
            //react-table DefaultSortTypes are 'alphanumeric' | 'datetime' | 'basic'. It seems we only need to handle for case-insensitive strings
            sortTypes: React.useMemo(
                () => ({
                    caseInsensitive: (a, b, id) => {
                        const valueA = a.values[id] !== null ? a.values[id].toLowerCase() : ''
                        const valueB = b.values[id] !== null ? b.values[id].toLowerCase() : ''
                        return valueB > valueA ? -1 : valueB < valueA ? 1 : 0
                    },
                }),
                []
            ),
        },
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useResizeColumns,
        useColumnOrder,
        useBlockLayout
        // useRowSelect,
        // (hooks) => {
        //     hooks.visibleColumns.push((columns) => {
        //         return [
        //             {
        //                 id: 'selection',
        //                 groupByBoundary: true,
        //                 width: 35,
        //                 disableResizing: true,
        //                 Header: ({ getToggleAllPageRowsSelectedProps }) => (
        //                     <div style={{ paddingLeft: 0 }}>
        //                         <RowCheckbox
        //                             {...getToggleAllPageRowsSelectedProps()}
        //                         />
        //                     </div>
        //                 ),
        //                 Cell: ({ row }) => (
        //                     <div>
        //                         <RowCheckbox
        //                             {...row.getToggleRowSelectedProps()}
        //                         />
        //                     </div>
        //                 ),
        //             },
        //             ...columns,
        //         ]
        //     })
        // }
    )

    const [orderParams, setOrderParams] = useState({})
    const [filteredColumns, setFilteredColumns] = useState([])

    useEffect(() => {
        const newOrderParams =  sortBy && sortBy.length
        ? {
            orderBy: sortBy[0].id,
            orderDirection: sortBy[0].desc ? 'DESC' : 'ASC',
        }
        : {}
        setOrderParams(newOrderParams)
        updateQueryParameters({ pageSize, page: pageIndex, ...newOrderParams })
    }, [pageSize, pageIndex, sortBy, updateQueryParameters])

    const clearFilters = useCallback(() => {
        filteredColumns.forEach(x => {
            updateQueryParameters({
                [x]: null,
                page: 0
            })
        });
    }, [filteredColumns, updateQueryParameters])

    const onSearch = useCallback(
        (accessor, value) => {
            gotoPage(0)
            if (value != null && !filteredColumns.includes(accessor)) {
                setFilteredColumns([...filteredColumns, accessor]) //stores a list of unique accessors that currently have filters applied
            }

            updateQueryParameters({
                [accessor]: value,
                page: 0
            })
        },
        [updateQueryParameters, filteredColumns]
    )

    // when the page size changes, go to the 0th page
    useEffect(() => {
        gotoPage(0)
    }, [pageSize])

    // const handleChangePage = (event, newPage) => {
    //     gotoPage(newPage)
    // }

    // const handleChangeRowsPerPage = (event) => {
    //     setPageSize(Number(event.target.value))
    // }
    const paginationProps = useMemo(
        () => ({
            pageSize,
            pageIndex,
            page,
            nextPage,
            gotoPage,
            previousPage,
            setPageSize,
            ...metaData,
        }),
        [
            pageSize,
            page,
            pageIndex,
            setPageSize,
            gotoPage,
            nextPage,
            previousPage,
            metaData,
        ]
    )

    const [gridConfigExpanded, setGridConfigExpanded] = useState(false)

    return (
        <>        
            <Header
                {...paginationProps}
                loading={loading}
                createLink={createLink}
                clearFunction={clearFilters}
                setGridConfigExpanded={setGridConfigExpanded}
                gridConfigExpanded={gridConfigExpanded}
            />
                <StyledTableContainer>
                    {/* <GridControls
                    refreshData={refreshData}
                    filtRows={filtRows}
                    filteredData={filteredData}
                    lastUpdate={lastUpdate}
                /> */}
                    {/* </div> */}
                    {/* <div className="showing">
                    <div style={{ marginLeft: '1%' }}>
                        {!filteredData.length
                            ? `Loading Grid Data`
                            : `Showing ${page.length} of ${
                                filtRows ? filtRows.length : filteredData.length
                            } results`}
                    </div>
                </div> */}
                    <ColumnSelectionModal
                        columns={allColumns}
                        setColumnOrder={setColumnOrder}
                        gridConfigExpanded={gridConfigExpanded}
                    />
                    <Table {...getTableProps()} size={'small'} stickyHeader>
                        <StyledTHeader>
                            {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps({})}>
                                    {headerGroup.headers.map((column) => (
                                        <TableCell {...column.getHeaderProps()}>
                                            <div className="header">
                                                <span
                                                    {...column.getSortByToggleProps()}
                                                >
                                                    {column.render('Header')}
                                                    <TableSortLabel
                                                        active={column.isSorted}
                                                        // react-table has a unsorted state which is not treated here
                                                        direction={
                                                            column.isSortedDesc
                                                                ? 'desc'
                                                                : 'asc'
                                                        }
                                                    />
                                                </span>
                                                {column.canResize && (
                                                <div
                                                    {...column.getResizerProps()}
                                                    className={`resizer`}
                                                />
                                                )}
                                            </div>
                                            {/* <div
                                            style={
                                                visibleSearchBox.length &&
                                                visibleSearchBox.indexOf(
                                                    column.id
                                                ) > -1
                                                    ? null
                                                    : { display: 'none' }
                                            }
                                        >
                                            {column.canFilter
                                                ? column.render('Filter')
                                                : null}
                                        </div> */}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()} sx={{ display: 'flex' }}>
                                    {headerGroup.headers.map((column, idx) => {
                                        return column.canFilter ? (
                                            <SearchField
                                                key={idx}
                                                accessor={column.id}
                                                onSearch={onSearch}
                                                column={column}
                                                queryParameters={queryParameters}
                                            />
                                        ) : null
                                    }
                                    )}
                                </TableRow>
                            ))}
                        </StyledTHeader>
                            <TableBody>
                                {page.map((row) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={row.id}>
                                            <TableRow {...row.getRowProps()}>
                                                {row.cells.map((cell, idx) => {
                                                    const td = cell.getCellProps()
                                                    return (
                                                        <TableCell
                                                            key={idx}
                                                            {...td}
                                                        // className="td"
                                                        >
                                                            {cell.render('Cell')}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </React.Fragment>
                                    )
                                })}
                            </TableBody>
                    </Table> 
                </StyledTableContainer>
        </>
    )
}

export default Grid
