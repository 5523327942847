const gridConfig = {
    base: {
        facilities: {
            facilityId: {
                Label: 'Detail',
                DataType: 'Link',
                BaseUrl: '/facility',
                Seq: 0,
            },
            // "facilityType": {Label: 'Facility Type', DataType: 'String', Seq: 0},
            // "createdBy": {Label: 'Created By', DataType: 'String', Seq: 0},
            createdDate: { Label: 'Created Date', DataType: 'Date', Seq: 15 },
            updatedBy: { Label: 'Updated By', DataType: 'String', Seq: 16 },
            updatedDate: { Label: 'Updated Date', DataType: 'Date', Seq: 17 },
        },
        facilityGroups: {
            facilityGroupId: {
                Label: 'Detail',
                DataType: 'Link',
                BaseUrl: '/project',
            },
            createdBy: { Label: 'Created By', DataType: 'String' },
            createdDate: { Label: 'Created Date', DataType: 'Date' },
            updatedBy: { Label: 'Updated By', DataType: 'String' },
            updatedDate: { Label: 'Updated Date', DataType: 'Date' },
        },
        bidPackages: {
            bidPackageId: {
                Label: 'Detail',
                DataType: 'Link',
                BaseUrl: '/bidPackage',
                Seq: 0,
            },
            bidPackageName: {
                Label: 'Package Name',
                DataType: 'String',
                Seq: 1,
            },
            bidPackageStatus: {
                Label: 'Package Status',
                DataType: 'String',
                Seq: 3,
            },
            createdDate: { Label: 'Created Date', DataType: 'Date', Seq: 11 },
            updatedBy: { Label: 'Updated By', DataType: 'String', Seq: 12 },
            updatedDate: { Label: 'Updated Date', DataType: 'Date', Seq: 13 },
        },
        bidResponses: {
            bidResponseId: {
                Label: 'Bid Response Detail',
                DataType: 'Link',
                BaseUrl: '/bidResponse',
                Seq: 0,
            },
            bidPackageName: {
                Label: 'Bid Package Name',
                DataType: 'String',
                Seq: 1,
            },
            bidPackageId: {
                Label: 'Bid Package Detail',
                DataType: 'Link',
                BaseUrl: '/bidPackage',
                Seq: 2,
            },
            contractId: {
                Label: 'Contract ID',
                DataType: 'String',
                Seq: 3,
            },
            bidResponseAmount: {
                Label: 'Awarded Total',
                DataType: 'Currency',
                Seq: 5,
            },
            bidResponseStatus: {
                Label: 'Bid Status',
                DataType: 'String',
                Seq: 7,
            },
            bidResponseDate: {
                Label: 'Bid Status Date',
                DataType: 'Date',
                Seq: 8,
            },
            numInvoices: {
                Label: 'No. of Rcvd Invoices',
                DataType: 'Number',
                Seq: 10,
            },
            createdDate: { Label: 'Created Date', DataType: 'Date', Seq: 11 },
            updatedBy: { Label: 'Updated By', DataType: 'String', Seq: 12 },
            updatedDate: { Label: 'Updated Date', DataType: 'Date', Seq: 13 },
        },
        facilityWork: {
            facilityId: {
                Label: 'Facility Detail',
                DataType: 'Link',
                BaseUrl: '/facility',
            },
            facilityType: {
                Label: 'Facility Type',
                DataType: 'String',
            },
        },
        bidPackageFacilityWork: {
            facilityId: {
                Label: 'Facility Detail',
                DataType: 'Link',
                BaseUrl: '/facility',
            }
        },
        bidResponseFacilityWork: {
            bidPackageId: {
                Label: 'Bid Package Detail',
                DataType: 'Link',
                BaseUrl: '/bidPackage',
                Seq: 0,
            },
            facilityId: {
                Label: 'Facility Detail',
                DataType: 'Link',
                BaseUrl: '/facility',
                Seq: 1,
            }
        },
        invoices: {
            invoiceId: {
                Label: 'Invoice Detail',
                DataType: 'Link',
                BaseUrl: '/invoice',
                Seq: 0,
            },
            contractId: {
                Label: 'Contract ID',
                DataType: 'String',
                Seq: 1,
            },
            bidResponseId: {
                Label: 'Bid Response Detail',
                DataType: 'Link',
                BaseUrl: '/bidResponse',
                Seq: 2,
            },
            vendor: {
                Label: 'Vendor',
                DataType: 'String',
                Seq: 3,
            },
            bidPackageName: {
                Label: 'Bid Package Name',
                DataType: 'String',
                Seq: 4
            },
            // invoiceType: {
            //     Label: 'Invoice Type',
            //     DataType: 'String',
            //     Seq: 5,
            // },
            invoiceIdAt: {
                Label: 'Invoice ID',
                DataType: 'String',
                Seq: 6,
            },
            invoiceTotal: {
                Label: 'Invoice Total',
                DataType: 'Currency',
                Seq: 7,
            },
            invoiceStatus: {
                Label: 'Invoice Status',
                DataType: 'String',
                Seq: 8,
            },
            datePaid: {
                Label: 'Date Paid',
                DataType: 'Date',
                Seq: 9,
            },
            paymentCheck: {
                Label: 'Payment Check / EFT Number',
                DataType: 'string',
                Seq: 10,
            },
            createdDate: { Label: 'Created Date', DataType: 'Date', Seq: 11 },
            updatedBy: { Label: 'Updated By', DataType: 'String', Seq: 12 },
            updatedDate: { Label: 'Updated Date', DataType: 'Date', Seq: 13 },
        },
        invoiceFacilityWork: {
            bidResponseId: {
                Label: 'Bid Response Detail',
                DataType: 'Link',
                BaseUrl: '/bidResponse',
            },
            unInvoicedWorkTotal: {
                Label: 'Un-Invoiced Work Total',
                DataType: 'Currency',
            },
            bidPackageId: {
                Label: 'Bid Package Detail',
                DataType: 'Link',
                BaseUrl: '/bidPackage',
            },
            facilityId: {
                Label: 'Facility Detail',
                DataType: 'Link',
                BaseUrl: '/facility',
            }
        },
    },
    attribute: {
        facilities: {
            permitNumber: { Label: 'Permit No.', DataType: 'String', Seq: 1 },
            apiNumber: { Label: 'API No.', DataType: 'String', Seq: 2 },
            wellDisplay: {
                Label: 'Lease/Well Name and No.',
                DataType: 'String',
                Seq: 3,
            },
            county: { Label: 'County', DataType: 'String', Seq: 4 },
            latitude: {
                Label: 'Latitude',
                DataType: 'Number',
                SortType: 'basic',
                Seq: 5,
            },
            longitude: {
                Label: 'Longitude',
                DataType: 'Number',
                SortType: 'basic',
                Seq: 6,
            },
            operatorDisplay: { Label: 'Operator', DataType: 'String', Seq: 7 },
            lastInspectionDate: {
                Label: 'Last Inspection Date',
                DataType: 'Date',
                Seq: 8,
            },
            hasSurfOwner: {
                Label: 'Has Surf Owner?',
                DataType: 'String',
                Seq: 9,
            },
            remediationRequired: {
                Label: 'Environmental Impact?',
                DataType: 'String',
                Seq: 10,
            },
            carterCoordinates: {
                Label: 'Carter Coordinates',
                DataType: 'String',
                Seq: 11,
            },
            wellType: {
                Label: 'Well Type',
                DataType: 'String',
                Seq: 12,
            },
            wellStatus: { Label: 'Well Status', DataType: 'String', Seq: 13 },
            inBidPackage: {
                Label: 'In Bid Package?',
                DataType: 'String',
                Seq: 14,
            },
        },
        facilityGroups: {
            packageType: { Label: 'Package Type', DataType: 'String' },
            packageStatus: { Label: 'Package Status', DataType: 'String' },
            numberOfReceivedBids: {
                Label: 'No. of Rcvd. Bids',
                DataType: 'Number',
            },
            awardedBidStatus: {
                Label: 'Awarded Bid Status',
                DataType: 'String',
            },
            dateBidAwarded: { Label: 'Date Bid Awarded', DataType: 'Date' },
            awardedBidAmount: {
                Label: 'Awarded Total',
                DataType: 'Number',
            },
            numberOfWells: { Label: 'No. of Wells', DataType: 'Number' },
            numberOfRemediations: {
                Label: 'No. of Env Impacts',
                DataType: 'Number',
            },
        },
        bidPackages: {
            bidPackageType: {
                Label: 'Package Type',
                DataType: 'String',
                Seq: 2,
            },
            packageStartDate: {
                Label: 'Package Start Date',
                DataType: 'Date',
                Seq: 4,
            },
            packageEndDate: {
                Label: 'Package End Date',
                DataType: 'Date',
                Seq: 5,
            },
            bidDueDate: {
                Label: 'Bid Due Date',
                DataType: 'Date',
                Seq: 6,
            },
            numberOfReceivedBids: {
                Label: 'No. of Rcvd Bids',
                DataType: 'Number',
                Seq: 7,
            },
            awardedBidAmount: {
                Label: 'Awarded Total',
                DataType: 'Currency',
                Seq: 8,
            },
            numberOfFacilities: {
                Label: 'No. of Facilities',
                DataType: 'Number',
                Seq: 9,
            },
            numberOfRemediations: {
                Label: 'No. of Env Impacts',
                DataType: 'Number',
                Seq: 10,
            },
        },
        bidResponses: {
            bidReceivedDate: {
                Label: 'Bid Rcvd Date',
                DataType: 'Date',
                Seq: 4,
            },
            contractor: {
                Label: 'Vendor',
                DataType: 'String',
                Seq: 6,
            },
            unInvoicedWorkTotal: {
                Label: 'Un-Invoiced Work Total',
                DataType: 'Currency',
                Seq: 9,
            },
        },
        facilityWork: {
            permitNumber: {
                Label: 'Permit No.',
                DataType: 'String',
            },
            apiNumber: { Label: 'API No.', DataType: 'String' },
            wellDisplay: {
                Label: 'Lease/Well Name and No.',
                DataType: 'String',
            },
            county: { Label: 'County', DataType: 'String' },
            hasSurfOwner: {
                Label: 'Has Surf Owner?',
                DataType: 'String',
            },
            remediationRequired: {
                Label: 'Environmental Impact?',
                DataType: 'String',
            },
            facilityWorkType: { Label: 'Work Type', DataType: 'String' },
            modifiedPluggingWorkInstructions: {
                Label: 'Work Summary',
                DataType: 'String',
            },
        },
        bidPackageFacilityWork: {
            facilityWorkType: { Label: 'Work Type', DataType: 'String' },
            permitNumber: {
                Label: 'Permit No.',
                DataType: 'String',
            },
            apiNumber: { Label: 'API No.', DataType: 'String' },
            wellDisplay: {
                Label: 'Lease/Well Name and No.',
                DataType: 'String',
            },
            county: { Label: 'County', DataType: 'String' },
            operatorDisplay: { Label: 'Operator', DataType: 'String' },
            hasSurfOwner: {
                Label: 'Has Surf Owner?',
                DataType: 'String',
            },
            carterCoordinates: { Label: 'Carter Coordinates', DataType: 'String', },
        },
        bidResponseFacilityWork: {
            facilityWorkType: { Label: 'Work Type', DataType: 'String' },
            inAwardedBidResponse: { Label: 'In Awarded Bid Response?', DataType: 'String' },
            permitNumber: {
                Label: 'Permit No.',
                DataType: 'String',
            },
            apiNumber: { Label: 'API No.', DataType: 'String' },
            wellDisplay: {
                Label: 'Lease/Well Name and No.',
                DataType: 'String',
            },
            county: { Label: 'County', DataType: 'String' },
            operatorDisplay: { Label: 'Operator', DataType: 'String' },
            hasSurfOwner: {
                Label: 'Has Surf Owner?',
                DataType: 'String',
            },
            carterCoordinates: { Label: 'Carter Coordinates', DataType: 'String', },
        },
        invoiceFacilityWork: {
            facilityWorkType: { Label: 'Work Type', DataType: 'String' },
            permitNumber: {
                Label: 'Permit No.',
                DataType: 'String',
            },
            apiNumber: { Label: 'API No.', DataType: 'String' },
            wellDisplay: {
                Label: 'Lease/Well Name and No.',
                DataType: 'String',
            },
            county: { Label: 'County', DataType: 'String' },
            operatorDisplay: { Label: 'Operator', DataType: 'String' },
            hasSurfOwner: {
                Label: 'Has Surf Owner?',
                DataType: 'String',
            },
            carterCoordinates: { Label: 'Carter Coordinates', DataType: 'String', },
        },
        invoices: {},
    },
}

const exampleData = {
    facilities: {
        'API Number': '16053077200000',
        'Permit Number': ' N23071',
        'Well Name': 'BOLES, ROBERT UNK 1A',
        'Well Number': 'UNK 1A',
        'Operator Number': 4777,
        'Operator': 'UNKNOWN',
    }
}

export { gridConfig }