import range from 'lodash/range'
import React, { useState, useCallback, useMemo } from 'react'
import { composePaths, computeLabel, createDefaultValue } from '@jsonforms/core'
import map from 'lodash/map'
import { ArrayLayoutToolbar } from './ArrayToolbar'
import ExpandPanelRenderer from './ExpandPanelRenderer'
import merge from 'lodash/merge'

const MaterialArrayLayoutComponent = (props) => {
    const [expanded, setExpanded] = useState(false)
    const innerCreateDefaultValue = useCallback(
        () => createDefaultValue(props.schema),
        [props.schema]
    )
    const handleChange = useCallback(
        (panel) => (_event, expandedPanel) => {
            setExpanded(expandedPanel ? panel : false)
        },
        []
    )
    const isExpanded = (index) =>
        expanded === composePaths(props.path, `${index}`)

    const {
        data,
        path,
        schema,
        uischema,
        errors,
        addItem,
        renderers,
        cells,
        label,
        required,
        rootSchema,
        config,
        uischemas,
        enabled
    } = props
    const appliedUiSchemaOptions = merge({}, config, props.uischema.options)

    const expandPanelComponents = useMemo(() => {
        return data > 0 ? (
            map(range(data), (index) => {
                return (
                    <ExpandPanelRenderer
                        index={index}
                        expanded={isExpanded(index)}
                        schema={schema}
                        path={path}
                        handleExpansion={handleChange}
                        uischema={uischema}
                        enabled={enabled}
                        renderers={renderers}
                        cells={cells}
                        key={index}
                        rootSchema={rootSchema}
                        enableMoveUp={index !== 0}
                        enableMoveDown={index < data - 1}
                        config={config}
                        childLabelProp={
                            appliedUiSchemaOptions.elementLabelProp
                        }
                        uischemas={uischemas}
                    />
                )
            })
        ) : (
            <p>No data</p>
        )
    }, [
        data,
        schema,
        path,
        handleChange,
        uischema,
        enabled,
        renderers,
        cells,
        rootSchema,
        config,
        appliedUiSchemaOptions,
        uischemas
    ])

    return (
        <div>
            <ArrayLayoutToolbar
                label={computeLabel(
                    label,
                    required,
                    appliedUiSchemaOptions.hideRequiredAsterisk
                )}
                errors={errors}
                path={path}
                addItem={addItem}
                enabled={enabled}
                createDefault={innerCreateDefaultValue}
            />
            <div>
                {expandPanelComponents}
            </div>
        </div>
    )
}

export const MaterialArrayLayout = React.memo(MaterialArrayLayoutComponent)
