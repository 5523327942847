import { rankWith, uiTypeIs } from '@jsonforms/core';

export const COLUMNS_SIZE = 3;

export const tightTester = rankWith(8000, uiTypeIs('TightLayout'));

export const sliceArray = (array, size) => {
  const res = [];
  for (let i = 0; i < array.length; i += size) {
    const arr = array.slice(i, i + size);
    res.push(arr);
  }
  return res;
}
