import { isControl, isLayout, iterateSchema } from '@jsonforms/core'

const constructErrorMessage = (controlPath, message, schemaPath = '', keyword = null, params = {}) => {
    return {
        instancePath: constructInstancePath(controlPath),
        message,
        schemaPath,
        keyword,
        params
    } 
}

const constructInstancePath = (dataPath) => {
    return `/${dataPath.split('.').join('/')}`
}

const findControlElements = (schema, elements = []) => {
    iterateSchema(schema, (element) => isControl(element) ? elements.push(element) : null)

    let arrayElements = []
    elements.forEach(controlElement => {
        if (controlElement.options?.detail) {
            iterateSchema(controlElement.options.detail, element => isControl(element) ? arrayElements.push(element) : null)
        }
    })
    const controlElements = [...elements, ...arrayElements]
    return controlElements
}

const lookForFieldsWithOptions = (elements, field) => {
    const fieldsWithOptions = elements.filter(x => x.options && Object.keys(x.options).includes(field))
    return fieldsWithOptions
}

const isListWithDetail = (uischema) => uischema?.options?.detail ? true : false

const findAllDetailControlsInSchema = (uischema, detailControlElements = []) => {
    iterateSchema(uischema, (element) => {
        if (isListWithDetail(element)) {
            detailControlElements.push(element)
        }
        if (isLayout(element)) {
            findAllDetailControlsInSchema(element, detailControlElements)
        }
    })
    return detailControlElements
}

const findAllControlsInSchema = (uischema, controlElements = []) => {
    iterateSchema(uischema, (element) => {
        if (isControl(element)) {
            controlElements.push(element)
        }
        if (isLayout(element)) {
            findAllControlsInSchema(element, controlElements)
        }
        if (isListWithDetail(element)) {
            findAllControlsInSchema(element.options.detail, controlElements)
        }
    })
    return controlElements
}

export { constructErrorMessage, constructInstancePath, findControlElements, lookForFieldsWithOptions, findAllControlsInSchema, findAllDetailControlsInSchema, isListWithDetail}