import isEmpty from 'lodash/isEmpty'

const parseSchemaObjects = (schemas) => {
    try {
        const parsedSchemas = {
            baseSchema: null,
            customSchema: null,
            uiSchema: null,
        }
        if (schemas.baseSchema && schemas.baseSchema.attributeSchema) {
            parsedSchemas.baseSchema = JSON.parse(
                schemas.baseSchema.attributeSchema
            )
        }
        if (schemas.customSchema && schemas.customSchema.attributeSchema) {
            parsedSchemas.customSchema = JSON.parse(
                schemas.customSchema.attributeSchema
            )
        }
        if (schemas.uiSchema && schemas.uiSchema.attributeSchema) {
            parsedSchemas.uiSchema = JSON.parse(
                schemas.uiSchema.attributeSchema
            )
        }
        return parsedSchemas
    } catch (e) {
        console.log('error parsing schema for data:', schemas)
    }
}

const resolveLinkData = (instance, dataPath) => {
    if (isEmpty(dataPath)) {
        return instance
    }
    const dataPathSegments = dataPath.split('.')

    if (Array.isArray(instance)) {
        return instance.map(x => resolveLinkData(x, dataPath))
    }
    const val = dataPathSegments
        .map((segment) => decodeURIComponent(segment))
        .reduce((curInstance, decodedSegment, idx) => {
            if (!curInstance || !curInstance.hasOwnProperty(decodedSegment) && !Array.isArray(curInstance)) {
                return undefined
            }
            const childDataPath = dataPathSegments.slice(idx + 1).join('.')
            if (Array.isArray(curInstance[decodedSegment])) {
                return curInstance[decodedSegment].map(x => {
                    const resolvedData = resolveLinkData(x, childDataPath)
                    if (Array.isArray(resolvedData)) {
                        return [...resolvedData]
                     } else {
                         return resolvedData
                     }
                    })
            }
            if (curInstance[decodedSegment]) {
                return curInstance[decodedSegment]
            } else {
                return curInstance
            }
        }, instance)

    return val
}

const getPropertiesFromSchema = (schema) => {
    return schema && schema.properties ? Object.keys(schema.properties) : []
}
const separatePropertiesBasedOnSchemaType = (data, schemas) => {
    const baseProperties = getPropertiesFromSchema(schemas.baseSchema)
    const customProperties = getPropertiesFromSchema(schemas.customSchema)
    const dataKeys = Object.keys(data)
    const baseData = dataKeys
        .filter((x) => baseProperties.includes(x))
        .reduce((acc, curr) => ({ ...acc, [curr]: data[curr] }), {})
    const attributes = dataKeys
        .filter((x) => customProperties.includes(x))
        .reduce((acc, curr) => ({ ...acc, [curr]: data[curr] }), {})
    const sourceAttributesJSON = data && Object.keys(data).length && data['sourceAttributes'] ? data['sourceAttributes'] : null
    const attributeJSON = JSON.stringify(attributes)
    return {
        ...baseData,
        sourceAttributes: sourceAttributesJSON, 
        attributes: attributeJSON,
    }
}

const getMergedSchema = (name, { baseSchema, customSchema }) => {
    const mergedSchema = {
        definitions: {
            base: baseSchema,
        },
        type: 'object',
        properties: {
            [name]: {
                allOf: [
                    {
                        $ref: '#/definitions/base',
                    },
                ],
            },
        },
        $defs: baseSchema.$defs
    }
    if (customSchema) {
        mergedSchema.definitions.custom = customSchema
        mergedSchema.properties[name].allOf = [
            ...mergedSchema.properties[name].allOf,
            { $ref: '#/definitions/custom' },
        ]
    }
    return mergedSchema
}

export { getMergedSchema, parseSchemaObjects, separatePropertiesBasedOnSchemaType, getPropertiesFromSchema, resolveLinkData }
