import React from 'react'
import { createDefaultValue } from '@jsonforms/core'
import { IconButton, TableRow, Tooltip } from '@mui/material'
import { Grid, Hidden, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ValidationIcon from './ValidationIcon'
import NoBorderTableCell from './NoBorderTableCell'

const fixedCellSmall = {
    paddingLeft: 0,
    paddingRight: 0,
}

const TableToolbar = React.memo(
    ({ numColumns, errors, label, path, addItem, schema, enabled }) => {
        return (
            <TableRow>
                <NoBorderTableCell colSpan={numColumns}>
                    <Grid
                        container
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant={'h6'}>{label}</Typography>
                        </Grid>
                        <Grid item>
                            <Hidden smUp={errors.length === 0 || !enabled}>
                                <Grid item>
                                    <ValidationIcon
                                        id="tooltip-validation"
                                        errorMessages={errors}
                                    />
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </NoBorderTableCell>
                {enabled ? (
                    <NoBorderTableCell align="right" style={fixedCellSmall}>
                        <Tooltip
                            id="tooltip-add"
                            title={`Add to ${label}`}
                            placement="bottom"
                        >
                            <IconButton
                                aria-label={`Add to ${label}`}
                                onClick={addItem(path, createDefaultValue(schema))}
                                size="large"
                            >
                                <AddIcon />
                            </IconButton>
                        </Tooltip>
                    </NoBorderTableCell>
                ) : null}
            </TableRow>
    )}
)

export default TableToolbar
