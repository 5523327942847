const mergeJsonAttributesIntoObject = (d, field) => {
    const attr = d[field]
    if (attr) {
        try {
            const parsed = JSON.parse(attr)
            d = {
                ...d,
                ...parsed,
            }
        } catch (e) {
            console.log(`error parsing ${field}: ${attr} on data: ${d}`)
        }
    }
    return d
}

const getAttributesForAllKeys = (d) => {
    if (d && typeof d === 'object') {
        const keys = Object.keys(d)
        const recursed = keys.reduce((acc, curr) => {
            const keyData = d[curr]
            if (Array.isArray(keyData)) {
                const parsed = keyData.map(x => getAttributesForAllKeys(x))
                return {
                    ...acc,
                    [curr]: parsed
                }
            } else {
                const parsed = getAttributesForAllKeys(keyData)
                if (parsed == 0 || parsed) {
                    return {
                        ...acc,
                        [curr]: parsed,
                    }
                } else return {
                        ...acc,
                        [curr]: undefined,
                }
            }
        }, {})
        const parsed = getAttributesIntoObject(recursed)
        return parsed
    } else {
        return d
    }
}
const getAttributesIntoObject = (d) => {
    // parse attributes
    d = mergeJsonAttributesIntoObject(d, 'attributes')
    // parse source attributes
    d = mergeJsonAttributesIntoObject(d, 'sourceAttributes')
    return d
}

// recursively parse all attributes / source attributes of
// an object and its related entities
const parseJsonAttributes = (data) => {
    if (data) {
        if (Array.isArray(data)) {
            const parsedData = data.map((d) => getAttributesForAllKeys(d))
            return parsedData
        } else {
            return getAttributesForAllKeys(data)
        }
    }
    return null
}

export { parseJsonAttributes }
