import { MaterialLayoutRenderer } from '@jsonforms/material-renderers';
import {
  Hidden,
} from '@mui/material';
import { withJsonFormsLayoutProps } from '@jsonforms/react';
import { COLUMNS_SIZE, sliceArray } from './TightUtils';

const TightRenderer = (props) => {
  const { uischema, schema, path, visible, renderers } = props;
  const elements = sliceArray(uischema.elements, COLUMNS_SIZE);

  const layoutProps = {
    schema: schema,
    path: path,
    direction: 'row',
    visible: visible,
    uischema: uischema,
    renderers: renderers,
    cells: null,
  };

  return (
    <Hidden xsUp={!visible}>
      {elements.map((elements, index) => {
        const props = { ...layoutProps, elements: elements }
        return (<MaterialLayoutRenderer key={index.toString()} {...props} />)
      })
      }
    </Hidden>
  );
};

export default withJsonFormsLayoutProps(TightRenderer);