import baseFacilitySchema from './baseschemas/facility.json'
import customFacilitySchema from './customschemas/facility.json'
import uiFacilitySchema from './uischemas/facility.json'
import baseFacilityGroupSchema from './baseschemas/facilityGroup.json'
import customFacilityGroupSchema from './customschemas/facilityGroup.json'
import uiFacilityGroupSchema from './uischemas/facilityGroup.json'
import baseLandownerSchema from './baseschemas/landowner.json'
import customLandownerSchema from './customschemas/landowner.json'
import uiLandownerSchema from './uischemas/landowner'
import baseBidPackageSchema from './baseschemas/bidPackage.json'
import customBidPackageSchema from './customschemas/bidPackage.json'
import uiBidPackageSchema from './uischemas/bidPackage.json'
import baseBidResponseSchema from './baseschemas/bidResponse.json'
import customBidResponseSchema from './customschemas/bidResponse.json'
import uiBidResponseSchema from './uischemas/bidResponse.json'
import baseInvoiceSchema from './baseschemas/invoice.json'
import customInvoiceSchema from './customschemas/invoice.json'
import uiInvoiceSchema from './uischemas/invoice.json'

const facilitySchema = {
    baseSchema: baseFacilitySchema,
    customSchema: customFacilitySchema,
    uiSchema: uiFacilitySchema
}

const facilityGroupSchema = {
    baseSchema: baseFacilityGroupSchema,
    customSchema: customFacilityGroupSchema,
    uiSchema: uiFacilityGroupSchema
}

const landownerSchema = {
    baseSchema: baseLandownerSchema,
    customSchema: customLandownerSchema,
    uiSchema: uiLandownerSchema
}

const bidPackageSchema = {
    baseSchema: baseBidPackageSchema,
    customSchema: customBidPackageSchema,
    uiSchema: uiBidPackageSchema
}

const bidResponseSchema = {
    baseSchema: baseBidResponseSchema,
    customSchema: customBidResponseSchema,
    uiSchema: uiBidResponseSchema
}

const invoiceSchema = {
    baseSchema: baseInvoiceSchema,
    customSchema: customInvoiceSchema,
    uiSchema: uiInvoiceSchema
}

export { facilitySchema, facilityGroupSchema, landownerSchema, bidPackageSchema, bidResponseSchema, invoiceSchema }