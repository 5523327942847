import { apiUrls } from '../../../utils/constants/urls'
import { toast } from 'react-toastify'

const getFacilityGroups = (authFetch, API_URL, queryParameters, after) => {
    const queryString = new URLSearchParams(queryParameters).toString()
    authFetch(
        `${API_URL}/${apiUrls.facilityGroup}?${queryString}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Bid Packages: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getFacilityGroupListMetadata = (authFetch, API_URL, queryParameters, after) => {
    const queryString = new URLSearchParams(queryParameters).toString()
    authFetch(
        `${API_URL}/${apiUrls.facilityGroup}/listMeta/?${queryString}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Bid Packages: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}


export { getFacilityGroups, getFacilityGroupListMetadata }
