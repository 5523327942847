import { resolveData, toDataPath } from '@jsonforms/core'
import React, { createContext, useState, useEffect, useContext, useCallback, useMemo } from 'react'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import { getLookupOptions } from './actions'
import { update } from '@jsonforms/core'


const DataContext = createContext(null)

const LookupDataContextProvider = withConfig(({config, uischema, data, path, dispatch, children}) => {
    const [lookupOptions, setLookupOptions] = useState([])
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [selectedOption, setSelectedOption] = useState(null)
    const { options } = uischema
    const { API_URL } = config

    
    const setData = useCallback(
        (updatedData) => {
            dispatch(update(path, () => updatedData))
        },
        [data, path, dispatch]
    )

    // when options or data change, set the whereid value if dependent on data elsewhere
    // in the form
    useEffect(() => {
        if ((options.whereId != null) && (options.whereId.length > 0) && (data != null)) {
            const wPath = options.whereId
            const wValue = resolveData(data, wPath)
            options.whereIdValue = (wValue) ? wValue.toString() : '0'
        }
    }, [options, data])

    // when ui schema options change, go fetch the options list
    useEffect(() => {
        getLookupOptions(authenticatedFetch, API_URL, options, (d) => {
            setLookupOptions(d)
        })
    }, [options, API_URL, authenticatedFetch])

    // when the lookupOptions get in, see if the current value
    // is in the list and set that as the selected option
    useEffect(() => {
        const currentData = resolveData(data, path)
        if (lookupOptions && lookupOptions.length) {
            if (typeof currentData !== 'undefined') {
                const matchingOption = lookupOptions.find(x => x.id.toString() === currentData.toString())
                    setSelectedOption(matchingOption)
            } else {
                setSelectedOption('')
            }
        }
    }, [lookupOptions, data])

    // when the select changes, update the form's value
    const handleSelectChange = useCallback((evt, d) => {
        if (d) {
            setData(d.id)
        } else {
            setData(undefined)
        }
        // if the lookupcontroller has dependent data (eg facility work record based on bid package id)
        // then clear that when the select valuechanges
        if (options && options.dependentDataPath) {
            dispatch(update(toDataPath(options.dependentDataPath), () => []))
        }
    }, [setData, options])

    return (
        <DataContext.Provider
            value={{lookupOptions, selectedOption, handleSelectChange}}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }

export default LookupDataContextProvider