import { Add, FilterAltOff } from '@mui/icons-material'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { checkRole, hasAccessToAdmin } from '../../../../utils/user/permissions'
import { UserContext } from '../../../wrappers/UserContext'

import Button from '../../Button'

const GridControls = ({ createLink, clearFunction }) => {
    const { roles } = useContext(UserContext)
    const hasEditorRole = checkRole('Editor', roles)
    const hasAdminRole = hasAccessToAdmin(roles)
    
    return (
        <div className="level-right gridControls">
            {(createLink && (hasEditorRole || hasAdminRole)) && (
                <div className="level-item">
                    <Link to={createLink}>
                        <Button
                            text={'Create'}
                            size="small"
                            iconComponent={Add}
                            action={() => {}}
                        />
                    </Link>
                </div>
            )}
            <div className="level-item">
                    <Button
                        text={'Clear Filters'}
                        size="small"
                        iconComponent={FilterAltOff}
                        action={() => clearFunction()}
                    />
            </div>
        </div>
    )
}

export default GridControls
