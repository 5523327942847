import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import Header from './components/elem/Header'
import CallbackComponent from './components/features/user/CallbackComponent'
import RestrictedAccess from './components/features/user/RestrictedAccess'
import Login from './components/features/landing/Login'

import UserContextProvider from './components/wrappers/UserContext'
import APIRequestContextProvider from './components/wrappers/APIRequestContext'

import urls from './utils/constants/urls'
import LandingPage from './components/features/landing/LandingPage'
// facility
import FacilityDataContextProvider from './components/features/facility/DataContext'
import Facility from './components/features/facility/Facility'
// facilities
import Facilities from './components/features/facilities/Facilities'
import FacilitiesDataContextProvider from './components/features/facilities/DataContext'
// facility groups
import FacilityGroupsDataContextProvider from './components/features/facility-groups/DataContext'
import FacilityGroups from './components/features/facility-groups/FacilityGroups'
// facility group
import FacilityGroupDataContextProvider from './components/features/facility-group/DataContext'
import FacilityGroup from './components/features/facility-group/FacilityGroup'
// bid package
import BidPackage from './components/features/bid-package/BidPackage'
import BidPackageDataContextProvider from './components/features/bid-package/DataContext'
// bid packages
import BidPackages from './components/features/bid-packages/BidPackages'
import BidPackagesDataContextProvider from './components/features/bid-packages/DataContext'
// bid response
import BidResponse from './components/features/bid-response/BidResponse'
import BidResponseDataContextProvider from './components/features/bid-response/DataContext'
// bid responses
import BidResponses from './components/features/bid-responses/bidResponses'
import BidResponsesDataContextProvider from './components/features/bid-responses/DataContext'
// invoice
import Invoice from './components/features/invoice/Invoice'
import InvoiceDataContextProvider from './components/features/invoice/DataContext'
// invoices
import Invoices from './components/features/invoices/Invoices'
import InvoicesDataContextProvider from './components/features/invoices/DataContext'

import GlobalStyle from './theme/GlobalStyle'
import FormStateContextProvider, { FormStateContext } from './components/wrappers/FormStateContext'

function App() {
    return (
        <React.Fragment>
            <GlobalStyle />
            <Header />
            <div className="contentWrapper">
                <Router>
                    <UserContextProvider>
                        <APIRequestContextProvider>
                            <FormStateContextProvider>
                                <Routes>
                                    <Route
                                        exact
                                        path={urls.userCallback}
                                        element={<CallbackComponent />}
                                    />
                                    <Route
                                        exact
                                        path="/"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <LandingPage />
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/facilities"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <FacilitiesDataContextProvider>
                                                    <Facilities />
                                                </FacilitiesDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/bidPackages"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <BidPackagesDataContextProvider>
                                                    <BidPackages />
                                                </BidPackagesDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidResponse/new'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor']}
                                            >
                                                <BidResponseDataContextProvider>
                                                    <BidResponse />
                                                </BidResponseDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/bidResponses"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <BidResponsesDataContextProvider>
                                                    <BidResponses />
                                                </BidResponsesDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidPackage/new'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor']}
                                            >
                                                <BidPackageDataContextProvider>
                                                    <BidPackage />
                                                </BidPackageDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/projects"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <FacilityGroupsDataContextProvider>
                                                    <FacilityGroups />
                                                </FacilityGroupsDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/project/new'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor']}
                                            >
                                                <FacilityGroupDataContextProvider>
                                                    <FacilityGroup />
                                                </FacilityGroupDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/invoices"
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <InvoicesDataContextProvider>
                                                    <Invoices />
                                                </InvoicesDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/invoice/new'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor']}
                                            >
                                                <InvoiceDataContextProvider>
                                                    <Invoice />
                                                </InvoiceDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/facility/:facilityId'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <FacilityDataContextProvider>
                                                    <Facility />
                                                </FacilityDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/project/:facilityGroupId'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <FacilityGroupDataContextProvider>
                                                    <FacilityGroup />
                                                </FacilityGroupDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidPackage/:bidPackageId'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <BidPackageDataContextProvider>
                                                    <BidPackage />
                                                </BidPackageDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/bidResponse/:bidResponseId'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <BidResponseDataContextProvider>
                                                    <BidResponse />
                                                </BidResponseDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route
                                        exact
                                        path={'/invoice/:invoiceId'}
                                        element={
                                            <RestrictedAccess
                                                allowedRoles={['Administrator', 'Editor', 'Viewer']}
                                            >
                                                <InvoiceDataContextProvider>
                                                    <Invoice />
                                                </InvoiceDataContextProvider>
                                            </RestrictedAccess>
                                        }
                                    />
                                    <Route path="/login" element={<Login />} />
                                </Routes>
                            </FormStateContextProvider>
                        </APIRequestContextProvider>
                    </UserContextProvider>
                </Router>
            </div>
            <ToastContainer />
        </React.Fragment>
    )
}

export default App
