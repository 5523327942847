import { apiUrls } from '../../../../utils/constants/urls'
import toast from '../../Toast'

const getLookupOptions = (authFetch, API_URL, options, after) => {
    const queryString = new URLSearchParams(options).toString()
    authFetch(
        `${API_URL}/${apiUrls.lookup}?${queryString}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Lookup: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export {getLookupOptions}