import { apiUrls } from '../../../utils/constants/urls'
import { removeEmptyParameters } from '../../../utils/queryParameters'
import toast from '../../elem/Toast'

const getFacility = (authFetch, API_URL, facilityId, after) => {
    authFetch(
        `${API_URL}/${apiUrls.facility}/${facilityId}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Facility: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getFacilitySchema = (authFetch, API_URL, after) => {
    authFetch(
        `${API_URL}/${apiUrls.schema}/facility`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Schema: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getFacilityAutoCalc = (authFetch, API_URL, facility, abortController, after) => {
    const queryParams = removeEmptyParameters(facility)
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(queryParams),
        signal: abortController.signal
    }  
    authFetch(`${API_URL}/${apiUrls.autoCalc}/facility`, POST)
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            if (e.name !== 'AbortError') { 
                toast({
                    level: 'error',
                    message:
                        'Schema: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            }
        })
}

const updateFacility = (authFetch, API_URL, facility, after) => {
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(facility)
    }  
    authFetch(
        `${API_URL}/${apiUrls.facility}`, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return await response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response)
            
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Update: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export { getFacility, getFacilitySchema, updateFacility, getFacilityAutoCalc }
