import StringCell from '../../../components/elem/grid/display/cells/StringCell'
import NumberCell from '../../../components/elem/grid/display/cells/NumberCell'
import DateCell from '../../../components/elem/grid/display/cells/DateCell'
import DateTimeCell from '../../../components/elem/grid/display/cells/DateTimeCell'
import LinkCell from '../../../components/elem/grid/display/cells/LinkCell'
import CurrencyCell from '../../../components/elem/grid/display/cells/CurrencyCell'

const getCellComponent = (type) => {
    switch (type) {
        case 'String':
            return StringCell
        case 'Number':
            return NumberCell
        case 'Date':
            return DateCell
        case 'DateTime':
            return DateTimeCell
        case 'Link':
            return LinkCell
        case 'Currency':
            return CurrencyCell
        default:
            return StringCell
    }
}

export default getCellComponent