import React, { useContext } from 'react'
import urls from '../../../utils/constants/urls'

import Form from '../../elem/form/Form'
import FormWrapper from '../../elem/form/FormWrapper'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const Facility = () => {
    const { facility, setFacility, schemas, editing, setEditing, errors, setErrors, additionalErrors, setAdditionalErrors, saveForm, resetForm, submitting, facilityTitle } = useContext(DataContext)
    return (
        <PageLayout returnUrl={urls.facilities}>
            <FormWrapper title="Facility" subTitle={facilityTitle} editing={editing} setEditing={setEditing} schemas={schemas} data={facility} loading={submitting} errors={errors} saveForm={saveForm} resetForm={resetForm} parentContext={DataContext} >
                <Form name='facility' data={facility} schemas={schemas} setData={setFacility} errors={errors} setErrors={setErrors} additionalErrors={additionalErrors} setAdditionalErrors={setAdditionalErrors} editing={editing} />
            </FormWrapper>
        </PageLayout>
    )

}

export default Facility