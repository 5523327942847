const landownerUiSchema = {
    "type": "Group",
    "label": "Entity Information",
    "elements": [
        {
            "type": "TightLayout",
            "elements": [
                {
                    "type": "Label",
                    "text": "After you successfully create or edit an Entity, you must Save the form to assign it!",
                    "variant": "caption",
                    "editingOnly": true
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/coNo",
                    "label": "CONO"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/landownerName",
                    "label": "Entity (Business Name or Last Name, First Name)"
                },
                {
                    "type": "Control",
                    "specializedType": "Phone",
                    "scope": "#/properties/landowner/properties/phoneNumber",
                    "label": "Phone Number"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/phoneNumberExt",
                    "label": "Phone Ext"
                },
                {
                    "type": "Control",
                    "specializedType": "Phone",
                    "scope": "#/properties/landowner/properties/altPhoneNumber",
                    "label": "Alt Phone Number"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/email",
                    "label": "Email"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalAddress1",
                    "label": "Physical Address 1"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalAddress2",
                    "label": "Physical Address 2"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalCity",
                    "label": "Physical City"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalState",
                    "label": "Physical State",
                    "specializedType": "Lookup",
                    "options": {
                        "tableName": "RefCodes",
                        "codeColumn": "Code",
                        "descriptionColumn": "Description",
                        "keyColumn": "Fld",
                        "keyValue": "State"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalZip5",
                    "label": "Physical Zip5"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalZip4",
                    "label": "Physical Zip4"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/physicalCountry",
                    "label": "Physical Country"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingAddress1",
                    "label": "Mailing Address 1"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingAddress2",
                    "label": "Mailing Address 2"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingCity",
                    "label": "Mailing City"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingState",
                    "label": "Mailing State",
                    "specializedType": "Lookup",
                    "options": {
                        "tableName": "RefCodes",
                        "codeColumn": "Code",
                        "descriptionColumn": "Description",
                        "keyColumn": "Fld",
                        "keyValue": "State"
                    }
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingZip5",
                    "label": "Mailing Zip5"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingZip4",
                    "label": "Mailing Zip4"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/mailingCountry",
                    "label": "Mailing Country"
                },
                {
                    "type": "Control",
                    "scope": "#/properties/landowner/properties/comments",
                    "label": "Comments"
                }
            ]
        }
    ]
}
export default landownerUiSchema