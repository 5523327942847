import React from 'react'
import { IconContext } from 'react-icons'
import { FaSpinner } from 'react-icons/fa'

const Spinner = ({ size, extraClass }) => {
    return (
        <div className={`loadingSpinnerWrapper ${extraClass ? extraClass : ''}`}>
            <IconContext.Provider value={{ className: 'fa-pulse', size: size }}>
                <FaSpinner />
            </IconContext.Provider>
        </div>
    )
}

export default Spinner
