import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef
} from 'react'
import isEqual from 'lodash/isEqual'

import { useParams, useNavigate } from 'react-router'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getBidPackage, getBidPackageSchema, createBidPackage, updateBidPackage, getBidPackageAutoCalc } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { parseSchemaObjects, separatePropertiesBasedOnSchemaType } from '../../../utils/json/schemas'

// TODO: Testing, replace with api call later
import { bidPackageSchema } from '../../../utils/testing/schemas'
import urls from '../../../utils/constants/urls'
import { checkFormErrors, checkEditingChild } from '../../../utils/form/errors'
import { debounce } from '@material-ui/core'
import titles, { constructFormTitle } from '../../../utils/constants/titles'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [bidPackage, setBidPackage] = useState(null)
    const [initialState, setInitialState] = useState(null)
    const [schemas, setSchemas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [errors, setErrors] = useState([])
    const [additionalErrors, setAdditionalErrors] = useState([])
    const [editingChild, setEditingChild] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    const bidPackageId = useMemo(() => params.bidPackageId, [params])
    const beforeAutoCalc = useRef({})
    const [bidPackageTitle, setBidPackageTitle] = useState(null)

    // when the bid package changes, submit the data to the auto calc endpoint
    // and set the response if we're editing
    const [autoCalcAbortController, setAutoCalcAbortController] = useState(new AbortController())
    const autoCalculateInputs = useCallback(() => {
        autoCalcAbortController.abort()
        if (!isEqual(beforeAutoCalc.current, bidPackage) && editing) {
            if (bidPackage && bidPackage.bidPackage) {
                const abortController = new AbortController()
                setAutoCalcAbortController(abortController)
                getBidPackageAutoCalc(authenticatedFetch, API_URL, bidPackage.bidPackage, abortController, (d) => {
                    if (d) {
                        const newBidPackage = ({
                            ...bidPackage.bidPackage,
                            ...d
                        })
                        beforeAutoCalc.current = { bidPackage: newBidPackage }
                        setBidPackage({bidPackage: newBidPackage})
                    }
                })
            }
        }
    }, [bidPackage, beforeAutoCalc, setBidPackage, editing, autoCalcAbortController])

    useEffect(() => {
        debounce(autoCalculateInputs(), 1500)
    }, [bidPackage, editing])

    const resetBidPackage = useCallback((d) => {
        const data = parseJsonAttributes(d)
        setBidPackage({bidPackage: data})
        setBidPackageTitle(constructFormTitle(data, titles.bidPackage))
        setInitialState(data)
    }, [setInitialState, setBidPackage])

    useEffect(() => {
        if (bidPackageId) {
            getBidPackage(authenticatedFetch, API_URL, bidPackageId, (d) => {
                resetBidPackage(d)
                setEditing(false)
            })
        } else {
            resetBidPackage({})
            setEditing(true)
        }
    }, [bidPackageId, resetBidPackage, API_URL, authenticatedFetch])

    useEffect(() => {
        // getBidPackageSchema(authenticatedFetch, API_URL, (s) => {
        //     const parsedSchemas = parseSchemaObjects(s)
        //     setSchemas(parsedSchemas)
        // })

        // TODO: Update after testing
        setSchemas(bidPackageSchema)
    }, [])

    useEffect(() => {
        if (schemas && bidPackage) {
            setLoading(false)
        }
    }, [schemas, bidPackage])

    const saveForm = useCallback(() => {
        setSubmitting(true)
        checkEditingChild(editingChild).then(() => checkFormErrors(errors, additionalErrors)).then(() => {
            const data = separatePropertiesBasedOnSchemaType(bidPackage.bidPackage, schemas)
            // if id already exists, it is an update
            if (bidPackage.bidPackage.bidPackageId) {
                updateBidPackage(authenticatedFetch, API_URL, data, (res) => {
                    resetBidPackage(res.data)
                    setEditing(false)
                    setSubmitting(false)
                }, (e) => {
                    setSubmitting(false)
                })
                return
            } else {
                createBidPackage(authenticatedFetch, API_URL, data, (res) => {
                    setSubmitting(false)
                    setEditing(false)
                    navigate(`${urls.bidPackage}/${res}`)
                }, (e) => {
                    setSubmitting(false)
                })
                return
            }
        })
        .catch(() => setSubmitting(false))
    }, [bidPackage, schemas, errors, additionalErrors, editingChild, resetBidPackage, authenticatedFetch, API_URL, navigate])

    const resetForm = useCallback((cancel) => {
        if (cancel && !(initialState && initialState.bidPackageId)) {
            navigate(urls.bidPackages)
        } else {
            autoCalcAbortController.abort()
            setBidPackage({bidPackage: initialState})
        }
    }, [initialState, autoCalcAbortController])

    return (
        <DataContext.Provider
            value={{
                bidPackage,
                setBidPackage,
                schemas,
                loading,
                errors,
                setErrors,
                additionalErrors, 
                setAdditionalErrors,
                setEditingChild,
                saveForm,
                resetForm,
                editing,
                setEditing,
                submitting,
                bidPackageTitle
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
