import { Link } from "react-router-dom"
import getCellComponent from "../display/cells"

const getValidColumnProps = (scopedSchema) => {
    if (
        scopedSchema.type === 'object' &&
        typeof scopedSchema.properties === 'object'
    ) {
        return Object.keys(scopedSchema.properties).filter(
            (prop) => scopedSchema.properties[prop].type !== 'array'
        )
    }
    // primitives
    return ['']
}

const getColumns = (staticConfig, attributeConfig) => {
    const staticColumns = Object.keys(staticConfig).map((c) => {
        return {
            prop: c,
            label: staticConfig[c].Label,
        }
    })
    const attributeColumns = Object.keys(attributeConfig).map((c) => {
        return {
            prop: c,
            label: attributeConfig[c].Label,
        }
    })

    return [...staticColumns, ...attributeColumns]
}

const getGridColumns = (staticConfig, attributeConfig) => {
    const staticColumns = Object.keys(staticConfig).map((c) => {
        const colConfig = staticConfig[c]
        const columnType = colConfig.DataType
        if (columnType === 'Link') {
            return {
                field: c,
                title: colConfig.Label,
                render: rowData => <Link to={`${colConfig.BaseUrl}/${rowData[c]}`}>{rowData[c]}</Link>,
                seq: colConfig.Seq
            }
        }
        const Cell = getCellComponent(columnType)
        return {
            field: c,
            title: colConfig.Label,
            render: rowData => <Cell value={rowData[c]} />,
            seq: colConfig.Seq
        }
    })
    const attributeColumns = Object.keys(attributeConfig).map((c) => {
        const colConfig = attributeConfig[c]
        const columnType = colConfig.DataType
        if (columnType === 'Link') {
            return {
                field: c,
                title: colConfig.Label,
                render: rowData => <Link to={`${colConfig.BaseUrl}/${rowData[c]}`}>{rowData[c]}</Link>,
                seq: colConfig.Seq
            }
        }
        const Cell = getCellComponent(columnType)
        return {
            field: c,
            title: colConfig.Label,
            render: rowData => <Cell value={rowData[c]} />,
            seq: colConfig.Seq
        }
    })

    const unsorted = [...staticColumns, ...attributeColumns]
    unsorted.sort((a, b) => a.seq - b.seq)
    return unsorted
}

export { getValidColumnProps, getColumns, getGridColumns }
