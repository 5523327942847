const theme = {
    palette: {
        secondary: {
            main: '#c9ccf6',
          },
    },
}

const defaultOptions = {
    filtering: true,
    headerStyle: {
        position: 'sticky',
        top: 0,
        height: 50,
    },
    maxBodyHeight: '60vh',
    filterRowStyle: {
        position: 'sticky',
        top: 50,
        background: 'white',
        zIndex: 5 /* optionally */,
    },
}

export { theme, defaultOptions }
