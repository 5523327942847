import React, { useContext } from "react";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { FormStateContext } from "../../wrappers/FormStateContext"; 

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const BreadCrumbLink = () => {
	const { breadCrumbList, updateBreadCrumbList } = useContext(FormStateContext)
	const location = useLocation()
	
	return (
		<div className="breadCrumbContainer">
			<Breadcrumbs aria-label="breadcrumb">
				{breadCrumbList.map((bread, idx) => {
					// const last = idx === breadCrumbList.length - 1
					const to = `/${bread.path}/${bread.id}`
					const current = location.pathname === to

					return current ? ( //last ? (
						<Typography color="text.primary" key={to}>
							{bread.title} - {bread.id}
						</Typography>
					) : (
						<LinkRouter underline="hover" color="inherit" to={to} 
							key={to} onClick={ () => {
								updateBreadCrumbList(idx) 
							}}
						>
							{bread.title} - {bread.id}
						</LinkRouter>
					)
				})}
			</Breadcrumbs>
		</div>
	)
}

export default BreadCrumbLink