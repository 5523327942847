import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback
} from 'react'
import { useParams, useNavigate } from 'react-router'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getFacilityGroup, getFacilityGroupSchema, updateFacilityGroup, createFacilityGroup } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { parseSchemaObjects, separatePropertiesBasedOnSchemaType } from '../../../utils/json/schemas'

// // TODO: Testing, replace with api call later
// import baseSchema from '../../../utils/testing/baseschemas/facilityGroup.json'
// import customSchema from '../../../utils/testing/customschemas/facilityGroup.json'
// import uiSchema from '../../../utils/testing/uischemas/facilityGroup.json'
import urls from '../../../utils/constants/urls'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [facilityGroup, setFacilityGroup] = useState(null)
    const [initialState, setInitialState] = useState(null)
    const [schemas, setSchemas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [errors, setErrors] = useState([])
    const params = useParams()
    const navigate = useNavigate()
    const facilityGroupId = useMemo(() => params.facilityGroupId, [params])

    useEffect(() => {
        if (facilityGroupId) {
            getFacilityGroup(authenticatedFetch, API_URL, facilityGroupId, (d) => {
                const data = parseJsonAttributes(d)
    
                setFacilityGroup({facilityGroup: data})
                setInitialState(data)
                setEditing(false)
            })
        } else {
            setFacilityGroup({facilityGroup: {}})
            setInitialState({})
            setEditing(true)
        }
    }, [facilityGroupId])

    useEffect(() => {
        getFacilityGroupSchema(authenticatedFetch, API_URL, (s) => {
            const parsedSchemas = parseSchemaObjects(s)
            setSchemas(parsedSchemas)
        })
        // const testSchemas = {
        //     customSchema,
        //     baseSchema,
        //     uiSchema
        // }
        // setSchemas(testSchemas)
    }, [])

    useEffect(() => {
        if (schemas && facilityGroup) {
            setLoading(false)
        }
    }, [schemas, facilityGroup])

    const saveForm = useCallback(() => {
        const data = separatePropertiesBasedOnSchemaType(facilityGroup.facilityGroup, schemas)
        // if id already exists, it is an update
        if (facilityGroup.facilityGroup.facilityGroupId) {
            updateFacilityGroup(authenticatedFetch, API_URL, data, (res) => {
                setInitialState(res)
                setEditing(false)
            })
        } else {
            createFacilityGroup(authenticatedFetch, API_URL, data, (res) => {
                navigate(`${urls.project}/${res}`)
            })
        }
    }, [facilityGroup, schemas])

    const resetForm = useCallback(() => {
        setFacilityGroup({facilityGroup: initialState})
    }, [initialState])

    return (
        <DataContext.Provider
            value={{
                facilityGroup,
                setFacilityGroup,
                schemas,
                loading,
                errors,
                setErrors,
                saveForm,
                resetForm,
                editing,
                setEditing,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
