const removeEmptyParameters = (parameters) => {
    Object.keys(parameters).forEach(key => {
        const value = parameters[key]
        if (typeof value === 'undefined' || value === '' || value === null) {
            delete parameters[key]
        }
    })
    return parameters
}

const updateParameters = (newParameters, prevParameters) => {
    const mergedParams = {
        ...prevParameters,
        ...newParameters,
    }
    return removeEmptyParameters(mergedParams)
}

const clearParameters = 
    (paramsToKeep = {}) => {
        return {
            page: 0,
            pageSize: 25,
            ...paramsToKeep,    
        }
    }

export { removeEmptyParameters, clearParameters, updateParameters }