import React from 'react'
import CellWrapper from './CellWrapper'


const StringCell = ({value}) => {
    return (
        <CellWrapper>
            {value && value.includes('<br/>')
                ? value
                      .split('<br/>')
                      .map((line, idx) => <div key={idx}>{line}</div>)
                : value}
        </CellWrapper>
    )
}

export default StringCell