import React from 'react'
import CellWrapper from './CellWrapper'

const numberToCurrency = (value) =>
    value == 0 || value ? '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : null

const CurrencyCell = ({ value }) => {
    return <CellWrapper>{numberToCurrency(value)}</CellWrapper>
}

export default CurrencyCell