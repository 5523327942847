import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback,
} from 'react'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getFacilities, getFacility, getFacilityListMetadata } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'

import { gridConfig as testConfig } from '../../../utils/testing/config' // TODO: replace with api requested data
import { removeEmptyParameters, clearParameters, updateParameters } from '../../../utils/queryParameters'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [facilities, setFacilities] = useState([])
    const [loading, setLoading] = useState(true)
    const [gridConfigExpanded, setGridConfigExpanded] = useState(false)
    const [gridConfig, setGridConfig] = useState(testConfig)
    const [queryParameters, setQueryParameters] = useState({
        page: 0,
        pageSize: 25,
    })
    const [listMetadata, setListMetadata] = useState({ count: 0, pages: 0 })

    const updateQueryParameters = useCallback(
        (newParameters) => {
            setQueryParameters( prevParameters => updateParameters(newParameters, prevParameters))
        }, [setQueryParameters]
    )

    const clearQueryParameters = useCallback((paramsToKeep = {}) =>
        setQueryParameters(clearParameters(paramsToKeep))
        , [setQueryParameters]
    )

    const fetchFacilityListMetadata = useCallback((queryParameters) => new Promise((resolve, reject) => {
        try {
            getFacilityListMetadata(
                authenticatedFetch,
                API_URL,
                queryParameters,
                (res) => {
                    setListMetadata(res)
                    return resolve()
                }
            )
        } catch (e) {
            return reject(e)
        }
    }), [])

    const fetchFacilities = useCallback(
        (queryParameters) => new Promise((resolve, reject) => {
            try {
                getFacilities(
                    authenticatedFetch,
                    API_URL,
                    queryParameters,
                    (res) => {
                        setFacilities(res)
                        return resolve()
                    }
                )
            } catch (e) {
                return reject()
            }
        }),
        [authenticatedFetch, API_URL]
    )

    // when query parameters change, update table data
    useEffect(() => {
        const updateTableData = async () => {
            setLoading(true)
            try {
                await fetchFacilities(queryParameters)
                await fetchFacilityListMetadata(queryParameters)
                setLoading(false)
            } catch (e) {
                setLoading(false)
            }
        }
        updateTableData()
    }, [queryParameters])

    return (
        <DataContext.Provider
            value={{
                facilities,
                updateQueryParameters,
                queryParameters,
                listMetadata,
                loading,
                gridConfig,
                gridConfigExpanded,
                setGridConfigExpanded,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
