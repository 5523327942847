import React from 'react'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Badge, Tooltip, styled } from '@mui/material'

const StyledBadge = styled(Badge)(({ theme }) => ({
    color: theme.palette.error.main,
}))

const ValidationIcon = ({ errorMessages, id }) => {
    let uniqueErrors = errorMessages
    const separateMessages = errorMessages.split('\n')

    // todo: this is a hack to resolve GPA-329 -
    // probably this should be handled by only surfacing one error message in the
    // first place for the minItems / required combination on array schemas
    if (separateMessages.filter(x => x === 'must NOT have fewer than 1 items').length > 1) {
        const otherErrors = separateMessages.filter(x => x !== 'must NOT have fewer than 1 items' )
        otherErrors.push('must NOT have fewer than 1 items')
        uniqueErrors = otherErrors.join('\n')
    }
    
    return (
        <Tooltip id={id} title={uniqueErrors}>
            <StyledBadge badgeContent={uniqueErrors.split('\n').length}>
                <ErrorOutlineIcon color="inherit" />
            </StyledBadge>
        </Tooltip>
    )
}

export default ValidationIcon
