import React from 'react'
import getCellComponent from '../../../../utils/grids/display/cells'

const Cell = ({ value, column, row, ...properties }) => {
    return (
        <div className="tableCell">
            {React.createElement(getCellComponent(properties.DataType), { value, ...properties })}
        </div>
    )
}

export default Cell
