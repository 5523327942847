import { materialCells } from '@jsonforms/material-renderers'
import { JsonForms } from '@jsonforms/react'
import { Autorenew, Cancel, Edit, Save } from '@material-ui/icons'
import { Button, Card, CardActions } from '@mui/material'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ajv from '../ajv/ajv'
import renderers from '../renderers/renderers'
import Loading from './Loading'
import { DataContext } from './SelectOrCreateDataContext'

const Display = (props) => {
    
    const { enabled } = props
    const {
        isCreating,
        isLinkDataLoading,
        existingData,
        uischema,
        schema,
        name,
        saveFormData,
        canEdit,
        isEditing,
        setIsEditing
    } = useContext(DataContext)
    const initialData = useMemo(() => ({[name]: existingData}), [existingData])
    const [formData, setFormData] = useState({[name]: existingData})
    const [formErrors, setFormErrors] = useState()

    useEffect(() => {
        setFormData({[name]: existingData})
    }, [existingData, setFormData])

    
    const handleFormChange = useCallback(
        ({ data, errors }) => {
            if (isEditing) {
                setFormData(data)
                setFormErrors(errors)
            }
        },
        [setFormData, setFormErrors, isEditing]
    )

    const canSave = useMemo(() => {
        return (
            typeof formErrors === 'undefined' ||
            !(formErrors && formErrors.length)
        )
    }, [formErrors])

    const resetRecord = useCallback(() => {
        setFormData({[name]: existingData})
    }, [existingData, setFormData])

    const cancelEditing = useCallback(() => {
        setFormData({[name]: existingData})
        setIsEditing(false)
    }, [existingData, setFormData, setIsEditing])

    const saveRecord = useCallback(() => {
        saveFormData(formData)
    }, [saveFormData, formData])
    
    if (!schema || isLinkDataLoading) {
        return (
            <Loading />
        )
    }
    return (
        (!isCreating || !enabled) && (
            <div className="createRecordForm">
                <JsonForms
                    schema={schema}
                    uischema={uischema}
                    data={formData}
                    ajv={ajv}
                    renderers={renderers}
                    onChange={handleFormChange}
                    cells={materialCells}
                    readonly={!isEditing}
                    validationMode={!isEditing ? "NoValidation" : "ValidateAndShow"}
                />
                <Card>
                    <CardActions>
                        {!isEditing && enabled && canEdit && (
                            <Button
                                startIcon={<Edit />}
                                onClick={() => setIsEditing(true)}
                                fullWidth
                            >
                                Edit
                            </Button>
                        ) }
                        {
                            isEditing && (
                                <>
                                    <Button
                                        startIcon={<Save />}
                                        onClick={saveRecord}
                                        fullWidth
                                        disabled={!canSave}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        startIcon={<Autorenew />}
                                        onClick={() => resetRecord()}
                                        fullWidth
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        startIcon={<Cancel />}
                                        onClick={() => cancelEditing()}
                                        fullWidth
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )
                        }
                    </CardActions>
                </Card>
            </div>
        )
    )
}
export default Display
