import React, { useEffect } from 'react'

import urls from '../../../utils/constants/urls'
// import LoadingSpinner from '../../elem/LoadingSpinner'

const CallbackComponent = () => {
    useEffect(() => {
        const t = setTimeout(() => {
            window.location = urls.home
            return null
        }, 5000)
        return () => clearTimeout(t)
    }, [])

    return (
        <div className="hero is-large">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <div className="title">
                        <div className="callbackSpinnerWrapper">
                            {/* <LoadingSpinner size={'4rem'}/> */}
                        </div>
                    </div>
                    <p className="subtitle authorizationMessage">Authorizing...</p>
                </div>
            </div>
        </div>
    )
}

export default CallbackComponent
