import React, { useContext } from 'react'
import urls from '../../../utils/constants/urls'

import Form from '../../elem/form/Form'
import FormWrapper from '../../elem/form/FormWrapper'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const BidResponse = () => {
    const { bidResponse, setBidResponse, submitting, schemas, editing, setEditing, errors, setErrors, additionalErrors, setAdditionalErrors, saveForm, resetForm, bidResponseTitle } = useContext(DataContext)
    
    return (
        <PageLayout returnUrl={urls.bidResponses}>
            <FormWrapper title="Bid Response" subTitle={bidResponseTitle} editing={editing} setEditing={setEditing} schemas={schemas} data={bidResponse} errors={errors} saveForm={saveForm} resetForm={resetForm} loading={submitting} parentContext={DataContext}>
                <Form name='bidResponse' data={bidResponse} schemas={schemas} setData={setBidResponse} errors={errors} setErrors={setErrors} additionalErrors={additionalErrors} setAdditionalErrors={setAdditionalErrors} editing={editing} />
            </FormWrapper>
        </PageLayout>
    )

}

export default BidResponse