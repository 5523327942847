import React from 'react'
import { useJsonForms } from '@jsonforms/react'
import LookupDataContextProvider from './LookupDataContext'
import SelectControl from './SelectControl'

const Lookup = (props) => {
    const { uischema, path } = props
    const ctx = useJsonForms()
    const {
        dispatch,
        core: { data },
    } = ctx

    return (
        <LookupDataContextProvider uischema={uischema} data={data} path={path} dispatch={dispatch}>
            <SelectControl {...props} />
        </LookupDataContextProvider>
    )
}

export default Lookup