import React, { useContext } from 'react'
import { useLocation, Navigate } from 'react-router-dom'
import { UserContext } from '../../wrappers/UserContext'
import withConfig from '../../wrappers/withConfig'
import urls from '../../../utils/constants/urls'
import Button from '@mui/material/Button'
import { ContainerLogin, Footer, Title, Wrapper } from './styled';

const Login = withConfig(({ config }) => {
    const { user, client } = useContext(UserContext)
    const location = useLocation()
    const { CONFIGURED_URLS, APP_TITLE } = config

    if (location.pathname !== urls.login) {
        window.history.replaceState(null, '', urls.login)
    }

    if (user) {
        return (
            <Navigate to={urls.home} replace />
        )
    }

    return (
        <ContainerLogin>
            <div className="section">
                <Title>{APP_TITLE}</Title>
            </div>
            <div className="section">
                <div className="forms-section">
                    <section className="forms">
                        <Wrapper>
                            <Button variant="text" className={'loginButton'} size="large" 
                                sx={{ width:150, padding: 2, margin:2, }} onClick={ () => client.signinRedirect() }
                            >
                                Login
                            </Button>
                            <a
                                href={CONFIGURED_URLS.newRegistration}
                                target="_blank"
                                rel="opener"
                            >
                                <Button variant="text" size="large" className={'loginButton'} sx={{
                                    width: 150, padding: 2, margin:2,
                                }}>Register</Button>
                            </a>
                        </Wrapper>
                    </section>
                </div>
            </div>
            <Footer>
                <p>© Copyright 2022</p>
            </Footer>
        </ContainerLogin>
    )
})

export default Login
