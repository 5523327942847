import React from 'react'
import { Card, CardContent, Container, Grid } from '@material-ui/core'
import Spinner from '../../Spinner'

const Loading = () => {
    return (
        <Card>
            <CardContent>
                <Grid container justifyContent="center">
                    <Grid item>
                        <Container>
                            <Spinner size={30} extraClass="cardSpinner" />
                        </Container>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default Loading