import merge from 'lodash/merge'
import get from 'lodash/get'
import React, {
    Fragment,
    useMemo,
    useState,
    useEffect,
    useCallback,
} from 'react'
import { JsonFormsDispatch, useJsonForms, withJsonFormsContext } from '@jsonforms/react'
import {
    composePaths,
    findUISchema,
    moveDown,
    moveUp,
    Resolve,
    update,
    getFirstPrimitiveProp,
    createId,
    removeId,
    toDataPath,
    getSubErrorsAt,
    formatErrorMessage,
} from '@jsonforms/core'
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Grid,
    IconButton,
    Button,
    Hidden
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowUpward from '@mui/icons-material/ArrowUpward'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import { resolveLinkData } from '../../../../../utils/json/schemas'
import { DeleteDialog } from '../shared/DeleteDialog'
import ValidationIcon from '../array/ValidationIcon'

const iconStyle = { float: 'right' }

const getDeleteDisabled = (uischema, data, childPath) => {
    if (uischema?.options?.disableDelete) {
        const scope = uischema.options.disableDelete.scope
        const values = uischema.options.disableDelete.values
        const targetPath = toDataPath(scope)
        const childData = Resolve.data(data, childPath)
        const targetValue = Resolve.data(childData, targetPath)
        if (values.includes(targetValue)) {
            return true
        }
    }
    return false
}

const ExpandPanelRendererComponent = (props) => {
    const [labelHtmlId] = useState(createId('expand-panel'))
    const [open, setOpen] = useState(false)
    const [deletePath, setDeletePath] = useState(undefined)
    const [rowData, setRowData] = useState(undefined)

    const openDeleteDialog = useCallback(
        (p, rowIndex) => {
            setOpen(true)
            setDeletePath(p)
            setRowData(rowIndex)
        },
        [setOpen, setDeletePath, setRowData]
    )
    const deleteCancel = useCallback(() => setOpen(false), [setOpen])
    const deleteConfirm = useCallback(() => {
        removeItems(deletePath, [rowData])()
        setOpen(false)
    }, [setOpen, deletePath, rowData])

    const deleteClose = useCallback(() => setOpen(false), [setOpen])

    useEffect(() => {
        return () => {
            removeId(labelHtmlId)
        }
    }, [labelHtmlId])

    const {
        childLabel,
        childPath,
        index,
        expanded,
        moveDown,
        moveUp,
        enableMoveDown,
        enableMoveUp,
        handleExpansion,
        removeItems,
        path,
        rootSchema,
        schema,
        uischema,
        uischemas,
        renderers,
        cells,
        config,
        enabled,
        deleteDisabled,
    } = props

    const foundUISchema = useMemo(
        () =>
            findUISchema(
                uischemas,
                schema,
                uischema.scope,
                path,
                undefined,
                uischema,
                rootSchema
            ),
        [uischemas, schema, path, uischema, rootSchema]
    )

    const ctx = useJsonForms()
    const childErrors = formatErrorMessage(getSubErrorsAt(childPath, schema)({...ctx.core, jsonforms: ctx}).map(e => e.message))
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const showSortButtons =
        appliedUiSchemaOptions.showSortButtons ||
        appliedUiSchemaOptions.showArrayLayoutSortButtons

    return (
        <Accordion
            aria-labelledby={labelHtmlId}
            expanded={expanded}
            onChange={handleExpansion(childPath)}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container alignItems={'center'}>
                    <Grid item xs={7} md={9}>
                        <Grid container alignItems={'center'}>
                            <Grid item xs={2} md={1}>
                                <Avatar aria-label="Index">{index + 1}</Avatar>
                            </Grid>
                            <Grid item xs={10} md={11}>
                                <span id={labelHtmlId}>{childLabel}</span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} md={3}>
                        <Grid container justifyContent="flex-end" paddingRight={'10px'}>
                            <Grid item>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Hidden smUp={childErrors.length === 0 || !enabled}>
                                            <Grid item>
                                                <ValidationIcon
                                                    id="tooltip-validation"
                                                    errorMessages={childErrors}
                                                />
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    {showSortButtons ? (
                                        <Fragment>
                                            <Grid item>
                                                <IconButton
                                                    onClick={moveUp(
                                                        path,
                                                        index
                                                    )}
                                                    style={iconStyle}
                                                    disabled={!enableMoveUp}
                                                    aria-label={`Move up`}
                                                    size="large"
                                                >
                                                    <ArrowUpward />
                                                </IconButton>
                                            </Grid>
                                            <Grid item>
                                                <IconButton
                                                    onClick={moveDown(
                                                        path,
                                                        index
                                                    )}
                                                    style={iconStyle}
                                                    disabled={!enableMoveDown}
                                                    aria-label={`Move down`}
                                                    size="large"
                                                >
                                                    <ArrowDownward />
                                                </IconButton>
                                            </Grid>
                                        </Fragment>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <JsonFormsDispatch
                    schema={schema}
                    uischema={foundUISchema}
                    path={childPath}
                    key={childPath}
                    renderers={renderers}
                    cells={cells}
                />
                 {(!deleteDisabled && enabled) && (
                    <Grid container alignItems='center' justifyContent='center' columns={12}>
                            <Button 
                                variant='outlined' 
                                endIcon={<DeleteIcon />}
                                onClick={() => openDeleteDialog(path, index)}
                            >
                                Delete Record
                            </Button>
                    </Grid>
                )}
            </AccordionDetails>
            <DeleteDialog
                open={open}
                onCancel={deleteCancel}
                onConfirm={deleteConfirm}
                onClose={deleteClose}
            />
        </Accordion>
    )
}

const ExpandPanelRenderer = React.memo(ExpandPanelRendererComponent)

/**
 * Maps state to dispatch properties of an expand pandel control.
 *
 * @param dispatch the store's dispatch method
 * @returns {DispatchPropsOfArrayControl} dispatch props of an expand panel control
 */
export const useCtxDispatchToExpandPanelProps = dispatch => ({
    removeItems: useCallback(
        (path, toDelete) => () => {
            // event.stopPropagation()
            dispatch(
                update(path, (array) => {
                    toDelete
                        .sort()
                        .reverse()
                        .forEach((s) => array.splice(s, 1))
                    return array
                })
            )
        },
        [dispatch]
    ),
    moveUp: useCallback(
        (path, toMove) => (event) => {
            event.stopPropagation()
            dispatch(
                update(path, (array) => {
                    moveUp(array, toMove)
                    return array
                })
            )
        },
        [dispatch]
    ),
    moveDown: useCallback(
        (path, toMove) => (event) => {
            event.stopPropagation()
            dispatch(
                update(path, (array) => {
                    moveDown(array, toMove)
                    return array
                })
            )
        },
        [dispatch]
    ),
})

/**
 * Map state to control props.
 * @param state the JSON Forms state
 * @param ownProps any own props
 * @returns {StatePropsOfControl} state props for a control
 */
export const withContextToExpandPanelProps =
    (Component) =>
    ({ ctx, props }) => {
        const dispatchProps = useCtxDispatchToExpandPanelProps(ctx.dispatch)
        const { childLabelProp, schema, path, index, uischemas, uischema } = props
        const state = ctx.core
        const { data } = state
        
        const childLabelPropParts = childLabelProp ? childLabelProp.split('-') : []
        let childLabel = ''
        const childPath = composePaths(path, `${index}`)
        if (childLabelPropParts.length > 1) {
            const childLabelParts = childLabelPropParts.map(x => {
                let childData = (x && x.split('.').length > 1) 
                    ? resolveLinkData(ctx.core.data, path)[index] 
                    : Resolve.data(ctx.core.data, childPath)
                return x
                    ? get(childData, x, '')
                    : get(childData, getFirstPrimitiveProp(schema), '')
            })
            childLabel = childLabelParts.filter(x => !!x).join(' - ')
        } else {
            const childData = (childLabelProp && childLabelProp.split('.').length > 1) ? resolveLinkData(ctx.core.data, path)[index] : Resolve.data(ctx.core.data, childPath)
            childLabel = childLabelProp
                ? get(childData, childLabelProp, '')
                : get(childData, getFirstPrimitiveProp(schema), '')
        }

        const deleteDisabled = getDeleteDisabled(uischema, data, childPath)

        return (
            <Component
                {...props}
                {...dispatchProps}
                deleteDisabled={deleteDisabled}
                childLabel={childLabel}
                childPath={childPath}
                uischemas={uischemas}
            />
        )
    }

export const withJsonFormsExpandPanelProps = (Component) =>
    withJsonFormsContext(withContextToExpandPanelProps(Component))

export default withJsonFormsExpandPanelProps(ExpandPanelRenderer)
