import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef
} from 'react'
import isEqual from 'lodash/isEqual'

import { useParams, useNavigate } from 'react-router'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getInvoice, getInvoiceSchema, createInvoice, updateInvoice, getInvoiceAutoCalc } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { parseSchemaObjects, separatePropertiesBasedOnSchemaType } from '../../../utils/json/schemas'

// TODO: Testing, replace with api call later
import { invoiceSchema } from '../../../utils/testing/schemas'
import urls from '../../../utils/constants/urls'
import { debounce } from '@material-ui/core'
import { checkFormErrors, checkEditingChild } from '../../../utils/form/errors'
import titles, { constructFormTitle } from '../../../utils/constants/titles'


const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [invoice, setInvoice] = useState(null)
    const [initialState, setInitialState] = useState(null)
    const [schemas, setSchemas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [editing, setEditing] = useState(false)
    const [errors, setErrors] = useState([])
    const [additionalErrors, setAdditionalErrors] = useState([])
    const params = useParams()
    const [editingChild, setEditingChild] = useState(false)
    const [invoiceTitle, setInvoiceTitle] = useState(null)
    
    const navigate = useNavigate()
    const invoiceId = useMemo(() => params.invoiceId, [params])
    const beforeAutoCalc = useRef({})

    // when the invoice changes, submit the data to the auto calc endpoint
    // and set the response if we're editing
    const [autoCalcAbortController, setAutoCalcAbortController] = useState(new AbortController())
    const autoCalculateInputs = useCallback(() => {
        autoCalcAbortController.abort()
        if (!isEqual(beforeAutoCalc.current, invoice) && editing) {
            if (invoice && invoice.invoice) {
                const abortController = new AbortController()
                setAutoCalcAbortController(abortController)
                getInvoiceAutoCalc(authenticatedFetch, API_URL, invoice.invoice, abortController, (d) => {
                    if (d) {
                        const newInvoice = ({
                            ...invoice.invoice,
                            ...d
                        })
                        beforeAutoCalc.current = { invoice: newInvoice }
                        setInvoice({invoice: newInvoice})
                    }
                })
            }
        }
    }, [invoice, setInvoice, beforeAutoCalc, editing, autoCalcAbortController])

    useEffect(() => {
        debounce(autoCalculateInputs(), 1500)
    }, [invoice, editing])

    const resetInvoice = useCallback((d) => {
        const data = parseJsonAttributes(d)
        setInvoice({invoice: data})
        setInvoiceTitle(constructFormTitle(data, titles.invoice))
        setInitialState(data)
    }, [setInitialState, setInvoice])

    useEffect(() => {
        if (invoiceId) {
            getInvoice(authenticatedFetch, API_URL, invoiceId, (d) => {
                resetInvoice(d)
                setEditing(false)
            })
        } else {
            resetInvoice({})
            setEditing(true)
        }
    }, [invoiceId, resetInvoice, API_URL, authenticatedFetch])

    useEffect(() => {
        // getBidPackageSchema(authenticatedFetch, API_URL, (s) => {
        //     const parsedSchemas = parseSchemaObjects(s)
        //     setSchemas(parsedSchemas)
        // })

        // TODO: Update after testing
        setSchemas(invoiceSchema)
    }, [])

    useEffect(() => {
        if (schemas && invoice) {
            setLoading(false)
        }
    }, [schemas, invoice])

    const saveForm = useCallback(() => {
        setSubmitting(true)
        checkEditingChild(editingChild).then(() => checkFormErrors(errors, additionalErrors)).then(() => {
            const data = separatePropertiesBasedOnSchemaType(invoice.invoice, schemas)
            // if id already exists, it is an update
            if (invoice.invoice.invoiceId) {
                updateInvoice(authenticatedFetch, API_URL, data, (res) => {
                    resetInvoice(res.data)
                    setEditing(false)
                    setSubmitting(false)
                })
            } else {
                createInvoice(authenticatedFetch, API_URL, data, (res) => {
                    setSubmitting(false)
                    setEditing(false)
                    navigate(`${urls.invoice}/${res}`)
                })
            }
        })
        .catch(() => setSubmitting(false))
    }, [invoice, schemas, errors, additionalErrors, editingChild, resetInvoice, authenticatedFetch, API_URL, navigate])

    const resetForm = useCallback((cancel) => {
        if (cancel && !(initialState && initialState.invoiceId)) {
            navigate(urls.invoices)
        } else {
            autoCalcAbortController.abort()
            setInvoice({ invoice: initialState })
        }
    }, [initialState, autoCalcAbortController])

    return (
        <DataContext.Provider
            value={{
                invoice,
                setInvoice,
                schemas,
                loading,
                errors,
                setErrors,
                additionalErrors, 
                setAdditionalErrors,
                setEditingChild,
                saveForm,
                resetForm,
                editing,
                setEditing,
                submitting,
                invoiceTitle
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
