import dayjs from 'dayjs'

const getExportFileName = (exportId, exportFilePrefix) => {
    if (exportId && exportFilePrefix) {
        const today = dayjs().format('YYYYMMDD')
        return `${exportId}_${exportFilePrefix}_${today}`
    }
    if (exportFilePrefix) {
        const today = dayjs().format('YYYYMMDD')
        return `${exportFilePrefix}_${today}`
    }
    return undefined
}

export { getExportFileName }