import React, { memo, useState } from 'react'
import TextField from '@mui/material/TextField'
import { fonts } from '../../../theme/base'
import { TableCell, Button } from '@mui/material'

const getNumbers = (value) => value.replace(/[^\d]+/g, '')

const Pagination = ({ gotoPage, pages, style }) => {
    const [value, setValue] = useState('')

    const handleSubmit = () => {
        let newValue = getNumbers(value)
        if (newValue > 1 && newValue <= pages) {
            gotoPage(newValue - 1)
        }
    }

    return (
        <TableCell style={style}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    height: '100%',
                    alignItems: 'center',
                    marginRight: 30,
                }}
            >
                Page:
                <TextField
                    type="number"
                    variant="standard"
                    size="small"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    InputProps={{
                        style: {
                            fontFamily: fonts.normal,
                            fontSize: 14,
                            paddingLeft: 2,
                        },
                    }}
                    sx={{
                        width: 80,
                        marginLeft: 0,
                        marginRight: 2,
                        paddingLeft: 2,
                    }}
                />
                <Button
                    size="small"
                    onClick={handleSubmit}
                    variant="outlined"
                    color="primary"
                >
                    Go
                </Button>
            </div>
        </TableCell>
    )
}

export default memo(Pagination)
