import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getInvoices, getInvoiceListMetadata } from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import { gridConfig as testConfig } from '../../../utils/testing/config' // TODO: replace with api requested data
import urls, { getCreateLink } from '../../../utils/constants/urls'
import { removeEmptyParameters } from '../../../utils/queryParameters'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
  const { API_URL } = config
  const { authenticatedFetch } = useContext(APIRequestContext)
  const [invoices, setInvoices] = useState([])
  const [loading, setLoading] = useState(true)
  const [gridConfigExpanded, setGridConfigExpanded] = useState(false)
  const [gridConfig, setGridConfig] = useState(testConfig)
  const createLink = useMemo(() => getCreateLink(urls.invoice), [])
  const [queryParameters, setQueryParameters] = useState({
      page: 0,
      pageSize: 25,
  })
  const [listMetadata, setListMetadata] = useState({ count: 0, pages: 0 })

  const updateQueryParameters = useCallback(
      (newParameters) => {
          setQueryParameters((prevParameters) => {
              const mergedParams = {
                  ...prevParameters,
                  ...newParameters,
              }
              return removeEmptyParameters(mergedParams)
          })
      },
      [setQueryParameters]
  )

  const fetchInvoiceListMetadata = useCallback((queryParameters) => new Promise((resolve, reject) => {
      try {
          getInvoiceListMetadata(
              authenticatedFetch,
              API_URL,
              queryParameters,
              (res) => {
                  setListMetadata(res)
                  return resolve()
              }
          )
      } catch (e) {
          return reject()
      }
  }), [])

  const fetchInvoices = useCallback(
      (queryParameters) => new Promise((resolve, reject) => {
          try {
              getInvoices(
                  authenticatedFetch,
                  API_URL,
                  queryParameters,
                  (res) => {
                      const parsed = res.map((p) => parseJsonAttributes(p))
                      setInvoices(parsed)
                      return resolve()
                  }
              )
          } catch (e) {
              return reject()
          }
      }),
      [authenticatedFetch, API_URL]
  )

  // when query parameters change, update table data
  useEffect(() => {
      const updateTableData = async () => {
          setLoading(true) 
          try {
              await fetchInvoices(queryParameters)
              await fetchInvoiceListMetadata(queryParameters)
              setLoading(false)
          } catch (e) {
              setLoading(false)
          }
      }
      updateTableData()
  }, [queryParameters])

  return (
      <DataContext.Provider
          value={{
              invoices,
              updateQueryParameters,
              queryParameters,
              listMetadata,
              loading,
              gridConfig,
              gridConfigExpanded,
              setGridConfigExpanded,
              createLink,
          }}
      >
          {children}
      </DataContext.Provider>
  )
})

export { DataContext }
export default DataContextProvider
