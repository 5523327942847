import React, { useCallback } from 'react'

import { isObjectArrayWithNesting, rankWith } from '@jsonforms/core'
import { Hidden } from '@mui/material'
import { MaterialArrayLayout } from './MaterialArrayLayout'
import { withJsonFormsArrayLayoutProps } from '@jsonforms/react'

export const MaterialArrayLayoutRenderer = ({ visible, addItem, ...props }) => {
    const addItemCb = useCallback((p, value) => addItem(p, value), [addItem])
    return (
        <Hidden xsUp={!visible}>
            <MaterialArrayLayout
                visible={visible}
                addItem={addItemCb}
                {...props}
            />
        </Hidden>
    )
}

export const materialArrayLayoutTester = rankWith(5, isObjectArrayWithNesting)
export default withJsonFormsArrayLayoutProps(MaterialArrayLayoutRenderer)
