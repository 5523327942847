import React, { useContext, useState } from 'react'
import { UserContext } from '../../wrappers/UserContext'
import urls from '../../../utils/constants/urls'
import { hasAccessToAdmin } from '../../../utils/user/permissions'
import withConfig from '../../wrappers/withConfig'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons'
import { FormStateContext } from '../../wrappers/FormStateContext'

const Burger = ({ expanded, setExpanded }) => {
    return (
        <a
            onClick={() => setExpanded(!expanded)}
            role="button"
            className={`navbar-burger ${expanded ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded="false"
            data-target="navbar-menu"
        >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    )
}
const NavBar = withConfig(({ config, returnUrl, title }) => {
    const { client, roles } = useContext(UserContext)
    const { setBreadCrumbList } = useContext(FormStateContext)
    const { CONFIGURED_URLS } = config
    const [burgerExpanded, setBurgerExpanded] = useState(false)

    return (
        <nav
            className="navbar is-transparent is-fixed-top"
            role="navigation"
            aria-label="main navigation"
        >
            <div className="navbar-brand">
                {returnUrl ? (
                    <div className="navbar-item">
                        <Button
                            component={Link}
                            to={returnUrl}
                            color={'primary'}
                            startIcon={<ArrowBack />}
                            onClick={
                                () => { //clears breadCrumbList from state and sessionStorage
                                    window.sessionStorage.removeItem("breadCrumbList")
                                    setBreadCrumbList([])
                                }
                            }
                        >
                            Back
                        </Button>
                    </div>
                ) : null}
                <div className="navbar-item">
                    <Button component={Link} to={urls.home}>
                        {title}
                    </Button>
                </div>
                <div className="navbar-item">
                    <Button href={CONFIGURED_URLS.dashboard} color="primary" target='_blank' rel='noreferrer'>
                        {config.DASHBOARD_LINK_TEXT}
                    </Button>
                </div>
                <Burger
                    expanded={burgerExpanded}
                    setExpanded={setBurgerExpanded}
                />
            </div>
            <div
                id="navbar-menu"
                className={`navbar-menu ${burgerExpanded ? 'is-active' : ''}`}
            >
                <div className="navbar-end">
                    {roles && hasAccessToAdmin(roles) && (
                        <div className="navbar-item">
                            <Button
                                href={CONFIGURED_URLS.manageUsers}
                                target="_blank"
                                rel="noreferrer"
                                variant="text"
                            >
                                Manage Users
                            </Button>
                        </div>
                    )}
                    <div className="navbar-item">
                        <Button
                            variant="text"
                            href={CONFIGURED_URLS.changePassword}
                            target="_blank" 
                            rel="noreferrer"
                        >
                            Change Password
                        </Button>
                    </div>
                    <div className="navbar-item">
                        <Button
                            variant="text"
                            onClick={function () {
                                client.signoutRedirect()
                            }}
                        >
                            Logout
                        </Button>
                    </div>
                </div>
            </div>
        </nav>
    )
})

export default NavBar
