import React, { useContext } from 'react'
import urls from '../../../utils/constants/urls'

import Form from '../../elem/form/Form'
import FormWrapper from '../../elem/form/FormWrapper'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const FacilityGroup = () => {
    const { facilityGroup, setFacilityGroup, schemas, editing, setEditing, errors, setErrors, saveForm, resetForm } = useContext(DataContext)
    
    return (
        <PageLayout returnUrl={urls.projects}>
            <FormWrapper title="Bid Package" editing={editing}  setEditing={setEditing} errors={errors} saveForm={saveForm} resetForm={resetForm} >
                <Form name='facilityGroup' data={facilityGroup} schemas={schemas} setData={setFacilityGroup} errors={errors} setErrors={setErrors} editing={editing} />
            </FormWrapper>
        </PageLayout>
    )

}

export default FacilityGroup