import React from 'react'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { Hidden } from '@mui/material'
import Lookup from '../../lookup/Lookup'

const LookupControlRenderer = (props) => {
    const { visible } = props
    return (
        <Hidden xsUp={!visible}>
            <Lookup {...props} />
        </Hidden>
    )
}

export default withJsonFormsControlProps(LookupControlRenderer)
