import { removeEmptyParameters } from "../../queryParameters"

const constructQueryParams = (queryObject) => {
    const { filters, orderBy, orderDirection, page, pageSize, search, totalCount } = queryObject
    const filterParams = filters.reduce((acc, curr) => {
        const { value, column: { field }, operator } = curr
        return {
            ...acc,
            [field]: value
        }
    }, {})

    const queryParams = {
        orderBy: orderBy ? orderBy.field : null,
        orderDirection,
        page,
        pageSize,
        search, 
        ...filterParams
    }
    return removeEmptyParameters(queryParams)
}

export { constructQueryParams }