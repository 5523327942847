import React from 'react'
import dayjs from 'dayjs'
import CellWrapper from './CellWrapper'

const format = 'MM/DD/YYYY'
const dateToString = (date) =>
    date ? dayjs(date).format(format).toString() : null

const DateCell = ({value}) => {
    return <CellWrapper>{dateToString(value)}</CellWrapper>
}

export default DateCell