import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef
} from 'react'
import isEqual from 'lodash/isEqual'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import {
    parseSchemaObjects,
    getMergedSchema,
    separatePropertiesBasedOnSchemaType,
} from '../../../../utils/json/schemas'
import { parseJsonAttributes } from '../../../../utils/json/attributes'
import { createLinkRecord, getLinkRecord, getLinkSchema, getSelectList, updateLinkRecord } from './actions'
import { ParentFormContext } from '../../../wrappers/WithParentFormContext'
import { landownerSchema } from '../../../../utils/testing/schemas'
import { Resolve, errorAt, getErrorAt, resolveData, toDataPath } from '@jsonforms/core'

const DataContext = createContext(null)

const SelectOrCreateDataContext = withConfig(
    ({
        config,
        dataSourceUrl,
        name,
        parentData,
        setData,
        targetIdColumn,
        linkIdColumn,
        displayColumn,
        configName,
        core,
        existingLinkData,
        disableEditing,
        path,
        children,
    }) => {
        const { setEditingChild } = useContext(ParentFormContext)
        const [isLinkDataLoading, setIsLinkDataLoading] = useState(true)
        const [isSelectListLoading, setIsSelectListLoading] = useState(true)
        const [linkData, setLinkData] = useState([])
        const [linkSchemas, setLinkSchemas] = useState(null)
        const [availableData, setAvailableData] = useState([])
        const [existingData, setExistingData] = useState(null)
        const [existingLinkId, setExistingLinkId] = useState(null)
        const [isCreating, setIsCreating] = useState(false)
        const [isEditing, setIsEditing] = useState(false)
        const { authenticatedFetch } = useContext(APIRequestContext)
        const { API_URL } = config
        const rootPath = useMemo(() => (path ? path.split('.')[0] : ''), [path])
        const beforeLinkData = useRef({})
        const [linkDataAbortController, setLinkDataAbortController] = useState(new AbortController)

        const canEdit = useMemo(() => {
            if (existingData) {
                if (disableEditing) {
                    const data = resolveData(existingData, toDataPath(disableEditing.scope))
                    const matchesSchema = core.ajv.validate(disableEditing.schema, data)
                    return !matchesSchema
                }
                return true
            }
            return false
        }, [existingData, disableEditing, core])

        // reusable function for fetching new link candidates
        const fetchLinkCandidates = useCallback(() => {
            setIsSelectListLoading(true)
            getSelectList(authenticatedFetch, API_URL, dataSourceUrl, (data) => {
                setLinkData(data)
                setIsSelectListLoading(false)
            })
        }, [authenticatedFetch, API_URL, dataSourceUrl, setLinkData, setIsSelectListLoading])

        // get all possible link candidates
        useEffect(() => {
            fetchLinkCandidates()
        }, [])

        // when all possible link candidates load, set availableData
        useEffect(() => {
            setAvailableData(linkData)
        }, [linkData, displayColumn, linkIdColumn])

        // get the schema for the link candidates in the grid
        useEffect(() => {
            // // TODO: Fix this when we implement schemas in the db
            // getLinkSchema(authenticatedFetch, API_URL, dataSourceUrl, (s) => {
            //     const parsedSchemas = parseSchemaObjects(s)
            //     setLinkSchemas(parsedSchemas)
            // })

            // // NOTE: this is for testing only, revert to db objects when complete
            setLinkSchemas(landownerSchema)
        }, [])

        // merge the schema together
        const schema = useMemo(
            () => (linkSchemas ? getMergedSchema(name, linkSchemas) : null),
            [linkSchemas]
        )

        // get the ui schema
        const uischema = useMemo(
            () => (linkSchemas ? linkSchemas.uiSchema : {}),
            [linkSchemas]
        )

        // when there is existing link data, set the current 
        // link id to be that of the associated id
        useEffect(() => {
            if (existingLinkData && existingLinkData[0]) {
                setExistingLinkId(existingLinkData[0][linkIdColumn])
            } else {
                setExistingLinkId(null)
            }
        }, [existingLinkData])

        // when the existingLinkId changes, 
        // fetch the associated data
        useEffect(() => {
            if (!isEqual(beforeLinkData.current, existingLinkId)) {
                beforeLinkData.current = existingLinkId
                linkDataAbortController.abort()
                if (existingLinkId != null) {
                    const abortController = new AbortController()
                    setLinkDataAbortController(abortController)
                    setIsLinkDataLoading(true)
                    getLinkRecord(authenticatedFetch, API_URL, dataSourceUrl, existingLinkId, abortController, (d) => {
                        const data = parseJsonAttributes(d)
                        setExistingData(data)
                        setIsLinkDataLoading(false)
                    })
                } else {
                    setIsLinkDataLoading(false)
                    setExistingData(null)
                }
            }
        }, [existingLinkId, beforeLinkData, setIsSelectListLoading, parentData, linkDataAbortController])

        const handleSelectChange = useCallback(
            (evt, data) => {
                const selectedLinkId = data.id
                const parentId = parentData[rootPath][targetIdColumn]
                const newData = [
                    {
                        [targetIdColumn]: parentId,
                        [linkIdColumn]: selectedLinkId,
                    },
                ]
                setData(newData)
            },
            [linkData, parentData, rootPath]
        )

        const saveFormData = useCallback(
            (data) => {
                // send a request to the api to create the new record
                const createdData = separatePropertiesBasedOnSchemaType(
                    data[name],
                    linkSchemas
                )
                
                if (createdData[linkIdColumn] != null) {
                    updateLinkRecord(
                        authenticatedFetch,
                        API_URL,
                        dataSourceUrl,
                        createdData,
                        (d) => {
                            const data = parseJsonAttributes(d)
                            setExistingData(data)
                            fetchLinkCandidates()
                        }
                    )
                    setIsEditing(false)
                } else {
                    createLinkRecord(
                        authenticatedFetch,
                        API_URL,
                        dataSourceUrl,
                        createdData,
                        (createdId) => {
                            // afterward, assign the record to this form
                            const parentId = parentData[rootPath][targetIdColumn]
                            const newData = [
                                {
                                    [targetIdColumn]: parentId,
                                    [linkIdColumn]: createdId,
                                },
                            ]
                            setData(newData)
                            fetchLinkCandidates()
                        }
                    )
                    setIsCreating(false)
                }
            },
            [
                linkSchemas,
                name,
                createLinkRecord,
                authenticatedFetch,
                dataSourceUrl,
                parentData,
                rootPath,
                targetIdColumn,
                setData,
            ]
        )

        // when the record is being created, flag the parent data context to indicate
        // that saving should be disabled
        useEffect(() => {
            setEditingChild(isCreating || isEditing)
        }, [setEditingChild, isCreating, isEditing])

        // when the parent data changes, 
        useEffect(() => {
            setIsCreating(false)
            setIsEditing(false)
        }, [parentData])

        return (
            <DataContext.Provider
                value={{
                    name,
                    isSelectListLoading,
                    isLinkDataLoading,
                    schema,
                    isCreating,
                    setIsCreating,
                    handleSelectChange,
                    saveFormData,
                    availableData,
                    uischema,
                    existingData,
                    targetIdColumn,
                    linkIdColumn,
                    displayColumn,
                    canEdit,
                    isEditing, 
                    setIsEditing
                }}
            >
                {children}
            </DataContext.Provider>
        )
    }
)

export { DataContext }

export default SelectOrCreateDataContext
