import React, { useCallback } from 'react'

import SelectOrCreateDataContextProvider, { DataContext } from './SelectOrCreateDataContext'
import { update } from '@jsonforms/core'
import { useJsonForms } from '@jsonforms/react'
import Display from './Display'
import SelectOrCreateControl from './SelectOrCreateControl'

const SelectOrCreate = (props) => {
    const { data, path, uischema, visible, label } = props
    const ctx = useJsonForms()
    const {
        dispatch,
        core,
        core: {
            data: parentData
        }
    } = ctx

    const setData = useCallback(
        (updatedData) => {
            dispatch(update(path, () => updatedData))
        },
        [data, path, dispatch]
    )

    return (
        <SelectOrCreateDataContextProvider
            existingLinkData={data}
            setData={setData}
            parentData={parentData}
            {...uischema}
            core={core}
            path={path}
        >
            <SelectOrCreateControl {...props} />
            <Display {...props} />
        </SelectOrCreateDataContextProvider>
    )
}

export default SelectOrCreate
