import merge from 'lodash/merge'
import React, { useEffect, useMemo, useState } from 'react'
import { isDescriptionHidden, isDateControl, rankWith } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FormControl, FormHelperText, Hidden, TextField } from '@mui/material'
import { LocalizationProvider } from '@mui/lab'
import AdapterDayjs from '@mui/lab/AdapterDayjs'
import {
    createOnChangeHandler,
    getData,
    useFocus,
} from '@jsonforms/material-renderers'
import dayjs from 'dayjs'

export const dateControlTester = rankWith(5, isDateControl)

export const MaterialDateControl = (props) => {
    const [focused] = useFocus()
    const {
        description,
        errors,
        label,
        uischema,
        visible,
        enabled,
        required,
        path,
        handleChange,
        data,
        config,
    } = props
    const isValid = errors.length === 0
    const appliedUiSchemaOptions = merge({}, config, uischema.options)
    const [value, setValue] = useState(null)
    const showDescription = !isDescriptionHidden(
        visible,
        description,
        focused,
        appliedUiSchemaOptions.showUnfocusedDescription
    )
    const format = appliedUiSchemaOptions.dateFormat ?? 'MM/DD/YYYY'
    const saveFormat = appliedUiSchemaOptions.dateSaveFormat ?? 'YYYY-MM-DD'

    const firstFormHelperText = showDescription
        ? description
        : !isValid
        ? errors
        : null
    const secondFormHelperText = showDescription && !isValid ? errors : null
    const onChange = useMemo(
        () => createOnChangeHandler(path, handleChange, saveFormat),
        [path, handleChange, saveFormat]
    )

    useEffect(() => {
        if (data) {
            const dataVal = getData(data, saveFormat)
            const displayVal = dataVal
                ? dataVal.format(saveFormat).toString()
                : null
            setValue(displayVal)
        }
        if (data === null || data === undefined ) {
            setValue('')
        }
    }, [data, saveFormat])

    return (
        <Hidden xsUp={!visible}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FormControl fullWidth>
                    {enabled ? (
                        <TextField
                            label={label}
                            type="date"
                            value={value === null ? '' : value}
                            required={required}
                            disabled={!enabled}
                            onChange={(evt) => setValue(evt.target.value)}
                            // onChange={(evt) => {
                            //     let val = evt.target.value
                            //     // the default format yyyy-mm-dd
                            //     let yearPart = val.split('-')[0]
                            //     console.log(yearPart, val);
                            //     if (yearPart.length >= 4) {
                            //         return
                            //     }
                            //     setValue(evt.target.value)
                            //     setValue(evt.target.value)
                            // }}
                            onFocus={(evt) => onChange(evt.target.value)}
                            onBlur={(evt) => onChange(evt.target.value)}
                            InputLabelProps={{ shrink: true }}
                            // limits the amount of characters you can enter in the year part to 4 chars
                            inputProps={{
                                inputProps: {
                                    min: '01/01/0001',
                                    max: '12/31/9999',
                                },
                            }}
                            variant="standard"
                            fillWidth={true}
                            error={!isValid}
                        />
                    ) : (
                        <TextField
                            label={label}
                            type="text"
                            value={value ? dayjs(value).format(format) : ''}
                            disabled={!enabled}
                            InputLabelProps={data ? { shrink: true } : null}
                            variant="standard"
                            fillWidth={true}
                        />
                    )}
                </FormControl>
                <FormHelperText error={!isValid && !showDescription}>
                    {firstFormHelperText}
                </FormHelperText>
                <FormHelperText error={!isValid}>
                    {secondFormHelperText}
                </FormHelperText>
            </LocalizationProvider>
        </Hidden>
    )
}

export default withJsonFormsControlProps(MaterialDateControl)
