import React, { useContext, useMemo } from 'react'
import urls from '../../../utils/constants/urls'
import Grid from '../../elem/grid/display/Grid'
import PageLayout from '../../elem/Layout'
import { DataContext } from './DataContext'

const BidResponses = () => {
    const { listMetadata, updateQueryParameters, queryParameters, bidResponses, loading, gridConfig, gridConfigExpanded, createLink } = useContext(DataContext)

    return (
        <PageLayout returnUrl={urls.home}>
                <Grid
                    metaData={listMetadata}
                    updateQueryParameters={updateQueryParameters}
                    queryParameters={queryParameters}
                    data={bidResponses}
                    gridConfig={gridConfig}
                    name={'bidResponses'}
                    loading={loading}
                    gridConfigExpanded={gridConfigExpanded}
                    createLink={createLink}
                />
        </PageLayout>
    )
}

export default BidResponses
