import React from 'react'
import ParentFormContextProvider from '../../wrappers/WithParentFormContext'
import BreadCrumbLink from './BreadCrumbLink'
import FormControls from './FormControls'

const FormWrapper = ({
    title,
    subTitle,
    editing,
    setEditing,
    errors,
    loading,
    saveForm,
    resetForm,
    children,
    data,
    schemas,
    parentContext,
}) => {
    return (
        <div className="container">
            <BreadCrumbLink />
            <div className="level">
                <div className="level-left">
                        <div className="level-item title">{title}{subTitle ? ' - ' : ''}</div>
                        {subTitle ? <div className="level-item subtitle">{subTitle}</div> : null}
                </div>
                <div className="level-right">
                    <FormControls
                        editing={editing}
                        setEditing={setEditing}
                        data={data}
                        schemas={schemas}
                        loading={loading}
                        errors={errors}
                        saveForm={saveForm}
                        resetForm={resetForm}
                    />
                </div>
            </div>
            <ParentFormContextProvider parentContext={parentContext}>
                {children}
            </ParentFormContextProvider>
        </div>
    )
}

export default FormWrapper
