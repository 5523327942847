import { styled } from '@mui/material/styles'
import { TableCell } from '@mui/material'
import React from 'react'

const StyledTableCell = styled(TableCell)({
    borderBottom: 'none',
})

const NoBorderTableCell = ({ children, ...otherProps }) => (
    <StyledTableCell {...otherProps}>{children}</StyledTableCell>
)

export default NoBorderTableCell
