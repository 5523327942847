import { _ } from "ajv"

const defaultZipLength = 5
const isValidZipCode = {
    keyword: "isValidZipCode",
    validate: (schema, data, parentSchema) => {
        let zipLength = defaultZipLength
        if (parentSchema?.zipCodeLength) {
            zipLength = parentSchema.zipCodeLength
        }
        let validZipString = `(^[0-9]{${zipLength}}$)`
        const isValidZip = new RegExp(validZipString, 'g').test(data);
        return isValidZip
    },
    error: {
        message: ({ parentSchema }) => {
            return `must be valid Zip Code number with ${parentSchema?.zipCodeLength ? parentSchema.zipCodeLength : defaultZipLength} digits`
        }
    }
}

export default [isValidZipCode]