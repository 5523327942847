import React, { createContext, useEffect, useState, useMemo } from 'react'
import withConfig from '../../../wrappers/withConfig'
import { gridConfig } from '../../../../utils/testing/config'
import {
    getColumns,
    getGridColumns,
} from '../../../../utils/grids/link/columns'
import {
    getAttributeColumnsFromGridConfig,
    getBaseColumnsFromGridConfig,
} from '../../../../utils/grids/display/config'
import { resolveLinkData } from '../../../../utils/json/schemas'

const DataContext = createContext(null)

const DisplayLinkDataContextProvider = withConfig(
    ({
        configName,
        resolvePath,
        existingLinkData,
        linkIdColumn,
        parentData,
        path,
        children,
    }) => {
        const [isLoading, setIsLoading] = useState(true)
        const [linkData, setLinkData] = useState([])
        const rootPath = useMemo(() => (path ? path.split('.')[0] : ''), [path])
        const exportId = useMemo(() => parentData[rootPath][linkIdColumn], [parentData, rootPath, linkIdColumn])
        
        // get link data from the parent based on path
        useEffect(() => {
            if (existingLinkData && existingLinkData.length) {
                const data = existingLinkData ? resolveLinkData(existingLinkData, resolvePath).flatMap(x => x) : []
                setLinkData(data)
            }
            setIsLoading(false)
        }, [existingLinkData])

        // create columns for the first attempt: ExistingRows, etc
        // NOTE: remove later
        const columns = useMemo(() => {
            const baseColumns = getBaseColumnsFromGridConfig(
                configName,
                gridConfig
            )
            const attributeColumns = getAttributeColumnsFromGridConfig(
                configName,
                gridConfig
            )
            return getColumns(baseColumns, attributeColumns)
        }, [gridConfig, configName])

        // create columns for the second attempt: Material Table React
        // Note: Does not allow for parsing column names that have
        // spaces, fix later
        const gridColumns = useMemo(() => {
            const baseColumns = getBaseColumnsFromGridConfig(
                configName,
                gridConfig
            )
            const attributeColumns = getAttributeColumnsFromGridConfig(
                configName,
                gridConfig
            )
            return getGridColumns(baseColumns, attributeColumns)
        }, [gridConfig, configName])

        return (
            <DataContext.Provider
                value={{ isLoading, linkData, columns, gridColumns, exportId }}
            >
                {children}
            </DataContext.Provider>
        )
    }
)

export { DataContext }

export default DisplayLinkDataContextProvider
