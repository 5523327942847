import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../../elem/Layout'
import urls from '../../../utils/constants/urls'
import Button from '@mui/material/Button'
import { Container } from './styled'

const LandingPage = () => {
    const featureLinks = urls.features
    return (
        <Container>
            <Layout>
                <div className="section">
                    <div className="container landingPageWrapper">
                        <div className="columns is-horizontal is-multiline is-centered">
                            {featureLinks.map((link, idx) => (
                                <div key={idx}>
                                    <Button component={Link} to={link.url} variant="outlined" sx={{ fontSize: '1.2rem', width: 200, padding: 2, margin: 2, }}>{link.name}</Button>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Layout>
        </Container>
    )
}

export default LandingPage
