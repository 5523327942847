import React, { useCallback, useContext, useMemo } from 'react'
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Hidden,
    Tab,
    Tabs,
} from '@mui/material'
import { TabPanel, TabContext } from '@mui/lab'
import CreateControl from './CreateControl'
import SelectControl from './SelectControl'
import { DataContext } from './SelectOrCreateDataContext'

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const SelectOrCreateControl = (props) => {
    const { isCreating, setIsCreating } = useContext(DataContext)
    const { enabled, label } = props

    // FOR CARD TABS VARIANT
    const tabValue = useMemo(
        () => (isCreating ? 'create' : 'select'),
        [isCreating]
    )
    const handleTabChange = useCallback(
        (event, newValue) => {
            const newCreateValue = newValue === 'select' ? false : true
            setIsCreating(newCreateValue)
        },
        [setIsCreating]
    )

    return (
        <Hidden xsUp={!enabled}>
            {/* CARD TAB ACTIONS VARIANT */}
            <Card sx={{ width: '100%' }} style={{ marginBottom: '10px' }}>
                <CardHeader title={label} />
                <CardContent>
                    <TabContext value={tabValue}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleTabChange}
                                aria-label="select or create tabs"
                            >
                                <Tab
                                    label="Select"
                                    value="select"
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label="Create"
                                    value="create"
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value="select">
                            <SelectControl {...props} />
                        </TabPanel>
                        <TabPanel value="create" sx={{padding: 0}}>
                            <CreateControl {...props} />
                        </TabPanel>
                    </TabContext>
                </CardContent>
            </Card>
        </Hidden>
    )
}

export default SelectOrCreateControl
