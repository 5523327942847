import { apiUrls } from '../../../../utils/constants/urls'
import toast from '../../Toast'

const getAvailableData = (authFetch, API_URL, dataSourceUrl, linkedIds, filterByLinkId, parentId, queryParams, after) => {
    const queryString = new URLSearchParams(queryParams).toString()
    const requestUrl = filterByLinkId ? `${API_URL}/${dataSourceUrl}/getAvailable/${parentId}?${queryString}` : `${API_URL}/${dataSourceUrl}/getAvailable?${queryString}`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(linkedIds)
    }  
    return authFetch(
        requestUrl,
        POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Link Data: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}


const getLinkedData = (authFetch, API_URL, dataSourceUrl, linkedIds, filterByLinkId, parentId, after) => {
    const requestUrl = filterByLinkId ? `${API_URL}/${dataSourceUrl}/getLinked/${parentId}` : `${API_URL}/${dataSourceUrl}/getLinked`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(linkedIds)
    }  
    return authFetch(
        requestUrl,
        POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Link Data: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}


const getLinkSchema = (authFetch, API_URL, dataSourceUrl, after) => {
    authFetch(
        `${API_URL}/${apiUrls.schema}/${dataSourceUrl}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Schema: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export { getAvailableData, getLinkedData, getLinkSchema }