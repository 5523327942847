import { apiUrls } from '../../../../utils/constants/urls'
import toast from '../../Toast'

const getSelectList = (authFetch, API_URL, dataSourceUrl, after) => {
    authFetch(
        `${API_URL}/${dataSourceUrl}/selectList`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Link Data: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const getLinkRecord = (authFetch, API_URL, dataSourceUrl, linkId, abortController, after) => {
    
    authFetch(
        `${API_URL}/${dataSourceUrl}/${linkId}`, {
            signal: abortController.signal
        }
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            if (e.name !== 'AbortError') {
                toast({
                    level: 'error',
                    message:
                        'Link Data: ' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server. Please try again later.'),
                })
            }
        })
}


const getLinkSchema = (authFetch, API_URL, dataSourceUrl, after) => {
    authFetch(
        `${API_URL}/${apiUrls.schema}/${dataSourceUrl}`
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            after(response)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Schema: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

const createLinkRecord = (authFetch, API_URL, dataSourceUrl, linkData, after) => {
    const url = `${API_URL}/${dataSourceUrl}/create`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(linkData)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response.createdId)
            
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Create Linked Record: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}


const updateLinkRecord = (authFetch, API_URL, dataSourceUrl, linkData, after) => {
    const url = `${API_URL}/${dataSourceUrl}`
    const POST = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
        },
        body: JSON.stringify(linkData)
    }  
    authFetch(
        url, POST
    )
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
            }
        })
        .then((response) => {
            toast({
                level: 'success',
                message: response.message,
                alert: true
            })
            after(response.data)
        })
        .catch((e) => {
            toast({
                level: 'error',
                message:
                    'Update Linked Record: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
            })
        })
}

export { getLinkRecord, getLinkSchema, createLinkRecord, getSelectList, updateLinkRecord }