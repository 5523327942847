import React, { useMemo, useCallback, useState } from 'react'
import GridLayout from 'react-grid-layout'

const ColumnSelectionModal = ({ columns, setColumnOrder, gridConfigExpanded }) => {
    const selectableColumns = useMemo(
        () =>
            columns.filter(
                (x) => typeof x.Header !== 'function' && x.Header !== ''
            ),
        [columns]
    )

    const layout = useMemo(() => {
        return selectableColumns.map((x, idx) => ({
            i: x.Header,
            x: 0,
            y: idx,
            w: 12,
            h: 1,
        }))
    }, [selectableColumns])

    const onLayoutChange = useCallback((layout) => {
        const orderedLayout = layout.sort((a, b) => a.y - b.y)
        const orderedColumns = orderedLayout.map(item => {
            return selectableColumns.find(col => col.Header === item.i)
        }).map(x => x.id)
        setColumnOrder(['selection', 'expander', ...orderedColumns, 'attributeJSON', 'note'])
    }, [selectableColumns, setColumnOrder])

    return (
        <>
            <div
                className={`columnSelectionModalWrapper ${
                    gridConfigExpanded ? '' : 'hidden'
                }`}
            >
                <div className="columnSelectionModal">
                    <GridLayout
                        className="layout"
                        draggableHandle={'.columnSelectionLabelWrapper'}
                        layout={layout}
                        cols={12}
                        rowHeight={30}
                        width={200}
                        onLayoutChange={onLayoutChange}
                    >
                        {selectableColumns.map((column) => (
                            <div className="columnSelectionRow" key={column.Header}>
                                <div className="columnSelectionCheckboxWrapper">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        {...column.getToggleHiddenProps()}
                                    />
                                </div>
                                <div className="columnSelectionLabelWrapper">
                                    <span className="columnSelectionLabel is-size-7">
                                        {column.Header}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </GridLayout>
                </div>
            </div>
        </>

    )
}

export default ColumnSelectionModal
