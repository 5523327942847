import isEmpty from 'lodash/isEmpty'
import union from 'lodash/union'
import { DispatchCell, JsonFormsDispatch, useJsonForms } from '@jsonforms/react'
import startCase from 'lodash/startCase'
import range from 'lodash/range'
import React, { Fragment, useMemo } from 'react'
import {
    FormHelperText,
    Grid,
    Hidden,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import {
    errorAt,
    formatErrorMessage,
    Paths,
    Resolve,
    toDataPath,
} from '@jsonforms/core'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'

import NoBorderTableCell from './NoBorderTableCell'
import TableToolbar from './TableToolbar'
import merge from 'lodash/merge'

// we want a cell that doesn't automatically span
const styles = {
    fixedCell: {
        width: '150px',
        height: '50px',
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center',
    },
    fixedCellSmall: {
        width: '50px',
        height: '50px',
        paddingLeft: 0,
        paddingRight: 0,
        textAlign: 'center',
    },
}

const generateCells = (Cell, uischema, schema, rowPath, enabled, cells) => {
    if (schema.type === 'object') {
        return getValidColumnProps(schema).map((prop) => {
            const cellPath = Paths.compose(rowPath, prop)
            let elementUiSchema = controlWithoutLabel(`#/properties/${prop}`)
            let hasElementsDefined = false
            if (uischema.elements) {
                const arrayDataPath = toDataPath(uischema.scope)
                elementUiSchema = uischema.elements.find((element) => {
                    const elementDataPath = toDataPath(element.scope)
                    return `${arrayDataPath}.${prop}` === elementDataPath
                })
                hasElementsDefined = true
            }
            
            const props = {
                propName: prop,
                schema,
                uischema: elementUiSchema,
                hasElementsDefined,
                title: schema.properties?.[prop]?.title ?? startCase(prop),
                rowPath,
                cellPath,
                enabled,
                cells,
            }
            return <Cell key={cellPath} {...props} />
        })
    } else {
        // primitives
        const props = {
            schema,
            rowPath,
            cellPath: rowPath,
            enabled,
        }
        return <Cell key={rowPath} {...props} />
    }
}

const getValidColumnProps = (scopedSchema) => {
    if (
        scopedSchema.type === 'object' &&
        typeof scopedSchema.properties === 'object'
    ) {
        return Object.keys(scopedSchema.properties).filter(
            (prop) => scopedSchema.properties[prop].type !== 'array'
        )
    }
    // primitives
    return ['']
}

const EmptyTable = ({ numColumns }) => (
    <TableRow>
        <NoBorderTableCell colSpan={numColumns}>
            <Typography align="center">No data</Typography>
        </NoBorderTableCell>
    </TableRow>
)

const TableHeaderCell = React.memo(({ title }) => (
    <TableCell>{title}</TableCell>
))

const ctxToNonEmptyCellProps = (ctx, ownProps) => {
    const path =
        ownProps.rowPath +
        (ownProps.schema.type === 'object' ? '.' + ownProps.propName : '')

    const errors = formatErrorMessage(union(errorAt(
        path,
        ownProps.schema,
        (p) => p === path
    )(ctx.core).map((error) => error.message)))

    return {
        rowPath: ownProps.rowPath,
        propName: ownProps.propName,
        schema: ownProps.schema,
        rootSchema: ctx.core.schema,
        uischema: ownProps.uischema,
        hasElementsDefined: ownProps.hasElementsDefined,
        errors,
        path,
        enabled: ownProps.enabled,
        cells: ownProps.cells || ctx.cells,
        renderers: ownProps.renderers || ctx.renderers,
    }
}

const controlWithoutLabel = (scope) => ({
    type: 'Control',
    scope: scope,
    label: false,
})

const NonEmptyCellComponent = React.memo(
    ({
        path,
        propName,
        uischema,
        hasElementsDefined,
        schema,
        rootSchema,
        errors,
        enabled,
        renderers,
        cells,
        isValid,
    }) => {
        const elementSchema = Resolve.schema(
            schema,
            `#/properties/${propName}`,
            rootSchema
        )
        return (
            <NoBorderTableCell>
                {schema.properties ? (
                    hasElementsDefined ? (
                        <JsonFormsDispatch
                            schema={elementSchema}
                            uischema={{
                                ...uischema,
                                scope: '#',
                            }}
                            path={path}
                            enabled={enabled}
                            renderers={renderers}
                            cells={cells}
                        />
                    ) : (
                        <DispatchCell
                            schema={elementSchema}
                            uischema={uischema}
                            path={path}
                            enabled={enabled}
                            renderers={renderers}
                            cells={cells}
                        />
                    )
                ) : (
                    <DispatchCell
                        schema={schema}
                        uischema={controlWithoutLabel('#')}
                        path={path}
                        enabled={enabled}
                        renderers={renderers}
                        cells={cells}
                    />
                )}
                {!hasElementsDefined && (
                    <FormHelperText error={!isValid}>
                        {!isValid && errors}
                    </FormHelperText>
                )}
            </NoBorderTableCell>
        )
    }
)

const NonEmptyCell = (ownProps) => {
    const ctx = useJsonForms()
    const emptyCellProps = ctxToNonEmptyCellProps(ctx, ownProps)
    const isValid = isEmpty(emptyCellProps.errors)
    return <NonEmptyCellComponent {...emptyCellProps} isValid={isValid} />
}

const NonEmptyRowComponent = ({
    childPath,
    uischema,
    schema,
    rowIndex,
    openDeleteDialog,
    moveUpCreator,
    moveDownCreator,
    enableUp,
    enableDown,
    showSortButtons,
    enabled,
    cells,
    path,
}) => {
    const moveUp = useMemo(
        () => moveUpCreator(path, rowIndex),
        [moveUpCreator, path, rowIndex]
    )
    const moveDown = useMemo(
        () => moveDownCreator(path, rowIndex),
        [moveDownCreator, path, rowIndex]
    )
    return (
        <TableRow key={childPath} hover>
            {generateCells(
                NonEmptyCell,
                uischema,
                schema,
                childPath,
                enabled,
                cells
            )}
            {enabled ? (
                <NoBorderTableCell
                    style={
                        showSortButtons
                            ? styles.fixedCell
                            : styles.fixedCellSmall
                    }
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {showSortButtons ? (
                            <Fragment>
                                <Grid item>
                                    <IconButton
                                        aria-label={`Move up`}
                                        onClick={moveUp}
                                        disabled={!enableUp}
                                        size="large"
                                    >
                                        <ArrowUpward />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        aria-label={`Move down`}
                                        onClick={moveDown}
                                        disabled={!enableDown}
                                        size="large"
                                    >
                                        <ArrowDownward />
                                    </IconButton>
                                </Grid>
                            </Fragment>
                        ) : null}
                        <Grid item>
                            <IconButton
                                aria-label={`Delete`}
                                onClick={() =>
                                    openDeleteDialog(childPath, rowIndex)
                                }
                                size="large"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </NoBorderTableCell>
            ) : null}
        </TableRow>
    )
}
export const NonEmptyRow = React.memo(NonEmptyRowComponent)
const TableRows = ({
    data,
    path,
    schema,
    openDeleteDialog,
    moveUp,
    moveDown,
    uischema,
    config,
    enabled,
    cells,
}) => {
    const isEmptyTable = data === 0

    if (isEmptyTable) {
        return (
            <EmptyTable numColumns={getValidColumnProps(schema).length + 1} />
        )
    }

    const appliedUiSchemaOptions = merge({}, config, uischema.options)

    return (
        <React.Fragment>
            {range(data).map((index) => {
                const childPath = Paths.compose(path, `${index}`)

                return (
                    <NonEmptyRow
                        key={childPath}
                        childPath={childPath}
                        rowIndex={index}
                        schema={schema}
                        uischema={uischema}
                        openDeleteDialog={openDeleteDialog}
                        moveUpCreator={moveUp}
                        moveDownCreator={moveDown}
                        enableUp={index !== 0}
                        enableDown={index !== data - 1}
                        showSortButtons={appliedUiSchemaOptions.showSortButtons}
                        enabled={enabled}
                        cells={cells}
                        path={path}
                    />
                )
            })}
        </React.Fragment>
    )
}

export class MaterialTableControl extends React.Component {
    addItem = (path, value) => this.props.addItem(path, value)
    render() {
        const {
            label,
            path,
            schema,
            rootSchema,
            uischema,
            errors,
            openDeleteDialog,
            visible,
            enabled,
            cells
        } = this.props
        const controlElement = uischema
        const isObjectSchema = schema.type === 'object'
        const headerCells = isObjectSchema
            ? generateCells(
                  TableHeaderCell,
                  controlElement,
                  schema,
                  path,
                  enabled,
                  cells
              )
            : undefined
        return (
            <Hidden xsUp={!visible}>
                <Table>
                    <TableHead>
                        <TableToolbar
                            errors={errors}
                            label={label}
                            addItem={this.addItem}
                            numColumns={isObjectSchema ? headerCells.length : 1}
                            path={path}
                            uischema={controlElement}
                            schema={schema}
                            rootSchema={rootSchema}
                            enabled={enabled}
                        />
                        {isObjectSchema && (
                            <TableRow>
                                {headerCells}
                                {enabled ? <TableCell /> : null}
                            </TableRow>
                        )}
                    </TableHead>
                    <TableBody>
                        <TableRows
                            openDeleteDialog={openDeleteDialog}
                            {...this.props}
                        />
                    </TableBody>
                </Table>
            </Hidden>
        )
    }
}
