import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef,
} from 'react'
import { APIRequestContext } from '../../../wrappers/APIRequestContext'
import withConfig from '../../../wrappers/withConfig'
import {
    // parseSchemaObjects,
    // getMergedSchema,
    resolveLinkData,
} from '../../../../utils/json/schemas'
import { parseJsonAttributes } from '../../../../utils/json/attributes'
import {
    getAvailableData,
    getLinkedData,
    // getLinkSchema
} from './actions'
import { gridConfig } from '../../../../utils/testing/config'
import { getGridColumns } from '../../../../utils/grids/link/columns'
import {
    getAttributeColumnsFromGridConfig,
    getBaseColumnsFromGridConfig,
} from '../../../../utils/grids/display/config'
import { constructQueryParams } from '../../../../utils/grids/link/query'

const DataContext = createContext(null)

const LinkDataContextProvider = withConfig(
    ({
        config,
        dataSourceUrl,
        setData,
        targetIdColumn,
        linkIdColumn,
        configName,
        existingLinkData,
        parentData,
        path,
        resolvePath,
        filterById,
        filterIdColumn,
        children,
    }) => {
        const [availableTableData, setAvailableTableData] = useState([])
        const [availableQuery, setAvailableQuery] = useState({
            filters: [],
            page: 0,
            pageSize: 25,
        })
        const [availableCount, setAvailableCount] = useState(0)
        const [availableLoading, setAvailableLoading] = useState(true)
        const [existingLoading, setExistingLoading] = useState(true)
        const [existingTableData, setExistingTableData] = useState([])
        const { authenticatedFetch } = useContext(APIRequestContext)
        const rootPath = useMemo(() => (path ? path.split('.')[0] : ''), [path])
        const parentId = useMemo(() => filterById ? parentData[rootPath][filterIdColumn] : null, [filterById, parentData, rootPath, filterIdColumn])
        const previousParentId = useRef(parentId)
        const originalParentId = useRef(parentId)
        const exportId = useMemo(() => parentData[rootPath][linkIdColumn], [parentData, rootPath, linkIdColumn])
        
        const { API_URL } = config

        const linkedIds = useMemo(() => {
            if (resolvePath) {
                const resolvedData = resolveLinkData(
                    existingLinkData,
                    resolvePath
                )
                return resolvedData
                    ? resolvedData
                          .flatMap((x) => x)
                          .map((x) => x[targetIdColumn])
                    : []
            } else {
                return existingLinkData ? existingLinkData.map((x) => x[targetIdColumn]) : []
            }
        }, [existingLinkData, resolvePath, targetIdColumn])

        // get all linkedData
        const fetchExisting = useCallback((linkedIds) => {
            setExistingLoading(true)
            getLinkedData(
                authenticatedFetch,
                API_URL,
                dataSourceUrl,
                linkedIds,
                filterById,
                parentId,
                (d) => {
                    const data = parseJsonAttributes(d)
                    setExistingTableData(data)
                    setExistingLoading(false)
                }
            )
        }, [API_URL, authenticatedFetch, dataSourceUrl, filterById, parentId])

        useEffect(() => {
            fetchExisting(linkedIds)
        }, [linkedIds, fetchExisting])

        // when parentId changes, set data to be empty before querying
        useEffect(() => {
            if (parentId !== previousParentId.current && previousParentId.current !== originalParentId.current) {
                setData([])
                previousParentId.current = parentId
            }
        }, [parentId])

        const fetchNewData = useCallback(
            (query, linkedIds) => {
                return new Promise((resolve, reject) => {
                    try {
                        setAvailableLoading(true)
                        const queryParams = constructQueryParams(query)
                        getAvailableData(
                            authenticatedFetch,
                            API_URL,
                            dataSourceUrl,
                            linkedIds,
                            filterById,
                            parentId,
                            queryParams,
                            (d) => {
                                const { data: dd, totalCount, page } = d
                                const data = parseJsonAttributes(dd)
                                setAvailableTableData(data)
                                setAvailableCount(totalCount)
                                setAvailableLoading(false)
                                return resolve({ data, totalCount, page })
                            }
                        )
                    } catch (e) {
                        return reject(e)
                    }
                })
            },
            [API_URL, authenticatedFetch, dataSourceUrl, filterById, parentId]
        )

        // on linked ids or query change, refetch available table data
        useEffect(() => {
            fetchNewData(availableQuery, linkedIds)
        }, [availableQuery, linkedIds, fetchNewData])

        // create columns for the second attempt: Material Table React
        // Note: Does not allow for parsing column names that have
        // spaces, fix later
        const gridColumns = useMemo(() => {
            const baseColumns = getBaseColumnsFromGridConfig(
                configName,
                gridConfig
            )
            const attributeColumns = getAttributeColumnsFromGridConfig(
                configName,
                gridConfig
            )
            return getGridColumns(baseColumns, attributeColumns)
        }, [configName])

        const addItem = useCallback(
            (selectedData) => {
                const data = selectedData.map((d) => ({
                    ...d,
                    tableData: {
                        ...d.tableData.id,
                        checked: false,
                    },
                }))
                const parentId = parentData[rootPath][linkIdColumn]
                var newLinkData = []
                if (filterById) {
                    newLinkData = data.map((x) => ({
                        [linkIdColumn]: parentId,
                        [resolvePath]: x,
                        [targetIdColumn]: x[targetIdColumn],
                    }))
                } else {
                    newLinkData = data.map((x) => ({
                        ...x,
                        [linkIdColumn]: parentId,
                    }))
                }
                setData([
                    ...newLinkData,
                    ...(existingLinkData ? existingLinkData : []),
                ])
                return
            },
            [setData, existingLinkData, parentData, filterById, linkIdColumn, targetIdColumn, rootPath, resolvePath]
        )

        const removeItem = useCallback(
            (selectedData) => {
                const data = selectedData.map((d) => ({
                    ...d,
                    tableData: {
                        ...d.tableData.id,
                        checked: false,
                    },
                }))
                const removedIds = data.map((x) => x[targetIdColumn])
                const newLinkData = existingLinkData.filter(
                    (x) => !removedIds.includes(x[targetIdColumn])
                )
                setData(newLinkData)
            },
            [setData, existingLinkData, targetIdColumn]
        )

        return (
            <DataContext.Provider
                value={{
                    availableTableData,
                    existingTableData,
                    existingLoading,
                    gridColumns,
                    addItem,
                    removeItem,
                    setAvailableQuery,
                    availableQuery,
                    availableCount,
                    availableLoading,
                    exportId
                }}
            >
                {children}
            </DataContext.Provider>
        )
    }
)

export { DataContext }

export default LinkDataContextProvider
