import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useCallback,
    useMemo,
} from 'react'

import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { getFacilityGroups, getFacilityGroupListMetadata } from './actions'
import withConfig from '../../wrappers/withConfig'

import { gridConfig as testConfig } from '../../../utils/testing/config' // TODO: replace with api requested data
import urls, { getCreateLink } from '../../../utils/constants/urls'

const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [facilityGroups, setFacilityGroups] = useState([])
    const [loading, setLoading] = useState(true)
    const [gridConfigExpanded, setGridConfigExpanded] = useState(false)
    const [gridConfig, setGridConfig] = useState(testConfig)
    const createLink = useMemo(() => getCreateLink(urls.project), [])
    const [queryParameters, setQueryParameters] = useState({
        page: 0,
        pageSize: 25,
    })
    const [listMetadata, setListMetadata] = useState({ count: 0, pages: 0 })

    const updateQueryParameters = useCallback(
        (newParameters) => {
            setQueryParameters((prevParameters) => ({
                ...prevParameters,
                ...newParameters,
            }))
        },
        [setQueryParameters]
    )

    const fetchFacilityGroupListMetadata = useCallback((queryParameters) => {
        setLoading(true)
        getFacilityGroupListMetadata(
            authenticatedFetch,
            API_URL,
            queryParameters,
            (res) => {
                setListMetadata(res)
            }
        )
    }, [])

    const fetchFacilityGroups = useCallback(
        async (queryParameters) => {
            setLoading(true)
            getFacilityGroups(
                authenticatedFetch,
                API_URL,
                queryParameters,
                (res) => {
                    setLoading(false)
                    setFacilityGroups(res)
                }
            )
        },
        [authenticatedFetch, API_URL]
    )

    // when query parameters change, update table data
    useEffect(() => {
        fetchFacilityGroups(queryParameters)
        fetchFacilityGroupListMetadata(queryParameters)
    }, [queryParameters])

    return (
        <DataContext.Provider
            value={{
                facilityGroups,
                updateQueryParameters,
                listMetadata,
                loading,
                gridConfig,
                gridConfigExpanded,
                setGridConfigExpanded,
                createLink,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
