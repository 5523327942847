import React, {
    createContext,
    useContext,
    useEffect,
    useState,
    useMemo,
    useCallback,
    useRef,
} from 'react'
import isEqual from 'lodash/isEqual'
import { useParams } from 'react-router'
import { debounce } from '@material-ui/core'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import {
    getFacility,
    getFacilitySchema,
    updateFacility,
    getFacilityAutoCalc,
} from './actions'
import withConfig from '../../wrappers/withConfig'
import { parseJsonAttributes } from '../../../utils/json/attributes'
import {
    parseSchemaObjects,
    separatePropertiesBasedOnSchemaType,
    getPropertiesFromSchema,
} from '../../../utils/json/schemas'
import { checkFormErrors, checkEditingChild } from '../../../utils/form/errors'
import { facilitySchema } from '../../../utils/testing/schemas'
import titles, { constructFormTitle } from '../../../utils/constants/titles'


const DataContext = createContext(null)

const DataContextProvider = withConfig(({ config, children }) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const [facility, setFacility] = useState(null)
    const [initialFacilityState, setInitialFacilityState] = useState(null)
    const [schemas, setSchemas] = useState(null)
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [errors, setErrors] = useState([])
    const [additionalErrors, setAdditionalErrors] = useState([])
    const [submitting, setSubmitting] = useState(false)
    const params = useParams()
    const facilityId = useMemo(() => params.facilityId, [params])
    const beforeAutoCalc = useRef({})
    const [facilityTitle, setFacilityTitle] = useState(null)

    const [autoCalcAbortController, setAutoCalcAbortController] = useState(new AbortController())
    const autoCalculateInputs = useCallback(() => {
        if (
            !isEqual(beforeAutoCalc.current, facility) && editing
        ) {
            if (facility && facility.facility) {
                const abortController = new AbortController()
                setAutoCalcAbortController(abortController)
                getFacilityAutoCalc(
                    authenticatedFetch,
                    API_URL,
                    facility.facility,
                    abortController,
                    (d) => {
                        if (d) {
                            const newFacility = {
                                ...facility.facility,
                                ...d,
                            }
                            beforeAutoCalc.current = {
                                facility: newFacility,
                            }
                            setFacility({ facility: newFacility })
                        }
                    }
                )
            }
        }
    }, [facility, beforeAutoCalc, setFacility, editing, autoCalcAbortController])

    useEffect(() => {
        debounce(autoCalculateInputs(), 1500)
    }, [facility, editing])

    const setFacilityData = useCallback(
        (f) => {
            const data = parseJsonAttributes(f)
            setFacility({ facility: data })
            setFacilityTitle(constructFormTitle(data, titles.facility))
            setInitialFacilityState(data)
        },
        [setFacility, setInitialFacilityState]
    )

    useEffect(() => {
        getFacility(authenticatedFetch, API_URL, facilityId, (d) => {
            setFacilityData(d)
        })
    }, [facilityId, setFacilityData])

    useEffect(() => {
        // getFacilitySchema(authenticatedFetch, API_URL, (s) => {
        //     const parsedSchemas = parseSchemaObjects(s)
        //     setSchemas(parsedSchemas)
        // })

        // // NOTE: this is for testing only, revert to db objects when complete
        setSchemas(facilitySchema)
    }, [])

    useEffect(() => {
        if (schemas && facility) {
            setLoading(false)
        }
    }, [schemas, facility])

    const [editingChild, setEditingChild] = useState(false)

    const saveForm = useCallback(() => {
        setSubmitting(true)
        checkEditingChild(editingChild)
            .then(() => checkFormErrors(errors, additionalErrors))
            .then(() => {
                if (facility.facility.facilityWork) {
                    const customProperties = getPropertiesFromSchema(
                        schemas.baseSchema.$defs.facilityWork
                            .additionalProperties.properties
                            .facilityWorkAttributesCustom
                    )
                    facility.facility.facilityWork.forEach((fw) => {
                        const dataKeys = Object.keys(fw)
                        const attributes = dataKeys
                            .filter((x) => customProperties.includes(x))
                            .reduce(
                                (acc, curr) => ({ ...acc, [curr]: fw[curr] }),
                                {}
                            )
                        attributes.permitNumber = facility.facility?.permitNumber
                        fw.attributes = JSON.stringify(attributes)
                    })
                }

                const facilityData = separatePropertiesBasedOnSchemaType(
                    facility.facility,
                    schemas
                )
                updateFacility(
                    authenticatedFetch,
                    API_URL,
                    facilityData,
                    (res) => {
                        setFacilityData(res.data)
                        setEditing(false)
                        setSubmitting(false)
                    }
                )
            })
            .catch(() => setSubmitting(false))
    }, [facility, schemas, errors, additionalErrors, editingChild, setFacilityData])

    const resetForm = useCallback(() => {
        autoCalcAbortController.abort()
        setFacility({ facility: initialFacilityState })
    }, [initialFacilityState, autoCalcAbortController])

    return (
        <DataContext.Provider
            value={{
                facility,
                setFacility,
                schemas,
                loading,
                errors,
                setErrors,
                additionalErrors,
                setAdditionalErrors,
                setEditingChild,
                saveForm,
                resetForm,
                editing,
                setEditing,
                submitting,
                facilityTitle,
            }}
        >
            {children}
        </DataContext.Provider>
    )
})

export { DataContext }
export default DataContextProvider
